import { FieldArray, useFormik } from 'formik';
import moment from 'moment-timezone';
import { ConnectedProps, connect } from 'react-redux';

import Button from '@/components/Button/Button';
import Dialog from '@/components/Dialog/Dialog';
import EmptyMessage from '@/components/EmptyMessage';
import FormikForm from '@/componentsv2/FormikForm';
import { removeExperience, saveExperience } from '@/profile/store';
import { RootState } from '@/store';

import Experience from './Experience';

const validate = (values: any) => {
  const errors = {};

  const experienceErrors: any = [];
  values.experiences.forEach((exp: any, i: any) => {
    if (emptyExperience(exp)) {
      // empty experience form, treat it as if it was never included
      return;
    }

    const err = {};

    if (!exp.title) {
      // @ts-expect-error TS(2339): Property 'title' does not exist on type '{}'.
      err.title = 'Required';
    }

    if (!exp.organization) {
      // @ts-expect-error TS(2339): Property 'organization' does not exist on type '{}... Remove this comment to see the full error message
      err.organization = 'Required';
    }

    if (exp.start_date && !exp.current && !exp.end_date) {
      // @ts-expect-error TS(2339): Property 'end_date' does not exist on type '{}'.
      err.end_date = 'Required';
    }

    if (exp.start_date && !moment(exp.start_date).isValid()) {
      // @ts-expect-error TS(2339): Property 'start_date' does not exist on type '{}'.
      err.start_date = 'Start date must be a valid date';
    }

    if (exp.end_date && !moment(exp.end_date).isValid()) {
      // @ts-expect-error TS(2339): Property 'end_date' does not exist on type '{}'.
      err.end_date = 'End date must be a valid date';
    }

    if (exp.start_date && exp.end_date && moment(exp.end_date).isBefore(exp.start_date)) {
      // @ts-expect-error TS(2339): Property 'end_date' does not exist on type '{}'.
      err.end_date = 'End date must be after start date';
    }

    if (Object.keys(err).length > 0) {
      experienceErrors[i] = err;
    }
  });

  if (experienceErrors.length) {
    // @ts-expect-error TS(2339): Property 'experiences' does not exist on type '{}'... Remove this comment to see the full error message
    errors.experiences = experienceErrors;
  }

  return errors;
};

function emptyExperience(exp: any) {
  if (exp.title) return false;
  if (exp.location) return false;
  if (exp.organization) return false;
  if (exp.role) return false;
  if (exp.start_date) return false;
  if (exp.end_date) return false;
  if (exp.current) return false;
  if (exp.description) return false;

  return true;
}

function Experiences({ fields, fieldName, onAdd, onRemove }: any) {
  return (
    <div>
      {fields?.length > 0 ? (
        fields?.map((field: any, index: any) => (
          <Experience
            key={field.id}
            showRemove
            showAdd={index === fields.length - 1}
            onAdd={onAdd}
            experience={fields[index]}
            field={`${fieldName}[${index}]`}
            onRemove={() => onRemove(index)}
          />
        ))
      ) : (
        <EmptyMessage
          border={false}
          iconName="work"
          body="Add your first work experience"
          action={<Button onClick={onAdd}>Add a Work Experience</Button>}
        />
      )}
    </div>
  );
}

interface ExperiencesFormProps {
  profileId: string;
  maxWidth: string;
  title: string;
  open?: boolean;
  onSubmit?: ({ experiences }: { experiences: any[] }) => void;
  onReset?: () => void;
  onClose: () => void;
}

const connector = connect(
  (state: RootState, ownProps: ExperiencesFormProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    const initialExperiences =
      profile.experiences && profile.experiences.length > 0 ? profile.experiences : [{}];

    const form = state.form.expertExperiences;
    const initialValues = { experiences: initialExperiences };
    const formValues = form ? form.values : initialValues;

    return {
      profile,
      formValues,
      initialValues,
    };
  },
  {
    saveExperience,
    removeExperience,
  }
);

const ExperiencesForm = (props: ExperiencesFormProps & ConnectedProps<typeof connector>) => {
  const { profile, onSubmit, initialValues, title, open, onClose } = props;
  const handleClose = () => {
    onClose();
    setTimeout(() => formik.resetForm(), 500);
  };
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: (values) => {
      const experiences = values.experiences
        .filter((exp: any) => !emptyExperience(exp))
        .map((w: { location?: { name: string }; role?: { name: string } }) => ({
          ...w,
          location: w.location?.name,
          role: w.role?.name,
        }));

      const itemsToDelete = profile.experiences?.filter(
        (current: any) => !experiences.find((edited: any) => edited.id === current.id)
      );

      itemsToDelete?.forEach((w: any) => props.removeExperience(profile.id, w.id));
      experiences.forEach((w: any) => props.saveExperience(profile.id, w));

      onSubmit?.({ experiences });
    },
    onReset: () => {
      props.onReset?.();
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onCancel={handleClose}
      title={title}
      maxWidth="lg"
      onConfirm={() => formik.handleSubmit()}
    >
      <FormikForm value={formik}>
        <FieldArray
          name="experiences"
          render={(arrayHelpers) => (
            <Experiences
              fieldName="experiences"
              fields={formik.values.experiences}
              onAdd={() => arrayHelpers.push({})}
              onRemove={arrayHelpers.remove}
            />
          )}
        />
      </FormikForm>
    </Dialog>
  );
};

export default connector(ExperiencesForm);
