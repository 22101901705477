import { FormEventHandler, FormHTMLAttributes, ReactNode } from 'react';

import Button from '@/components/Button';

interface SaveFormProps extends FormHTMLAttributes<HTMLFormElement> {
  children: ReactNode;
  saveLabel?: ReactNode;
  onConfirm?: FormEventHandler<HTMLFormElement>;
}

const SaveForm = ({
  children,
  saveLabel = 'Save',
  onConfirm,
  ...other
}: SaveFormProps): JSX.Element => {
  return (
    <form onSubmit={onConfirm} {...other}>
      {children}
      <Button style={{ marginTop: 40 }} type="submit" size="medium">
        {saveLabel}
      </Button>
    </form>
  );
};

export default SaveForm;
