import cx from 'classnames';
import { Field } from 'formik';
import { ConnectedProps, connect } from 'react-redux';

import FormikMonthPicker from '@/components/MonthPicker/FormikMonthPicker';
import { FormikField } from '@/componentsv2';
import { convertToAutocompleteOptions } from '@/componentsv2/Autocomplete/util';
import FormikAutocomplete from '@/componentsv2/Autocomplete/withFormik';
import { Country } from '@/reducers/countries';
import { RootState } from '@/store';

import s from './Experience.module.scss';
import AddButton from './buttons/AddButton';
import RemoveButton from './buttons/RemoveButton';

const parseFormat = 'YYYY-MM-DD';

const roles = [
  'Consultant',
  'Cross border trade',
  'Executive Management',
  'Finance and Accounting',
  'HR',
  'Investments, Mergers and Acquisitions',
  'IT',
  'Manufacturing',
  'Marketing',
  'Operations',
  'Research and Development',
  'Sales',
  'Sourcing',
  'Strategy',
];

function Col({ className, children, ...other }: any) {
  return (
    <div className={cx(s.col, { [className]: !!className })} {...other}>
      <div className={s.colContainer}>{children}</div>
    </div>
  );
}

const connector = connect((state: RootState) => ({
  allCountries: state.countries,
}));

interface ExperienceProps {
  field: string;
  experience: any;
  showRemove: boolean;
  showAdd: boolean;
  onRemove: () => void;
  onAdd: () => void;
}

function Experience({
  field,
  allCountries,
  experience,
  onRemove,
  showRemove,
  showAdd,
  onAdd,
}: ExperienceProps & ConnectedProps<typeof connector>) {
  return (
    <>
      <div className={s.root}>
        <div className={s.removeAction}>{showRemove && <RemoveButton onClick={onRemove} />}</div>

        <div className={s.content}>
          <div className={s.row}>
            <Col>
              <FormikField fullWidth name={`${field}.title`} label="Title" />
            </Col>
          </div>

          <div className={s.row}>
            <Col>
              <FormikAutocomplete
                name={`${field}.location`}
                options={convertToAutocompleteOptions<Country>(allCountries)}
                TextFieldProps={{ label: 'Location' }}
              />
            </Col>
          </div>

          <div className={s.row}>
            <Col>
              <FormikField
                fullWidth
                name={`${field}.organization`}
                label="Employer Name"
                sx={{ marginTop: '13px' }}
              />
            </Col>

            <Col>
              <FormikAutocomplete
                name={`${field}.role`}
                options={roles.map((r) => ({ id: r, name: r }))}
                TextFieldProps={{ label: 'Type of role' }}
              />
            </Col>
          </div>

          <div className={s.row}>
            <Col>
              <FormikMonthPicker
                name={`${field}.start_date`}
                label="Start date"
                parseFormat={parseFormat}
              />
            </Col>

            <Col>
              <FormikMonthPicker
                name={`${field}.end_date`}
                label="End date"
                parseFormat={parseFormat}
                disabled={experience.current}
              />
            </Col>

            <Col className={s.currentCol}>
              <label htmlFor={`${field}.current`} className="cursor-pointer">
                <Field
                  id={`${field}.current`}
                  type="checkbox"
                  name={`${field}.current`}
                  label="I still work here"
                />
                <span className="ml-4">I still work here</span>
              </label>
            </Col>
          </div>

          <div className={s.row}>
            <Col>
              <FormikField
                name={`${field}.description`}
                fullWidth
                multiline
                minRows={2}
                maxRows={10}
                label="Describe what you learned here"
              />
            </Col>
          </div>
        </div>
      </div>

      <div className={s.addAction}>
        {showAdd && <AddButton label="Experience" onClick={onAdd} />}
      </div>
    </>
  );
}

export default connector(Experience);
