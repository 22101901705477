import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { TextField } from '../FormAdapters/FormAdapters';

// Hide spinner
const useStyles = makeStyles(() => ({
  input: {
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

export interface CurrencyProps {
  input?: any;
  meta?: any;
  InputProps?: any;
  adornment?: ReactNode;
  position?: 'start' | 'end';
  maxlength?: number;
  [key: string]: any;
}

const Currency = ({
  input = {},
  meta,
  InputProps = {},
  adornment = '$',
  position = 'start',
  maxlength = 6,
  ...other
}: CurrencyProps): JSX.Element => {
  const s = useStyles();

  const inputRef = React.createRef();
  const [value, setValue] = useState<number | undefined>(input.value);
  const [formatted, setFormatted] = useState('');

  useEffect(() => {
    if (value === undefined) {
      setFormatted('');
      return;
    }

    const formatted = value
      .toString()
      .padStart(3, '0')
      .replace(/(\d+)(\d\d$)/, '$1.$2');

    setFormatted(formatted);
  }, [value]);

  const inputAdornment = useMemo(
    () => <InputAdornment position={position}>{adornment}</InputAdornment>,
    [position, adornment]
  );

  const handleChange = useCallback(
    (e: any, value: any) => {
      // Value will be present when using redux, otherwise get event value
      value = value ?? e.target?.value;

      value = String(value).replace(/\D+/g, '').substring(0, maxlength);

      // Strip zeros
      value = Number(value);

      setValue(value);
      if (input.onChange) {
        input.onChange(value);
      }
    },
    [maxlength]
  );

  const keepCursorAtTheEnd = () => {
    const { length } = formatted || '';
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    inputRef.current.setSelectionRange(length, length);
  };

  return (
    <TextField
      {...other}
      // @ts-expect-error input doesn't exist on type
      input={input}
      meta={meta}
      onChange={handleChange}
      onBlur={handleChange}
      onSelect={keepCursorAtTheEnd}
      value={formatted}
      changeOnBlur={false}
      inputRef={inputRef}
      InputProps={{
        ...InputProps,
        classes: { input: s.input },
        startAdornment: position === 'start' && inputAdornment,
        endAdornment: position === 'end' && inputAdornment,
      }}
    />
  );
};

export default Currency;
