import { useQuery } from '@apollo/client';

import { gengql } from '@/__generated__';
import { NetworkExpertConnectionState } from '@/__generated__/graphql';
import BillRateForm from '@/profile/components/forms/BillRateForm';

import SaveForm from './DeprecatedSaveForm';

interface RateProps {
  profileId: string;
  userId: string;
}

const GET_PROFILE = gengql(/* GraphQL */ `
  query rateGetProfile($id: String!) {
    profile(id: $id) {
      id
      available_marketplace
      expert_internal_networks {
        id
        connection_state
      }
    }
  }
`);

const VALID_NETWORK_STATES = [
  NetworkExpertConnectionState.AwaitingAcceptance,
  NetworkExpertConnectionState.Active,
];

const Rate = ({ profileId, userId }: RateProps): JSX.Element | null => {
  const { data } = useQuery(GET_PROFILE, {
    variables: {
      id: profileId,
    },
  });
  if (!data) return null;

  const profile = data.profile;
  const networks = profile.expert_internal_networks || [];

  // Always allow user to opt back into Marketplace
  // Only allow user to opt out of Marketplace if connected to internal networks
  const showMarketplace =
    profile.available_marketplace === false ||
    networks.some((n) => n.connection_state && VALID_NETWORK_STATES.includes(n.connection_state));

  return (
    <BillRateForm
      showMarketplace={showMarketplace}
      component={SaveForm}
      profileId={profileId}
      userId={userId}
    />
  );
};

export default Rate;
