import { Bookmark, Layout, User } from 'react-feather';

import { userSelector } from '@/auth/store/userSlice';
import Header, { NavBarItem } from '@/components/Header';
import useTasks from '@/hooks/useTasks';
import APP_ROUTES from '@/routes/APP_ROUTES';
import Omnibar from '@/searchv2/components/Omnibar';
import { useAppSelector } from '@/store';

const HarnessHeader = (): JSX.Element => {
  //const { isAuthenticated, logout } = useAuth0();
  const isAuthenticated = false;
  const logout = () => {};

  const { incompleteTasks } = useTasks();
  const { data: userData } = useAppSelector(userSelector);
  const navbarItems: NavBarItem[] = [
    {
      key: 'dashboard',
      text: 'Dashboard',
      to: APP_ROUTES.dashboard,
      icon: Layout,
    },
    {
      key: 'profile',
      text: 'Profile',
      to: APP_ROUTES.profile(),
      icon: User,
    },
    {
      key: 'search',
      text: 'Search',
      to: APP_ROUTES.search,
      className: 'sm:hidden',
    },
    {
      key: 'collections',
      text: 'Collections',
      to: APP_ROUTES.collections,
      icon: Bookmark,
    },
  ];

  const userMenuItems: NavBarItem[] = [
    {
      key: 'dashboard',
      text: 'Dashboard',
      to: APP_ROUTES.dashboard,
    },
    {
      key: 'profile',
      text: 'View my profile',
      to: APP_ROUTES.profile(),
      divider: true,
    },
  ];

  return (
    <Header
      userData={{
        displayName: userData?.full_name || null,
        picture_url: userData?.picture_url || '',
      }}
      SearchComponent={() => <Omnibar className="hidden max-w-[480px] sm:block" />}
      incompleteTasks={incompleteTasks}
      isAuthenticated={isAuthenticated}
      navbarItems={navbarItems}
      userMenuItems={userMenuItems}
      logout={logout}
    />
  );
};

export default HarnessHeader;
