import { FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useField } from 'formik';
import moment from 'moment-timezone';

import { isRequiredError } from '@/utils/input-helpers';

interface FormikMonthPickerProps {
  name: string;
  label: string;
  parseFormat: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  helperText?: string;
  maxDate?: string;
  minDate?: string;
}
const pattern = 'MM/YYYY';
const FormikMonthPicker = ({
  name,
  label,
  maxDate,
  minDate,
  disabled,
  placeholder = pattern,
  helperText,
  required,
  parseFormat,
}: FormikMonthPickerProps): JSX.Element => {
  const [, { touched, error, value }, { setValue, setTouched }] = useField(name);

  const handleOnChangeMonth = (e: any) => {
    const value = moment(e?.toDate()).format(parseFormat);
    setValue(value);
  };

  const textFieldsProps = {
    error: !!error,
    placeholder,
    helperText,
    required,
    onBlur: () => setTouched(true, true),
  };

  return (
    <>
      <DatePicker
        disabled={disabled}
        label={label}
        maxDate={maxDate ? moment(maxDate) : undefined}
        minDate={minDate ? moment(minDate) : undefined}
        value={value ? moment(value) : undefined}
        views={['year', 'month']}
        onChange={handleOnChangeMonth}
        format={pattern}
        slotProps={{
          inputAdornment: { position: 'end' },
          textField: textFieldsProps,
        }}
      />
      {touched && error && !isRequiredError(error) ? (
        <FormHelperText error>{error}</FormHelperText>
      ) : null}
    </>
  );
};

export default FormikMonthPicker;
