import FormHelperText from '@mui/material/FormHelperText';
import { useField } from 'formik';

import { isRequiredError } from '@/utils/input-helpers';

import Autocomplete from '.';
import { IFormikAutocompleteProps } from './types';

const FormikAutocomplete = ({
  name,
  options,
  TextFieldProps,
  ...rest
}: IFormikAutocompleteProps): JSX.Element => {
  const [, { touched, error, value }, { setValue, setTouched }] = useField(name);
  const selectedValue = options.find((option) => option.name === value);

  return (
    <>
      <Autocomplete
        options={options}
        value={selectedValue || value}
        onChange={(newValue) => setValue(newValue)}
        onBlur={() => {
          setTouched(true, true);
        }}
        TextFieldProps={{
          error: Boolean(touched && error),
          ...TextFieldProps,
        }}
        {...rest}
      />
      {touched && error && !isRequiredError(error) ? (
        <FormHelperText error>{error}</FormHelperText>
      ) : null}
    </>
  );
};

export default FormikAutocomplete;
