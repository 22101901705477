import { UnknownAction } from 'redux';

import { gengql } from '@/__generated__';
import {
  ActionAddConsultationMutation,
  ActionAddConsultationMutationVariables,
  EngagementType,
} from '@/__generated__/graphql';
import { AppThunk } from '@/store';
import { shouldResetCollection } from '@/utils';

import { REQUEST_CONSULTATION, UPDATE_CONSULTATION } from './queries';

export const COLLECTION_TYPES = {
  COMPLETED: 'completed',
  UNREVIEWED: 'unreviewed',
  STARTING: 'starting',
  AWAITING: 'awaiting',
  CANCELED: 'canceled',
  CONFIRMED: 'confirmed',
  DEFAULT: 'default',
  DASHBOARD_AWAITING: 'dashboardAwaiting',
  DASHBOARD_CONFIRMED: 'dashboardConfirmed',
} as const;

export type CollectionType = (typeof COLLECTION_TYPES)[keyof typeof COLLECTION_TYPES];

export const CONSULTATION__ADD_REGISTRANT = 'CONSULTATION__ADD_REGISTRANT';
export const CONSULTATION__BATCH_ADD = 'CONSULTATION__BATCH_ADD';
export const CONSULTATION__UPDATE = 'CONSULTATION__UPDATE';
export const CONSULTATION__DELETE = 'CONSULTATION__DELETE';
export const CONSULTATION__LIST_LOADING = 'CONSULTATION__LIST_LOADING';
export const CONSULTATION__LIST_LOADED = 'CONSULTATION__LIST_LOADED';
export const CONSULTATION__CLEAR = 'CONSULTATION__CLEAR';
export const CONSULTATION__DISMISS_REVIEW = 'CONSULTATION__DISMISS_REVIEW';
export const CONSULTATION__RESET_COLLECTION = 'CONSULTATION__RESET_COLLECTION';

export const OUTDATED_ERROR = 'GraphQL Error: cannot choose a past date to start the consultation';
export const ALREADY_CONFIRMED =
  'GraphQL Error: cannot change start at date of confirmed consultation';
export const ALREADY_CANCELED = 'GraphQL Error: cannot reopen a canceled or denied consultation';
export const ALREADY_STARTED =
  'GraphQL Error: cannot cancel or reschedule consultation that has already started';
export const NOT_ENOUGH_CREDITS = 'GraphQL Error: not enough credits for consultation';
export const DEADLINE_BEFORE_CURRENT =
  'GraphQL Error: cannot set new deadline before current deadline';
export const DEADLINE_WRONG_STATE =
  'GraphQL Error: can only set deadline when state is awaiting or confirmed';

export const Trigger = Object.freeze({
  consultationPage: Symbol('consultationPage'),
  emailLink: Symbol('emailLink'),
});

export const isCallType = (type: EngagementType) =>
  [EngagementType.Consultation, EngagementType.Opportunity].includes(type);

const state = Object.freeze({
  negotiating_expert_time: 'negotiating_expert_time',
  negotiating_client_time: 'negotiating_client_time',
  awaiting_expert_review: 'awaiting_expert_review',
  awaiting_client_accept: 'awaiting_client_accept',
  denied: 'denied',
  expired: 'expired',
  canceled: 'canceled',
  incomplete: 'incomplete',
  confirmed: 'confirmed',
  finalizing: 'finalizing',
  completed: 'completed',
  client_rejected: 'client_rejected',
});

export function getEngagementTimestamp(consultation: any): any {
  if (consultation.engagementType === EngagementType.WrittenResponse) {
    return (
      consultation.proposedTimes &&
      consultation.proposedTimes.length > 0 &&
      consultation.proposedTimes[0]
    );
  }
  return consultation.starts_at;
}

export const isFixedRate = (type: any) =>
  [EngagementType.Opportunity, EngagementType.WrittenResponse].includes(type);

export const isWrittenConsultationConfirmed = (type: any) =>
  [state.confirmed, state.awaiting_expert_review, state.awaiting_client_accept].includes(type);

function triggerToConsultationUpdateTrigger(trigger: any) {
  // Unfortunatelly, Symbol.prototype.description is not supported on
  // all browsers or node env
  switch (trigger) {
    case Trigger.consultationPage:
      return 'consultationPage';
    case Trigger.emailLink:
      return 'emailLink';
    default:
      throw new Error(`Unsupported trigger ${trigger}`);
  }
}

export function requestConsultation(values: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(REQUEST_CONSULTATION, values);

    dispatch({ type: CONSULTATION__CLEAR });

    return result.requestConsultation;
  };
}

export function validateExpertConsultationPreferences(values: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query validateExpertConsultationPreferences(
          $expertId: String!
          $expertRequestId: String
          $groupId: String
          $engagementType: EngagementType!
        ) {
          validateExpertConsultationPreferences(
            expert_id: $expertId
            expert_request_id: $expertRequestId
            group_id: $groupId
            engagement_type: $engagementType
          )
        }
      `),
      values
    );

    return result.validateExpertConsultationPreferences;
  };
}

export function addConsultation(
  values: ActionAddConsultationMutationVariables
): AppThunk<Promise<ActionAddConsultationMutation['addConsultation']>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionAddConsultation(
          $started_at: Datetime!
          $recording_url: String!
          $engagement_type: EngagementType!
          $recording_filename: String!
          $expert_request_id: String
          $group_id: String
          $requester_id: String
          $expert_id: String
          $requester_name: String
          $expert_name: String
          $recording_duration: Duration
          $recording_file_size: Int
        ) {
          addConsultation(
            expert_request_id: $expert_request_id
            group_id: $group_id
            requester_id: $requester_id
            expert_id: $expert_id
            requester_name: $requester_name
            expert_name: $expert_name
            started_at: $started_at
            recording_url: $recording_url
            recording_duration: $recording_duration
            engagement_type: $engagement_type
            recording_file_size: $recording_file_size
            recording_filename: $recording_filename
          ) {
            id
            html_url
          }
        }
      `),
      values
    );

    dispatch({ type: CONSULTATION__CLEAR });

    return result.addConsultation;
  };
}

export function deleteConsultation(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionDeleteConsultation($consultationId: String!) {
          deleteConsultation(consultation_id: $consultationId)
        }
      `),
      { consultationId: id }
    );

    dispatch({ type: CONSULTATION__DELETE, id });
  };
}

export function orderTranscript(id: any, maxCredits: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionOrderTranscript($id: String!, $maxCredits: MoneyInput) {
          orderTranscription(consultation_id: $id, max_credits: $maxCredits) {
            id
            state
          }
        }
      `),
      { id, maxCredits }
    );

    const order = result.orderTranscription;

    dispatch({
      type: CONSULTATION__UPDATE,
      consultation: { id, transcription_order: order },
    });
  };
}

export function updateConsultation(values: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { data } = await graphql.client.mutate({
      mutation: UPDATE_CONSULTATION,
      variables: {
        ...values,
        trigger: triggerToConsultationUpdateTrigger(values.trigger),
      },
    });
    const consultation = data!.updateConsultation!;
    dispatch({ type: CONSULTATION__UPDATE, consultation });
    return consultation;
  };
}

export function reviewConsultation(values: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionReviewConsultation(
          $consultation_id: String!
          $match_experience_rating: Int
          $uselfulness_rating: Int
          $communication_rating: Int
          $connection_quality_rating: Int
          $review: String
          $target_feedback: String
          $onfrontiers_feedback: String
          $expert_usefulness_rating: Int
          $time_savings_feedback: Boolean
          $improved_bid_confidence_feedback: Boolean
          $partner_identification_feedback: Boolean
          $cost_savings_feedback: Boolean
          $new_opportunity_identification_feedback: Boolean
          $other_feedback: Boolean
          $other_text_feedback: String
        ) {
          reviewConsultation(
            consultation_id: $consultation_id
            match_experience_rating: $match_experience_rating
            uselfulness_rating: $uselfulness_rating
            communication_rating: $communication_rating
            connection_quality_rating: $connection_quality_rating
            review: $review
            target_feedback: $target_feedback
            onfrontiers_feedback: $onfrontiers_feedback
            expert_usefulness_rating: $expert_usefulness_rating
            time_savings_feedback: $time_savings_feedback
            improved_bid_confidence_feedback: $improved_bid_confidence_feedback
            partner_identification_feedback: $partner_identification_feedback
            cost_savings_feedback: $cost_savings_feedback
            new_opportunity_identification_feedback: $new_opportunity_identification_feedback
            other_feedback: $other_feedback
            other_text_feedback: $other_text_feedback
          ) {
            id
            review_target
            match_experience_rating
            uselfulness_rating
            communication_rating
            connection_quality_rating
            review
            target_feedback
            onfrontiers_feedback
            expert_usefulness_rating
            time_savings_feedback
            improved_bid_confidence_feedback
            partner_identification_feedback
            cost_savings_feedback
            new_opportunity_identification_feedback
            other_feedback
            other_text_feedback
          }
        }
      `),
      values
    );

    const review = result.reviewConsultation;

    if (review) {
      const reviewPropName = review.review_target === 'client' ? 'client_review' : 'expert_review';

      dispatch({
        type: CONSULTATION__DELETE,
        collection: 'unreviewed',
        id: values.consultation_id,
      });

      dispatch({
        type: CONSULTATION__UPDATE,
        consultation: {
          id: values.consultation_id,
          [reviewPropName]: review,
        },
      });

      return review;
    }
  };
}

export function updateConsultationReview(values: any): AppThunk<Promise<any>> {
  return async (dispatch, _, { graphql }) => {
    const result = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionUpdateConsultationReview($id: String!, $review: String!) {
          updateConsultationReview(id: $id, review: $review) {
            id
            consultation_id
            review_target
            match_experience_rating
            uselfulness_rating
            communication_rating
            connection_quality_rating
            review
            target_feedback
            onfrontiers_feedback
            expert_usefulness_rating
            time_savings_feedback
            improved_bid_confidence_feedback
            partner_identification_feedback
            cost_savings_feedback
            new_opportunity_identification_feedback
            other_feedback
            other_text_feedback
          }
        }
      `),
      values
    );

    const review = result.updateConsultationReview;

    if (review) {
      const reviewPropName = review.review_target === 'client' ? 'client_review' : 'expert_review';

      dispatch({
        type: CONSULTATION__UPDATE,
        consultation: {
          id: review.consultation_id,
          [reviewPropName]: review,
        },
      });
    }
  };
}

export function clearCollection(collection: any): AppThunk<Promise<any>> {
  return async (dispatch) => {
    // Reset the specified collection to the initial state
    dispatch({
      type: CONSULTATION__RESET_COLLECTION,
      collection,
    });
  };
}

export const LIST_CONSULTATIONS_FIELDS = gengql(/* GraphQL */ `
  fragment ListConsultationsFields on ConsultationPage {
    pageInfo {
      hasNextPage
    }
    edges {
      cursor
      node {
        id
        html_url
        external
        state
        starts_at
        billing_duration
        recording_duration
        archived
        engagement_type
        permissions
        requester {
          id
          username
          name
          first_name
          last_name
          html_url
          picture_url
        }
        requester_name
        expert {
          id
          username
          name
          first_name
          last_name
          html_url
          picture_url
        }
        expert_name
        expert_request {
          id
          name
        }
      }
    }
  }
`);

export function setListLoading(collection: any): UnknownAction {
  return { type: CONSULTATION__LIST_LOADING, collection };
}

function fetchConsultations(
  states: any,
  collection: CollectionType,
  cursor: any,
  pageSize = 10,
  all = true,
  sort = 'created_at',
  sortOrder = 'desc'
): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const reset = !cursor;
    const consultations = getState().consultations[collection];

    if (reset && !shouldResetCollection(consultations, pageSize)) return consultations;

    dispatch(setListLoading(collection));

    try {
      const result = await graphql.query(
        gengql(/* GraphQL */ `
          query actionFetchConsultations(
            $cursor: String
            $all: Boolean
            $states: [ConsultationState]
            $pageSize: Int!
            $userContext: String!
            $sort: String
            $sortOrder: String
          ) {
            consultations(
              after: $cursor
              all: $all
              states: $states
              first: $pageSize
              user_context: $userContext
              sort: $sort
              sort_order: $sortOrder
            ) {
              ...ListConsultationsFields
            }
          }
        `),
        {
          states,
          cursor,
          pageSize,
          all,
          sort,
          sortOrder,
          userContext: getState().ui.userContext,
        }
      );

      const page = result.consultations;

      dispatch({
        type: CONSULTATION__BATCH_ADD,
        collection,
        reset,
        ...page,
      });

      return page;
    } finally {
      dispatch({ type: CONSULTATION__LIST_LOADED, collection });
    }
  };
}

export function fetchUnreviewed(): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const collection = 'unreviewed';

    const consultations = getState().consultations[collection];
    if (!shouldResetCollection(consultations, 1000)) return consultations;

    dispatch({ type: CONSULTATION__LIST_LOADING, collection });

    try {
      const result = await graphql.query(
        gengql(/* GraphQL */ `
          query actionfetchUnreviewed {
            unreviewedConsultations {
              pageInfo {
                hasNextPage
              }
              edges {
                cursor
                node {
                  id
                  html_url
                  external
                  state
                  starts_at
                  proposed_times
                  requester {
                    id
                    name
                    first_name
                    last_name
                  }
                  expert {
                    id
                    name
                    first_name
                    last_name
                  }
                }
              }
            }
          }
        `)
      );

      const page = result.unreviewedConsultations;

      dispatch({
        type: CONSULTATION__BATCH_ADD,
        collection,
        reset: true,
        ...page,
      });

      return page;
    } finally {
      dispatch({ type: CONSULTATION__LIST_LOADED, collection });
    }
  };
}

export function fetchAwaiting(
  cursor: any,
  pageSize = 10,
  all = true,
  collection: CollectionType = 'awaiting'
): AppThunk<Promise<any>> {
  const states = ['negotiating_expert_time', 'negotiating_client_time'];
  return fetchConsultations(states, collection, cursor, pageSize, all, 'created_at');
}

export function fetchCanceled(
  cursor: any,
  pageSize = 10,
  all = true,
  collection: CollectionType = 'canceled'
): AppThunk<Promise<any>> {
  const states = ['canceled', 'denied', 'client_rejected', 'expired', 'incomplete'];
  return fetchConsultations(states, collection, cursor, pageSize, all, 'canceled_at');
}

export function fetchConfirmed(
  cursor: any,
  pageSize = 10,
  all = true,
  collection: CollectionType = 'confirmed'
): AppThunk<Promise<any>> {
  const states = [
    'confirmed',
    'awaiting_join',
    'awaiting_client_join',
    'awaiting_expert_join',
    'awaiting_expert_review',
    'awaiting_client_accept',
    'in_progress',
    'finalizing',
  ];
  return fetchConsultations(states, collection, cursor, pageSize, all, 'starts_at', 'asc');
}

export function fetchCompleted(
  cursor: any,
  pageSize = 10,
  all = true,
  collection?: CollectionType
): AppThunk<Promise<any>> {
  const states = ['completed'];
  return fetchConsultations(
    states,
    collection || COLLECTION_TYPES.COMPLETED,
    cursor,
    pageSize,
    all,
    'ended_at'
  );
}

export function fetchPredictTransactions(
  expertId: string,
  expertRequestId: string,
  groupId: string,
  engagementType: EngagementType,
  expectedDuration: string,
  billRate?: any,
  creditRate?: any
): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      gengql(/* GraphQL */ `
        query actionfetchPredictTransactions(
          $expertId: String!
          $expertRequestId: String
          $groupId: String
          $engagementType: EngagementType!
          $expectedDuration: Duration
          $billRate: Int
          $creditRate: Int
        ) {
          predictTransactions(
            expert_id: $expertId
            expert_request_id: $expertRequestId
            group_id: $groupId
            engagement_type: $engagementType
            expected_duration: $expectedDuration
            bill_rate: $billRate
            credit_rate: $creditRate
          ) {
            booking_fee {
              cents
              currency
            }
            client_expert_fee {
              cents
              currency
            }
            expert_earnings {
              cents
              currency
            }
          }
        }
      `),
      {
        expertId,
        expertRequestId,
        groupId,
        engagementType,
        expectedDuration,
        billRate,
        creditRate,
      }
    );

    return result.predictTransactions;
  };
}

export function dismissConsultationReview(id: any, permanent: any): UnknownAction {
  return {
    type: CONSULTATION__DISMISS_REVIEW,
    id,
    permanent,
  };
}

export function dialOutExpert(consultationId: any): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) =>
    graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionDialOutExpert($consultationId: String!) {
          dialOutExpert(consultation_id: $consultationId)
        }
      `),
      { consultationId }
    );
}

export function dialOutMe({ consultationId, phoneNumber }: any): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) =>
    graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionDialOutMe($consultationId: String!, $phoneNumber: String!) {
          dialOutMe(consultation_id: $consultationId, phone_number: $phoneNumber)
        }
      `),
      { consultationId, phoneNumber }
    );
}

export function changeCarrier({ consultation, carrier }: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const consultationId = consultation.id;
    const result = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionChangeCarrier($consultationId: String!, $carrier: CarrierType!) {
          updateConferenceCarrier(consultation_id: $consultationId, carrier: $carrier) {
            id
            carrier
          }
        }
      `),
      { consultationId, carrier }
    );

    const { updateConferenceCarrier: conference } = result;

    dispatch({
      type: CONSULTATION__UPDATE,
      consultation: { ...consultation, conference },
    });
  };
}

export function fetchAttachmentContent(attachment: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState) => {
    const resp = await fetch(attachment.location);
    const result = await resp.text();
    return result;
  };
}

export function addAttachments({ consultation, attachments }: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const consultationId = consultation.id;
    const result = await graphql.mutate(
      gengql(/* GraphQL */ `
        mutation actionAddAttachments(
          $consultationId: String!
          $attachments: [EngagementAttachmentInput]!
        ) {
          consultationAddAttachments(consultation_id: $consultationId, attachments: $attachments) {
            id
            created_at
            author {
              id
              name
              html_url
            }
            expert_request_id
            consultation_id
            name
            description
            file_url
          }
        }
      `),
      { consultationId, attachments }
    );

    const addedAttachments = result?.consultationAddAttachments;

    if (addedAttachments && addedAttachments.length > 0) {
      const existingAttachments = consultation.attachments || [];
      dispatch({
        type: CONSULTATION__UPDATE,
        consultation: {
          ...consultation,
          attachments: existingAttachments.concat(addedAttachments),
        },
      });
    }
  };
}
