import { ApolloError } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ConnectedProps, connect } from 'react-redux';

import { notify } from '@/actions/ui';
import login, { SignupType } from '@/auth';
import { setBasicAuth } from '@/auth/store';
import Dialog from '@/components/Dialog/Dialog';
import Link from '@/components/Link';
import { APIError, ERROR_CODES, OtpMethod, UnauthorizedError, hasErrorCode } from '@/core/api';
import { GroupDomain } from '@/group';
import { RootState } from '@/store';
import { white } from '@/theme/colors';

import Credentials from './Credentials';
import EnrollOtpAuth from './EnrollOtpAuth';
import VerificationCode from './VerificationCode';

const useStyles = makeStyles({
  loginErrorLink: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: white,
    textDecoration: 'underline',
  },
  LoginErrorSubText: {
    fontSize: 14,
  },
});

function makeSentence(s: string) {
  if (!s) return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

interface LoginProps {
  initialErrors?: string[];
  nextUrl?: string;
  signupType?: SignupType;
  invite?: string;
  domain?: GroupDomain;
}

const connector = connect(
  (state: RootState) => ({
    initialErrors: state.runtime.errors,
  }),
  {
    notify,
    setBasicAuth,
  }
);

type Step = 'initial' | 'mfa' | 'mfa_setup';

interface OTPConfiguration {
  method?: OtpMethod;
  maskedAddress?: string;
}

const Login = ({
  initialErrors = [],
  notify,
  setBasicAuth,
  nextUrl,
  signupType,
  invite,
  domain,
}: LoginProps & ConnectedProps<typeof connector>) => {
  const s = useStyles();
  const [step, setStep] = useState<Step>('initial');
  const [groupId, setGroupId] = useState<string | undefined>(undefined);
  const [otpConfiguration, setOtpConfiguration] = useState<OTPConfiguration>({});
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState([]);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [finalAttemptDialogOpen, setFinalAttemptDialogOpen] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonResendDisable, setButtonResendDisable] = useState(false);

  // AlertId used in the automation tests
  const [alertId, setAlertId] = useState<string | undefined>(undefined);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return Promise.resolve();
    }

    const token = await executeRecaptcha('login');
    setRecaptchaToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleEmail = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setEmail(e.target.value),
    []
  );
  const handlePassword = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setPassword(e.target.value),
    []
  );
  const handleOtp = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setOtp(e.target.value),
    []
  );
  const hasTypedOtp = useMemo(() => otp.length > 0, [otp]);

  const handleCancel = useCallback(() => {
    setOtp('');
    setErrors([]);
    setAlertId(undefined);
    setStep('initial');
    setOtpConfiguration({});
    setButtonDisable(false);
    setButtonResendDisable(false);
  }, []);

  const securityEmail = 'hello@onfrontiers.com';

  const handleResend = async () => {
    setButtonResendDisable(true);
    try {
      await login({ email, password, next: nextUrl, recaptchaToken });
    } catch (err: unknown) {
      if (
        !(err instanceof UnauthorizedError) ||
        !hasErrorCode(err, ERROR_CODES.AUTH_MFA_REQUIRED)
      ) {
        throw err;
      }
      notify(`Verification code sent to ${err.otpMaskedAddress}`);
      return;
    }
    console.warn('Unexpected login success without otp');
  };

  const handleSubmit = async () => {
    setButtonDisable(true);

    try {
      await login({ email, password, otp, next: nextUrl, recaptchaToken });
    } catch (err) {
      handleReCaptchaVerify();

      let message;

      if (err instanceof ApolloError) {
        console.error(err);
        message = 'Sorry, the service is currently unavailable.';
      } else if (err instanceof UnauthorizedError) {
        switch (err.code) {
          case ERROR_CODES.AUTH_INVALID_CREDENTIALS:
            message = 'Invalid username or password';
            if (err.remainingAttempts === 1) {
              setFinalAttemptDialogOpen(true);
            }
            if ((err?.remainingAttempts ?? 0) > 0 && (err?.remainingAttempts ?? 0) < 3) {
              message = (
                <span>
                  {message}. To prevent your account from being locked,&nbsp;
                  <Link className={s.loginErrorLink} to="/password_reset">
                    recover your password
                  </Link>
                </span>
              );
            }
            break;
          case ERROR_CODES.AUTH_LOCKED_FOR_INTERVAL:
            message = (
              <span>
                Your account has been locked for 24 hours
                <br />
                <div className={s.LoginErrorSubText}>
                  If you need urgent assistance please email&nbsp;
                  <a className={s.loginErrorLink} href={`mailto:${securityEmail}`}>
                    {securityEmail}
                  </a>
                </div>
              </span>
            );
            break;
          case ERROR_CODES.AUTH_MFA_SETUP_REQUIRED:
            message = 'Two-factor authentication is required';
            if (step !== 'mfa_setup') {
              await setBasicAuth(email, password);
              setGroupId(err.mfaGroupId);
              setStep('mfa_setup');
            }
            break;
          case ERROR_CODES.AUTH_MFA_REQUIRED:
            if (step !== 'mfa') {
              setOtpConfiguration({
                method: err.otpMethod,
                maskedAddress: err.otpMaskedAddress,
              });
              setStep('mfa');
            }
            break;
          case ERROR_CODES.AUTH_OTP_INVALID:
            message = 'Invalid verification code';
            break;
          default:
            message = makeSentence(err.message);
        }
      } else if (err instanceof APIError) {
        console.error(err);
        message = 'Sorry, unknown error.';
      } else {
        throw err;
      }

      setAlertId('invalidLoginAlert');
      setTimeout(() => {
        setButtonDisable(false);
      }, 1000);
      // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setErrors(message ? [message] : []);
      setOtp('');
    }
  };
  const allErrors = [...errors, ...initialErrors];

  switch (step) {
    case 'initial':
      return (
        <>
          <Credentials
            invite={invite}
            domain={domain}
            signupType={signupType}
            nextUrl={nextUrl}
            email={email}
            password={password}
            onEmailChange={handleEmail}
            onPasswordChange={handlePassword}
            onSubmit={(e: FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              handleSubmit();
            }}
            errors={allErrors}
            buttonDisable={buttonDisable || (!recaptchaToken && !!executeRecaptcha)}
            alertId={alertId}
          />
          <Dialog
            PaperProps={{
              id: 'invalidUserPasswordPopUp',
            }}
            open={finalAttemptDialogOpen}
            onCancel={() => {
              setFinalAttemptDialogOpen(false);
            }}
          >
            <span>
              You only have one remaining opportunity to input your password before you are locked
              out.&nbsp;
              <Link to="/password_reset">Recover your password</Link>.
            </span>
          </Dialog>
        </>
      );
    case 'mfa_setup': {
      if (!groupId) {
        throw new Error('MFA setup requires a group ID');
      }
      return <EnrollOtpAuth groupId={groupId} setOtp={setOtp} email={email} />;
    }
    case 'mfa':
      if (!otpConfiguration.method || !otpConfiguration.maskedAddress) {
        throw new Error('MFA step requires a method and masked address');
      }
      return (
        <VerificationCode
          code={otp}
          onChange={handleOtp}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          onResend={handleResend}
          disabled={!hasTypedOtp}
          buttonResendDisable={buttonResendDisable}
          error={errors && errors[0]}
          method={otpConfiguration.method}
          maskedAddress={otpConfiguration.maskedAddress}
        />
      );
  }
};

export default connector(Login);
