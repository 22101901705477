import { gengql } from '@/__generated__';

export const GET_GROUP = gengql(/* GraphQL */ `
  query actionFetchGroup(
    $id: String!
    $members: Boolean!
    $awaitingMembers: Boolean!
    $transactions: Boolean!
    $aboutPage: Boolean!
    $profileKeywordsDefinition: Boolean!
    $internalNetwork: Boolean!
    $domain: Boolean!
    $savedSearches: Boolean!
    $publicData: Boolean
  ) {
    group(id: $id, public: $publicData) {
      id
      html_url
      slug
      name
      internal
      branding_logo_url
      branding_show_poweredbyof
      enforce_2fa
      members @include(if: $members) {
        edges {
          node {
            id
            role
            email
            user {
              id
              html_url
              picture_url
              name
              first_name
              last_name
              city
              country
            }
          }
        }
        pageInfo {
          total
        }
      }
      members_awaiting: members(states: [awaiting_approval]) @include(if: $awaitingMembers) {
        edges {
          node {
            id
            user {
              id
              html_url
              picture_url
              name
              first_name
              last_name
            }
          }
        }
        pageInfo {
          total
        }
      }
      permissions
      stats {
        expert_request_count
        consultation_count
      }
      billing_account {
        id
        type
        entity_type
        seat_count
        state
        purchases {
          remaining {
            cents
            currency
          }
          expires_at
        }
        credit_balance {
          cents
          currency
        }
        transactions @include(if: $transactions) {
          id
          created_at
          description
          money {
            cents
            currency
          }
          credits {
            cents
            currency
          }
          tracking_code
          state
        }
      }
      account_type
      internal_network @include(if: $internalNetwork) {
        id
        name
        expert_unpaid
      }
      saved_searches @include(if: $savedSearches) {
        id
        name
        url
      }
      domain @include(if: $domain) {
        id
        subdomain
        root_subdomain_redirect
        logo_url
        signup_enabled
        expert_signup_about
        expert_signup_about_mobile
        network_join_title
        member_signup_about
        member_signup_about_mobile
        signup_prompt_hourly_rate
        signup_prompt_marketplace
        signup_prompt_profile_publicity
        agreements(active_only: false) {
          id
          active
          policy_code
          policy_label
          policy_url
        }
      }
      default_anonymous_messaging
      about
      about_page @include(if: $aboutPage) {
        public
        enabled
        body_logo_url
        signup_url
        html_url
        search_engine_index
        logo_link_url
      }
      profile_keywords_definition @include(if: $profileKeywordsDefinition) {
        id
        name
      }
    }
  }
`);

export const LIST_GROUPS = gengql(/* GraphQL */ `
  query actionFetchGroups(
    $stats: Boolean!
    $billingAccount: Boolean!
    $includeMemberLimit: Boolean!
    $internalNetwork: Boolean!
    $keywordsConfig: Boolean!
    $internal: Boolean
    $memberOnly: Boolean
    $cursor: String
    $pageSize: Int!
    $sharingExperts: Boolean
    $memberLimit: Int
    $name: String
  ) {
    groups(
      internal: $internal
      member_only: $memberOnly
      first: $pageSize
      after: $cursor
      sharing_experts: $sharingExperts
      name: $name
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          html_url
          slug
          name
          internal
          about
          stats @include(if: $stats) {
            expert_request_count
            consultation_count
          }
          billing_account @include(if: $billingAccount) {
            id
            type
            state
            credit_balance {
              cents
              currency
            }
          }
          members(first: $memberLimit) @include(if: $includeMemberLimit) {
            edges {
              node {
                id
                role
                email
                user {
                  id
                  first_name
                  last_name
                  name
                  html_url
                  picture_url
                }
              }
            }
            pageInfo {
              total
            }
          }
          internal_network @include(if: $internalNetwork) {
            id
            name
          }
          profile_keywords_definition @include(if: $keywordsConfig) {
            id
            name
          }
        }
      }
    }
  }
`);

export const UPDATE_GROUP = gengql(/* GraphQL */ `
  mutation actionUpdateGroup(
    $id: String!
    $about: String
    $default_anonymous_messaging: Boolean
    $profile_keywords_definition: [String]
    $enforce_2fa: Boolean
  ) {
    updateGroup(
      id: $id
      about: $about
      default_anonymous_messaging: $default_anonymous_messaging
      profile_keywords_definition: $profile_keywords_definition
      enforce_2fa: $enforce_2fa
    ) {
      id
    }
  }
`);

export const UPDATE_GROUP_ABOUT_PAGE = gengql(/* GraphQL */ `
  mutation actionUpdateGroupAboutPage(
    $group_id: String!
    $body_logo_url: String
    $public: Boolean
    $enabled: Boolean
    $search_engine_index: Boolean
    $logo_link_url: String
  ) {
    updateGroupAboutPage(
      group_id: $group_id
      body_logo_url: $body_logo_url
      public: $public
      enabled: $enabled
      search_engine_index: $search_engine_index
      logo_link_url: $logo_link_url
    ) {
      body_logo_url
      public
      enabled
      html_url
      search_engine_index
      logo_link_url
    }
  }
`);

export const UPDATE_GROUP_BRANDING = gengql(/* GraphQL */ `
  mutation actionUpdateGroupBranding(
    $id: String!
    $branding_logo_url: String!
    $branding_show_poweredbyof: Boolean!
  ) {
    updateGroup(
      id: $id
      branding_logo_url: $branding_logo_url
      branding_show_poweredbyof: $branding_show_poweredbyof
    ) {
      id
      branding_logo_url
      branding_show_poweredbyof
    }
  }
`);
