import arrayMutators from 'final-form-arrays';
import setFieldData from 'final-form-set-field-data';
import moment from 'moment-timezone';
import { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { ExpertRequestType } from '@/__generated__/graphql';
import { notify, popup } from '@/actions/ui';
import {
  calculateExpertCredits,
  getEngagementType,
  getSuggestedTimes,
  isDurationEmpty,
  minimumTimeNotice,
  validate,
} from '@/consultation';
import { requestConsultation } from '@/consultation/store';
import { money } from '@/core/money';
import { RootState } from '@/store';

import TeamAccountPromoDialog from '../TeamAccountPromo/TeamAccountPromoDialog';
import RequestConsultationDialog, { FormData } from './Dialog';
import {
  ERR_NOT_ENOUGH_CREDITS,
  ERR_NO_BILLING_ACCOUNT,
  ERR_OUT_OF_MARKETPLACE,
  ERR_REQUESTER_EQUALS_EXPERT,
  MSG_OUT_OF_MARKETPLACE,
} from './constants';
import minimumTimeNoticePopup from './minimumTimeNoticePopup';

interface RequestConsultationProps {
  profile: any;
  returnTo?: string;
  expertId: string;
  onClose?: () => void;
  open: boolean;
  expertRequest?: any;
  groupId?: string;
}

const connector = connect(
  (state: RootState, props: RequestConsultationProps) => {
    const group = state.groups.all.edges.find((g: any) => g.node.id === props.groupId)?.node;
    return {
      viewer: state.viewer,
      group,
    };
  },
  {
    requestConsultation,
    notify,
    popup,
  }
);

const RequestConsultation = ({
  profile,
  returnTo,
  expertId,
  onClose,
  open,
  expertRequest,
  groupId,

  // Redux
  viewer,
  group,
  requestConsultation,
  notify,
  popup,
}: RequestConsultationProps & ConnectedProps<typeof connector>) => {
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    if (returnTo) navigate(returnTo);
    if (onClose) onClose();
  }, [navigate, onClose, returnTo]);

  const purchasePromoPopup = useCallback(
    (popupFn: any, purchaseLink: any, isTeam: any) => {
      return popupFn({
        title: 'Not enough credits',
        contents: (
          <>
            There are not enough credits to request the consultation.
            {isTeam && (
              <>
                <br />
                You can purchase more credits in your team settings.
              </>
            )}
          </>
        ),
        buttonAlignment: 'space-between',
        buttons: [
          {
            label: 'Cancel',
            callback: () => {},
            flat: true,
          },
          // {
          //   label: 'Contact',
          //   primary: true,
          //   callback: () => window.HubSpotConversations?.widget?.open(),
          // },
          {
            label: 'Purchase Credits',
            primary: true,
            callback: () => navigate(purchaseLink),
          },
        ],
      });
    },
    [navigate]
  );

  const handleSubmit = useCallback(
    (values: FormData) =>
      new Promise<void>((resolve, reject) => {
        const dates = values.dates.filter(Boolean);

        (async () => {
          try {
            const {
              duration,
              engagement_type: engagementType,
              booking_fee: bookingFee,
              ...other
            } = values;

            const expertCredits = calculateExpertCredits(
              profile.credit_rate,
              duration,
              engagementType
            );
            const cents = expertCredits + bookingFee;

            const consultation = await requestConsultation({
              ...other,
              duration,
              engagement_type: engagementType,
              expert_id: expertId,
              credits: money({ cents, currency: 'OFC' }),
              dates,
            });

            notify('Your request has been submitted.', 'success');

            navigate(`/consultation/${consultation.id}`);

            resolve();
          } catch (err: any) {
            if (err.message === ERR_NOT_ENOUGH_CREDITS || err.message === ERR_NO_BILLING_ACCOUNT) {
              // if (viewer.admin) {
              //   return resolve({
              //     group_id: 'Team does not have enough credits',
              //   });
              // }
              let purchaseLink = group ? `/team/${group.slug}` : '';
              purchaseLink += `/settings/credits/purchase`;
              purchasePromoPopup(popup, purchaseLink, !!group);
              return resolve();
            }

            if (err.message === ERR_OUT_OF_MARKETPLACE) {
              notify(`${MSG_OUT_OF_MARKETPLACE}.`, 'error');
              return resolve();
            }

            if (err.message === ERR_REQUESTER_EQUALS_EXPERT) {
              notify('Requester and expert cannot be the same.', 'error');
              return resolve();
            }

            if (err.message === 'User not logged in') {
              notify('User not logged in.', 'error');
            } else {
              notify('An error occurred when trying to submit your request.', 'error');
            }
            reject(err);
          }
        })();
      }),
    [
      expertId,
      group,
      navigate,
      notify,
      popup,
      profile.credit_rate,
      purchasePromoPopup,
      requestConsultation,
    ]
  );

  const handleSubmitWithCheck = useCallback(
    (values: any) => {
      const dates = values.dates.filter(Boolean);
      if (minimumTimeNotice(dates)) {
        handleSubmit(values);
      } else {
        minimumTimeNoticePopup(popup, false, () => handleSubmit(values));
      }
      if (onClose) onClose();
    },
    [handleSubmit, popup, onClose]
  );

  const initialValues = useMemo(() => {
    const commonValues = {
      expert_request_id: expertRequest?.id || '',
      group_id: groupId || '',
      requester_id: '',
      disclosure: 'full',
      engagement_type: getEngagementType(expertRequest),
      booking_fee: 0,
    };

    return expertRequest?.er_type === ExpertRequestType.WrittenReview
      ? {
          ...commonValues,
          dates: [moment().add(7, 'd').startOf('day')],
          duration:
            (!isDurationEmpty(expertRequest.expected_duration) &&
              expertRequest.expected_duration) ||
            '1h',
        }
      : {
          ...commonValues,
          dates: getSuggestedTimes({
            t1: viewer.timezone,
            t2: profile.timezone,
            hourOffset: 12,
            hourInterval: 1,
          }),
          duration: '1h',
        };
  }, [expertRequest, groupId, profile.timezone, viewer.timezone]);

  if (!viewer.groups || viewer.groups.length === 0) {
    return (
      <TeamAccountPromoDialog
        open={open}
        onClose={handleClose}
        showEventTrack="promo.show.team_account.consultation_request"
        clickEventTrack="promo.click.team_account.consultation_request"
      />
    );
  }

  return (
    <Form<FormData>
      initialValues={initialValues}
      onSubmit={handleSubmitWithCheck}
      validate={validate(viewer)}
      // @ts-ignore - refactor when change to formik
      mutators={{ setFieldData, ...arrayMutators }}
      // Only re-render if these parts of the form state change
      subscription={{
        submitting: true,
        values: true,
        initialValues: true,
      }}
    >
      {(props) => (
        <RequestConsultationDialog
          {...props}
          expertId={expertId}
          profile={profile}
          expertRequest={expertRequest}
          open={open}
          onClose={handleClose}
        />
      )}
    </Form>
  );
};

export default connector(RequestConsultation);
