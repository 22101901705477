import MarkdownIt from 'markdown-it';
import { ConnectedProps, connect } from 'react-redux';

import Button from '@/components/Button/Button';
import Link from '@/components/Link/Link';
import Logo from '@/components/Logo/Logo';
import LongText from '@/components/LongText/LongText';
import Layout from '@/components/MarketingLayout/MarketingLayout';
import MarketingSection from '@/components/MarketingSection/MarketingSection';
import MediaQuery from '@/components/MediaQuery/MediaQuery';
import { RootState } from '@/store';
import { sand } from '@/theme/colors';
import { SCREEN_XS } from '@/theme/screens';

import s from './TeamAbout.module.scss';
import ConnectionsIcon from './connections.svg';

const md = new MarkdownIt();

interface TeamAboutProps {
  group: any;
  siteSettings: any;
}

const connector = connect((state: RootState) => ({
  siteSettings: state.siteSettings || {},
}));

const TeamAboutPage = (props: TeamAboutProps & ConnectedProps<typeof connector>): JSX.Element => {
  const { group, siteSettings } = props;
  const aboutPage = group.about_page;

  const bodyLogo = (
    <img
      alt="logo"
      className={s.aboutImage}
      src={aboutPage.body_logo_url}
      style={{ maxWidth: 250, maxHeight: 250 }}
    />
  );

  return (
    <Layout>
      <MarketingSection className={s.hero} bodyClassName={s.heroBody} padding={0}>
        <div className={s.splashTitle}>{group.name}</div>
        <div className={s.splashSubTitle}>Join our knowledge network on OnFrontiers</div>
      </MarketingSection>

      <MarketingSection className={s.aboutSection} bodyClassName={s.about}>
        {aboutPage.body_logo_url && (
          <div className={s.aboutLogo}>
            {aboutPage.logo_link_url ? (
              <Link to={aboutPage.logo_link_url}>{bodyLogo}</Link>
            ) : (
              bodyLogo
            )}
          </div>
        )}

        <div className={s.aboutText}>
          <LongText text={group.about} />

          {aboutPage.signup_url && (
            <MediaQuery maxWidth={SCREEN_XS}>
              {(isXs: boolean) => (
                <div className={s.aboutAction}>
                  <Link to={aboutPage.signup_url}>
                    <Button
                      size={isXs ? 'large' : 'normal'}
                      style={{
                        fontSize: 18,
                        ...(isXs ? { width: '100%' } : {}),
                      }}
                    >
                      Join Network
                    </Button>
                  </Link>
                </div>
              )}
            </MediaQuery>
          )}
        </div>
      </MarketingSection>

      <MarketingSection padding={60} style={{ backgroundColor: sand }}>
        <div className={s.aboutOF}>
          <div className={s.aboutOFImage}>
            <img alt="" src={ConnectionsIcon} />
          </div>
          <div className={s.aboutOFContent}>
            <Logo dark style={{ width: 320, height: 30 }} />

            <div
              dangerouslySetInnerHTML={{
                __html: md.render(siteSettings.group_about_page_onfrontiers_text || ''),
              }}
            />
          </div>
        </div>
      </MarketingSection>
    </Layout>
  );
};

export default connector(TeamAboutPage);
