import cx from 'classnames';
import { ConnectedProps, connect } from 'react-redux';

import FAIcon from '@/components/Icon/FAIcon';
import Link from '@/components/Link';
import MediaQuery from '@/components/MediaQuery';
import MemberList, { Member } from '@/components/MemberList';
import Picture from '@/components/Picture/Picture';
import ExpertRequestPreview from '@/expertrequest/components/ExpertRequestPreview';
import { RootState } from '@/store';
import { white } from '@/theme/colors';
import { SCREEN_XS } from '@/theme/screens';

import s from './ProjectPreview.module.scss';

interface ProjectPreviewProps {
  project: {
    name: string;
    expert_requests: any[];
    group: { name: string };
    members?: Member[];
    html_url: string;
  };
  showState?: boolean;
  showContextActions?: boolean;
}

const connector = connect((state: RootState) => ({
  viewer: state.viewer,
}));

function ProjectPreview({
  project,
  showState = true,
  showContextActions = true,
  viewer,
}: ProjectPreviewProps & ConnectedProps<typeof connector>) {
  const iconStyle = {
    width: 'auto',
    height: 'auto',
    fontSize: 20,
    margin: 0,
    color: white,
  };

  const { name, expert_requests: expertRequests } = project;
  const membersOrder = (a: any, b: any) => {
    const roles = ['manager', 'associate', 'owner', 'member', 'viewer'];
    return roles.indexOf(a.role) - roles.indexOf(b.role);
  };
  const membersToShow = (project.members || [])
    .filter((m: any) => m.state === 'active')
    .sort(membersOrder);
  const group = project.group && project.group.name;

  return (
    <div className={s.root}>
      <div className={s.header}>
        <div className={s.title}>
          <Link className={s.titleLink} to={project.html_url} style={{ width: '100%' }}>
            <MediaQuery minWidth={SCREEN_XS + 1}>
              <Picture style={{ flex: '0 0 auto' }} size={40}>
                <FAIcon iconSet="fal" icon="clipboard-list" style={iconStyle} />
              </Picture>
            </MediaQuery>
            <span className={s.titleName}>{name}</span>
          </Link>
          {viewer.admin && group && <div className={s.groupName}>{group}</div>}
        </div>
        <MemberList
          className={s.memberList}
          members={membersToShow}
          membersDisplayLimit={5}
          total={membersToShow.length}
          htmlUrl={project.html_url}
        />
      </div>
      <div className={s.requests}>
        {expertRequests && expertRequests.length > 0 ? (
          expertRequests.map((er: any, index: any) => (
            <ExpertRequestPreview
              key={er.id}
              request={er}
              project={project}
              className={cx(s.request, {
                [s.requestDivider]: index !== expertRequests.length - 1,
              })}
              showState={showState}
              showContextActions={showContextActions}
              showAddAction
              showCloseAction
            />
          ))
        ) : (
          <div className={s.emptyProject}>No expert request for this project.</div>
        )}
      </div>
    </div>
  );
}

export default connector(ProjectPreview);
