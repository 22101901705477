import React from 'react';
import { connect } from 'react-redux';

import Divider from '@/components/Divider';
import Module from '@/components/Module';
import { Select } from '@/components/Select/Select';
import { updateProfile } from '@/profile/store';
import { updateUser } from '@/store/user';

import ExpertApplicationPanel from './ExpertApplicationPanel';
import ProfileInfo from './ProfileInfo';
import ProfileManagementPanel from './ProfileManagementPanel';
import Source from './Source';

function AdminModule({
  user,
  profile,
  updateProfile,
  updateUser,
  style,
  onEditMarketplace,
  onEditBackgroundCheck,
  onEditRate,
  onEditEmail,
  onEditPhone,
  onEditTimezone,
  onEditSkype,
  onEditAdditionalInformation,
}: any): JSX.Element {
  const updateTier = ({ target: { value } }: any) => {
    updateProfile({ id: profile.id, tier: value });
  };

  const updateSignupType = ({ target: { value } }: any) => {
    updateUser({ id: user.id, signup_type: value });
  };

  return (
    <Module title="Admins Only" style={style}>
      {user && <ExpertApplicationPanel userId={user.id} />}

      <ProfileInfo
        user={user}
        profile={profile}
        onEditMarketplace={onEditMarketplace}
        onEditBackgroundCheck={onEditBackgroundCheck}
        onEditRate={onEditRate}
        onEditEmail={onEditEmail}
        onEditPhone={onEditPhone}
        onEditTimezone={onEditTimezone}
        onEditSkype={onEditSkype}
      />

      <Divider />

      {!user &&
        profile.sources &&
        profile.sources.map((s: any) => (
          <div key={s.created_at}>
            <Source source={s} />
            <Divider />
          </div>
        ))}

      {user && user.expert_state && (
        <Select
          id="expertTier"
          value={profile.tier}
          onChange={updateTier}
          label="Expert Tier"
          options={[1, 2, 3, 4].map((i) => ({ label: `${i}`, value: i }))}
        />
      )}

      {user && (
        <Select
          id="userSignupType"
          value={user.signup_type}
          onChange={updateSignupType}
          label="Signup Type"
          sort={false}
          options={['Expert', 'Client', 'Staff'].map((v) => ({
            label: v,
            value: v.toLowerCase(),
          }))}
        />
      )}

      <ProfileManagementPanel
        user={user}
        profile={profile}
        onEditAdditionalInformation={onEditAdditionalInformation}
      />
    </Module>
  );
}

export default connect(undefined, {
  updateUser,
  updateProfile,
})(AdminModule);
