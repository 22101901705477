import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import pluralize from 'pluralize';
import { useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { gengql } from '@/__generated__';
import { Consultation, getUser, getUserName } from '@/consultation';
import { isCallType } from '@/expertrequest/store';
import { RootState } from '@/store';
import { darkBlue, white } from '@/theme/colors';

import Alert from '../Alert';
import Button from '../Button';
import MaterialIcon from '../Icon/MaterialIcon';
import Link from '../Link';
import Picture from '../Picture';
import s from './ConsultationsStarting.module.scss';

const GET_CONSULTATIONS = gengql(/* GraphQL */ `
  query fetchStartingConsultations($userContext: String!) {
    startingConsultations(user_context: $userContext) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          html_url
          starts_at
          expert_request {
            id
            er_type
          }
          requester {
            id
            name
            first_name
            last_name
            html_url
            picture_url
          }
          requester_name
          requester_identifier
          expert {
            id
            name
            first_name
            last_name
            html_url
            picture_url
          }
          expert_name
          expert_identifier
          state
        }
      }
    }
  }
`);

const connector = connect((state: RootState) => ({
  viewer: state.viewer,
  call: state.call, // KT: this isn't really used anymore for Zoom.
  userContext: state.ui.userContext,
}));

const ConsultationsStarting = ({ userContext, viewer, call }: ConnectedProps<typeof connector>) => {
  const { data } = useQuery(GET_CONSULTATIONS, { variables: { userContext } });

  const [consultationDialogClosed, setConsultationDialogClosed] = useState<Record<string, boolean>>(
    {}
  );

  const closeConsultationAlert = (id: string) => {
    setConsultationDialogClosed((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const edges = data?.startingConsultations?.edges || [];
  const consultations = edges.map((e) => e?.node).filter((c) => !!c);
  const filteredConsultations = consultations.filter(
    (c) =>
      !consultationDialogClosed[c.id] &&
      c.id !== call.consultationId &&
      (!c.expert_request || (c.expert_request.er_type && isCallType(c.expert_request.er_type)))
  );

  if (filteredConsultations.length === 0) return null;

  return (
    <div>
      {filteredConsultations.map((c) => {
        const diff = moment(c.starts_at).diff(moment());
        const minutes = moment.duration(diff).minutes();

        const user = getUser(viewer, userContext, c as Consultation, false);
        const userName = getUserName(viewer, userContext, c as Consultation, false);

        const prefix = minutes <= 0 ? 'In progress' : `Starting in ${pluralize('minute', minutes)}`;

        return (
          <Alert
            key={c.id}
            backgroundColor={darkBlue}
            onRequestClose={() => closeConsultationAlert(c.id)}
          >
            <div className={s.consultationStarting}>
              <div>
                <Picture user={user} size={80} />
              </div>

              <div className={s.consultationStartingContent}>
                <div className={s.consultationStartingText}>
                  {prefix}: &nbsp;
                  <span className={s.consultationStartingUser}>Call with {userName}</span>
                </div>

                <Link to={`${c.html_url}?join_call=true`}>
                  <Button
                    startIcon={<MaterialIcon color={white} icon="phone" size={20} />}
                    size="medium"
                  >
                    Join Call
                  </Button>
                </Link>
              </div>
            </div>
          </Alert>
        );
      })}
    </div>
  );
};

export default connector(ConsultationsStarting);
