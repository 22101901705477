import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Code, RotateCcw } from 'react-feather';
import { matchPath, useLocation, useNavigate, useParams, useSearchParams } from 'react-router';

import { Pagination } from '@/componentsv2';
import Button from '@/componentsv2/Button';
import { segmentTracking } from '@/core/analytics';
import { SearchResponse } from '@/openapi';
import APP_ROUTES from '@/routes/APP_ROUTES';
import { useUpdateSavedSearch } from '@/searchv2/mutations/savedSearch';
import { useSavedSearchQuery } from '@/searchv2/queries/savedSearch';
import {
  DEFAULT_SEARCH_LIMIT,
  useSearchHashQuery,
  useSearchProfilesQuery,
} from '@/searchv2/queries/search';

import DebugDialog from './DebugDialog';
import Filters from './Filters';
import Results from './Results';

const testId = 'of-search-interface';

interface LocationState {
  filterId: string;
  keywordSearch: string;
}

const SearchInterface = ({ initialHash }: { initialHash?: string }): JSX.Element => {
  const enableSearchDebugging = useFeatureIsOn('web-search-debugging');
  const navigate = useNavigate();
  const location = useLocation();
  const { filterId: filterFromLocation, keywordSearch: keywordFromLocation } =
    (location.state as LocationState) || {
      filterId: '',
      keywordSearch: '',
    };
  const resultsContainerRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const [hash, setHash] = useState('');
  const [showDebugDialog, setShowDebugDialog] = useState(false);
  const [debugCode, setDebugCode] = useState<SearchResponse[] | SearchResponse>();
  const [pendingHash, setPendingHash] = useState(
    () => initialHash ?? searchParams.get('hash') ?? ''
  );
  const [popstateKey, setPopstateKey] = useState(0);
  const [page, setPage] = useState(() => Number(searchParams.get('page') ?? 1));
  const [query, setQuery] = useState(keywordFromLocation);
  const [initialQuery, setInitialQuery] = useState(keywordFromLocation);
  const [filterIds, setFilterIds] = useState<string[]>(
    filterFromLocation ? [filterFromLocation] : []
  );
  const { savedSearchId } = useParams();
  const { data: savedSearch } = useSavedSearchQuery(savedSearchId ?? '');

  const { data, isLoading, isFetching, isPreviousData } = useSearchProfilesQuery(
    {
      query,
      page,
      filterIds,
    },
    {
      enabled: !pendingHash,
    }
  );

  const { data: restoringHashData, isError: isSearchHashError } = useSearchHashQuery({
    hash: pendingHash,
  });

  const { mutate: updateSavedSearch } = useUpdateSavedSearch();

  useEffect(() => {
    if (!data) {
      return;
    }

    setHash(data.search_hash);
  }, [data]);

  useEffect(() => {
    // This data only gets updated when the location changes by popstate
    if (!restoringHashData) {
      return;
    }

    const newQuery = restoringHashData.query === '*' ? '' : restoringHashData.query;

    setQuery(newQuery);
    setInitialQuery(newQuery);
    setFilterIds(restoringHashData.filter_ids);
    setHash(restoringHashData.hash);
    setPendingHash('');
    setPopstateKey((prev) => prev + 1);
  }, [restoringHashData]);

  useEffect(() => {
    const currentParams = new URLSearchParams(window.location.search);
    const newParams = new URLSearchParams();

    const drawerIsOpen =
      !matchPath('search/', window.location.pathname) &&
      !matchPath('search/saved/:savedSearchId', window.location.pathname);

    // wait for hash sync
    if (pendingHash || drawerIsOpen) {
      return;
    }

    if (
      hash === (currentParams.get('hash') ?? '') &&
      page === Number(currentParams.get('page') ?? 1)
    ) {
      return;
    }

    if (hash) {
      newParams.append('hash', hash);
    }
    if (page !== 1) {
      newParams.append('page', page.toString());
    }

    navigate(`./?${newParams.toString()}`, {
      // if this logic is going to change, check triggered_by/source for Segment event if it's working as expected
      replace: !currentParams.get('hash'),
    });

    if (savedSearchId && hash) {
      updateSavedSearch({ id: savedSearchId, hash });
    }

    if (filterFromLocation) {
      setFilterIds([filterFromLocation]);
      setPopstateKey((prev) => prev + 1);
    }

    if (keywordFromLocation) {
      setQuery(keywordFromLocation);
      setInitialQuery(keywordFromLocation);
      setFilterIds([]);
      setPopstateKey((prev) => prev + 1);
    }
  }, [
    navigate,
    pendingHash,
    hash,
    page,
    savedSearchId,
    updateSavedSearch,
    filterFromLocation,
    keywordFromLocation,
  ]);

  useEffect(() => {
    const handlePopState = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const hash = searchParams.get('hash') ?? '';
      const page = Number(searchParams.get('page') ?? 1);

      if (savedSearchId && hash) {
        updateSavedSearch({ id: savedSearchId, hash });
      }

      setPendingHash(hash);
      setPage(page);
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [savedSearchId, updateSavedSearch]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>, queryInputValue: string) => {
    e.preventDefault();
    setQuery(queryInputValue);
    setInitialQuery(queryInputValue);
    setPage(1);
  };

  const handlePageChange = (page: number) => {
    window.scrollTo(0, 0);
    setPage(page);
    resultsContainerRef.current?.focus();
  };

  const handleClear = useCallback(() => {
    setQuery('');
    setInitialQuery('');
    setPage(1);
    setFilterIds([]);
    setHash('');
    setPendingHash('');
    setPopstateKey((prev) => prev + 1);

    segmentTracking('user-cleared-search', {
      event_type: 'Search',
      event_name: 'User cleared search',
      savedsearch_id: savedSearch?.id,
      savedsearch_name: savedSearch?.name,
    });

    navigate(APP_ROUTES.search);

    if (savedSearch?.id) {
      enqueueSnackbar({
        message: 'Started new search',
        variant: 'success',
        action: (
          <Button variant="secondary" onClick={() => navigate(-1)} endIcon={RotateCcw}>
            Undo
          </Button>
        ),
      });
    }
  }, [navigate, savedSearch]);

  const applyFilter = (filterId: string) => {
    setFilterIds((prev) => (prev.includes(filterId) ? prev : [...prev, filterId]));
    setPage(1);
  };

  const removeFilter = (filterId: string) =>
    setFilterIds((prev) => prev.filter((prevId) => prevId !== filterId));

  useEffect(() => {
    if (isSearchHashError) {
      handleClear();
    }
  }, [handleClear, isSearchHashError]);

  const resultsAreLoading = isLoading || (!!pendingHash && pendingHash !== hash);

  return (
    <div className="grow items-start md:flex" data-testid={testId}>
      <Filters
        data={data}
        isFetching={isFetching}
        isLoading={isLoading}
        filterIds={filterIds}
        handleClear={handleClear}
        handleSubmit={handleSubmit}
        initialQuery={initialQuery}
        popstateKey={popstateKey}
        query={query}
        applyFilter={applyFilter}
        removeFilter={removeFilter}
      />
      <main
        className="self-stretch border-l border-grey-400 bg-gray-50 p-16 md:w-[calc(100%-280px)]"
        ref={resultsContainerRef}
        tabIndex={-1}
      >
        {enableSearchDebugging && data?.results?.length ? (
          <div className="fixed bottom-10 right-10">
            <Button
              variant="destructive"
              size="small"
              onClick={() => {
                setDebugCode(data?.results);
                setShowDebugDialog(true);
              }}
              startIcon={Code}
            >
              Debug
            </Button>
          </div>
        ) : null}
        <Results
          results={data?.results ?? []}
          isLoading={resultsAreLoading}
          isPreviousData={isPreviousData}
          showDebugDialog={(result) => {
            setDebugCode(result);
            setShowDebugDialog(true);
          }}
        />
        {data?.count && data.count > DEFAULT_SEARCH_LIMIT ? (
          <Pagination
            disabled={isFetching}
            page={page}
            count={Math.ceil(data.count / DEFAULT_SEARCH_LIMIT)}
            handlePageChange={(_, page) => handlePageChange(page)}
          />
        ) : null}
      </main>
      {enableSearchDebugging && showDebugDialog && (
        <DebugDialog
          open={showDebugDialog}
          dialogTitle="Search Response"
          onClose={() => setShowDebugDialog(false)}
          code={debugCode}
        />
      )}
    </div>
  );
};

export default SearchInterface;
export { testId };
