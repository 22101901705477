// @ts-nocheck
import MuiAutocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';

import Box from '@/componentsv2/Box';
import Input from '@/componentsv2/Input';
import { useAppDispatch } from '@/store';

import styles, { autocompleteClasses } from './styles';
import { IAutocompleteProps, IFetchPromise } from './types';

const useStyles = makeStyles(styles);

const testId = 'of-autocomplete';

const Autocomplete = ({
  className,
  fetch,
  filterOptions,
  filters,
  getOptionLabel,
  isLoading,
  isOptionEqualToValue,
  name,
  onChange,
  onInputChange,
  onBlur,
  options = [],
  queryField = 'query',
  renderOption,
  TextFieldProps,
  disableEmptyQuery,
  ...rest
}: IAutocompleteProps) => {
  const classes = useStyles();
  const classProps = clsx(className, classes.autocomplete, autocompleteClasses.root);
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const [queriedValue, setQueriedValue] = useState('');
  const fetchPromise = useRef<IFetchPromise | null>();

  const fetchAndUpdate = useMemo(() => {
    return async (query: string) => {
      if (!fetch) {
        return;
      }

      fetchPromise.current = dispatch(
        fetch({
          [queryField]: query,
          cancelPrevious: true,
          ...filters,
        })
      );

      await fetchPromise.current;

      setQueriedValue(query);
    };
  }, [dispatch, fetch, filters, queryField]);

  useEffect(() => {
    if (disableEmptyQuery && !inputValue) {
      return;
    }

    fetchAndUpdate(inputValue);
  }, [fetchAndUpdate, disableEmptyQuery, inputValue]);

  return (
    <Box className={classProps} data-testid={testId}>
      <MuiAutocomplete
        options={options}
        getOptionLabel={getOptionLabel ? getOptionLabel : (option) => option.name ?? ''}
        isOptionEqualToValue={
          isOptionEqualToValue ? isOptionEqualToValue : (option, value) => option.id === value.id
        }
        size="small"
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          onInputChange?.(event, newInputValue);
        }}
        onChange={(_, newValue) => {
          onChange?.(newValue);
        }}
        onBlur={(e) => {
          fetchPromise.current?.abort();
          onBlur?.(e);
        }}
        renderInput={(params) => (
          <Input
            name={name}
            {...params}
            {...TextFieldProps}
            inputProps={{
              ...params.inputProps,
              ...(TextFieldProps?.inputProps ?? {}),
            }}
            InputProps={{
              ...params.InputProps,
              ...(TextFieldProps?.InputProps ?? {}),
              endAdornment: isLoading ? (
                <CircularProgress size={20} />
              ) : (
                params.InputProps?.endAdornment
              ),
            }}
          />
        )}
        filterOptions={
          fetch
            ? (x) => x
            : filterOptions
              ? filterOptions
              : (options) =>
                  options.filter((option) =>
                    option.name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
                  )
        }
        renderOption={
          renderOption
            ? renderOption
            : (props, option) => (
                <li
                  {...props}
                  key={option.id}
                  className={clsx(props.className, {
                    'opacity-40': isLoading && !inputValue.includes(queriedValue),
                  })}
                >
                  {option.name}
                </li>
              )
        }
        {...rest}
      />
    </Box>
  );
};

export { autocompleteClasses, testId as AutocompleteTestId };
export type { IAutocompleteProps };
export default Autocomplete;
