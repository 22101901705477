import { VariantType, enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router';

import Header from '@/componentsv2/HarnessHeader';
import { ApiError } from '@/openapi';
import APP_ROUTES from '@/routes/APP_ROUTES';
import SearchDrawers from '@/searchv2/components/SearchDrawers';
import SearchInterface from '@/searchv2/components/SearchInterface';
import CollaboratorsDialog from '@/searchv2/components/saved-search/CollaboratorsDialog';
import CreateOrEditSavedSearchDialog from '@/searchv2/components/saved-search/CreateOrEditSavedSearchDialog';
import SavedSearchBar from '@/searchv2/components/saved-search/SavedSearchBar';
import SavedSearchesDrawer from '@/searchv2/components/saved-search/SavedSearchesDrawer';
import ShortlistDrawer from '@/searchv2/components/saved-search/ShortlistDrawer';
import { useSavedSearchQuery } from '@/searchv2/queries/savedSearch';

const testId = 'of-page-search';

interface LocationState {
  snackbar: { message: string; variant: VariantType };
}

const Search = (): JSX.Element => {
  const { savedSearchId } = useParams();
  const location = useLocation();
  const { snackbar } = (location.state as LocationState) || {
    searchContext: undefined,
  };

  const {
    data: savedSearch,
    isLoading: isSavedSearchLoading,
    error,
    isError,
  } = useSavedSearchQuery(savedSearchId ?? '');

  useEffect(() => {
    if (snackbar) {
      const { message, variant } = snackbar;
      enqueueSnackbar(message, { variant });
    }
  }, [snackbar]);

  if (isError) {
    const message =
      (error as ApiError).status === 403
        ? 'You do not have permission to view this saved search. Contact the owner to be added as a collaborator.'
        : 'Requested saved search could not be found';
    return (
      <Navigate
        to={APP_ROUTES.search}
        replace
        state={{ snackbar: { message, variant: 'error' } }}
      />
    );
  }

  return (
    <div className="flex min-h-screen flex-col" data-testid={testId}>
      <div>
        <Header />
      </div>
      <SavedSearchBar
        isLoading={!!savedSearchId && isSavedSearchLoading}
        savedSearch={savedSearch}
      />
      {(savedSearchId && savedSearch?.search_hash) || !savedSearchId ? (
        <SearchInterface key={savedSearch?.id ?? 0} initialHash={savedSearch?.search_hash} />
      ) : null}
      <CreateOrEditSavedSearchDialog />
      <SearchDrawers />
      <SavedSearchesDrawer />
      <ShortlistDrawer />
      <CollaboratorsDialog />
    </div>
  );
};

export default Search;
