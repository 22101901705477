import { CSSProperties, FormEvent, ReactNode, useCallback } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { notify } from '@/actions/ui';
import Button, { ButtonProps } from '@/components/Button/Button';
import Form from '@/components/Form/Form';

const SaveButton = (props: Omit<ButtonProps, 'children'>) => {
  return (
    <Button type="submit" size="small" {...props}>
      Save
    </Button>
  );
};

const connector = connect(undefined, {
  notify,
});

interface SaveFormProps {
  saveMessage?: string;
  saveLabel?: string;
  onSubmit: (values: FormEvent<HTMLFormElement>) => Promise<any>;
  onReset?: () => void;
  style?: CSSProperties;
  className?: string;
  saveButtonStyle?: CSSProperties;
  children: ReactNode;
}

const SaveForm = ({
  children,
  saveLabel = 'Save',
  saveButtonStyle,
  onReset,
  style,
  className,
  onSubmit,
  saveMessage = 'Settings updated.',
  notify,
}: SaveFormProps & ConnectedProps<typeof connector>) => {
  const handleSubmit = useCallback(
    (values: FormEvent<HTMLFormElement>) => {
      Promise.resolve(onSubmit(values)).then((errors) => {
        if (errors) return;
        notify(saveMessage);
      });
    },
    [notify, onSubmit, saveMessage]
  );

  return (
    <Form {...{ onReset, style, className }} onSubmit={handleSubmit}>
      {children}
      <SaveButton style={{ marginTop: 40, ...saveButtonStyle }} label={saveLabel} />
    </Form>
  );
};

export default connector(SaveForm);
