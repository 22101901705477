import { circularProgressClasses } from '@mui/material/CircularProgress';

const ns = 'autocomplete';

export const autocompleteClasses = {
  root: `${ns}__root`,
};

const styles = {
  autocomplete: {
    [`& .${circularProgressClasses.root}`]: {
      marginTop: -16,
    },
  },
};

export default styles;
