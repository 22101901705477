import { useApolloClient } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import arrayMutators from 'final-form-arrays';
import { useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { ConnectedProps, connect } from 'react-redux';

import { updateGroupDomain } from '@/actions/domain';
import { updateNetwork } from '@/actions/internalNetwork';
import { notify } from '@/actions/ui';
import Button from '@/components/Button';
import { Checkbox, TextField } from '@/components/FormAdapters/FormAdapters';
import FAIcon from '@/components/Icon/FAIcon';
import config from '@/config';
import { presignAttachmentURL } from '@/core/attachment';
import { RootState } from '@/store';
import { red500 } from '@/theme/colors';
import { interceptEnter } from '@/utils';
import { isValidURL } from '@/utils/reducer';

import Logo from './Logo';

const DUP_POLICY_CODE = 'GraphQL Error: Agreements duplicate entry: ';

const useStyles = makeStyles(() => ({
  textField: {
    display: 'block',
    maxWidth: 350,
  },
}));

interface CustomFieldProps {
  type?: string;
  multiline?: boolean;
  inputProps?: any;
  [key: string]: any;
}

const CustomField = ({
  type = 'text',
  multiline,
  inputProps,
  ...rest
}: CustomFieldProps): JSX.Element => {
  const maxLength = multiline ? 2048 : type === 'text' ? 255 : undefined;

  return (
    // @ts-expect-error missing name
    <Field
      {...rest}
      type={type}
      multiline={multiline}
      onKeyPress={interceptEnter}
      parse={(v) => v}
      inputProps={{ maxLength, ...inputProps }}
    />
  );
};

interface FormData {
  logo_url: string;
  external_logo_url: string;
  signup_enabled: boolean;
  subdomain: string;
  root_subdomain_redirect: string;
  member_signup_about: string;
  member_signup_about_mobile: string;
  expert_signup_about: string;
  expert_signup_about_mobile: string;
  network_join_title: string;
  signup_prompt_hourly_rate: boolean;
  signup_prompt_marketplace: boolean;
  signup_prompt_profile_publicity: boolean;
  agreements: any[];
  expert_unpaid: boolean;
}

export interface SubdomainProps {
  groupId: string;
  isViewerAdmin?: boolean;
}

const connector = connect(
  (state: RootState, { groupId }: SubdomainProps) => {
    const group = state.groups.default.edges.find((e: any) => e.node.id === groupId).node;
    return {
      internalNetwork: group.internal_network || {},
      domain: group.domain || {},
    };
  },
  {
    updateGroupDomain,
    updateNetwork,
    notify,
  }
);

const webUrl = config.webUrl.split('/').pop();

const required = (value: any) => (value ? undefined : 'Required');

const validUrl = (url: any) => (url && !isValidURL(url) ? 'Must be a valid URL' : undefined);

const validateAgreements = (agreements: any[] = [], duplicateCode: any) => {
  const errorsArray: any = [];

  const codes = agreements.map((a) => a.policy_code);

  agreements.forEach((a) => {
    const errors = {
      policy_code: required(a.policy_code),
      policy_label: required(a.policy_label),
      policy_url: required(a.policy_url),
    };

    const code = a.policy_code;

    if (
      (code !== undefined && code === duplicateCode) || // code already in use
      codes.indexOf(code) !== codes.lastIndexOf(code) // code dupl in same form
    ) {
      errors.policy_code = `Code is duplicated`;
    }

    errorsArray.push(errors);
  });

  return errorsArray;
};

const Subdomain = ({
  groupId,
  isViewerAdmin = false,
  internalNetwork,
  domain,
  updateGroupDomain,
  updateNetwork,
  notify,
}: SubdomainProps & ConnectedProps<typeof connector>): JSX.Element => {
  const s = useStyles();

  const graphql = useApolloClient();

  const initialValues: Partial<FormData> = useMemo(() => {
    return {
      external_logo_url: domain.logo_url,
      signup_enabled: domain.signup_enabled,
      subdomain: domain.subdomain,
      root_subdomain_redirect: domain.root_subdomain_redirect,
      member_signup_about: domain.member_signup_about,
      member_signup_about_mobile: domain.member_signup_about_mobile,
      expert_signup_about: domain.expert_signup_about,
      expert_signup_about_mobile: domain.expert_signup_about_mobile,
      network_join_title: domain.network_join_title,
      signup_prompt_hourly_rate: domain.signup_prompt_hourly_rate,
      signup_prompt_marketplace: domain.signup_prompt_marketplace,
      signup_prompt_profile_publicity: domain.signup_prompt_profile_publicity,
      agreements: domain.agreements,

      expert_unpaid: internalNetwork.expert_unpaid,
    };
  }, [domain, internalNetwork]);

  const handleSubmit = async (values: FormData) => {
    try {
      const { expert_unpaid: expertUnpaid, external_logo_url: esIgnored, ...domainValues } = values;
      await Promise.all([
        updateGroupDomain(
          {
            group_id: groupId,
            ...domainValues,
          },
          groupId
        ),
        updateNetwork(
          {
            group_id: groupId,
            expert_unpaid: expertUnpaid,
          },
          groupId
        ),
      ]);
      notify('Team settings updated.', 'success');
    } catch (err: unknown) {
      if (!(err instanceof Error)) throw err;

      if (err.message.includes(DUP_POLICY_CODE)) {
        const errors = validateAgreements(
          values.agreements,
          err.message.replace(DUP_POLICY_CODE, '')
        );
        return { agreements: errors };
      }

      notify('Error when updating team settings.', 'error');
    }
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues} mutators={{ ...arrayMutators }}>
      {({ form, values, handleSubmit }) => {
        const { push } = form.mutators;
        return (
          <form onSubmit={(e) => handleSubmit(e)}>
            <CustomField
              type="checkbox"
              component={Checkbox}
              label="Signup page enabled"
              name="signup_enabled"
            />

            <Logo
              label="Logo"
              src={values.external_logo_url}
              onChange={async (file: any) => {
                const externalLogoUrl = await presignAttachmentURL(graphql, file.url);
                form.change('logo_url', file.url);
                form.change('external_logo_url', externalLogoUrl);
              }}
              alternateSvgUpload={isViewerAdmin}
              noStretch
            />

            <CustomField
              component={TextField}
              classes={{ root: s.textField }}
              label="Subdomain"
              name="subdomain"
              validate={required}
              inputProps={{ maxLength: 35 }}
            />

            {values.subdomain && (
              <CustomField
                component={TextField}
                classes={{ root: s.textField }}
                label={`Redirect ${values.subdomain}.${webUrl} to`}
                name="root_subdomain_redirect"
                validate={validUrl}
                style={{ minWidth: 500 }}
                inputProps={{ maxLength: 200 }}
              />
            )}

            <CustomField
              component={TextField}
              label="Member signup about text"
              name="member_signup_about"
              multiline
            />

            <CustomField
              component={TextField}
              label="Member signup about text (short)"
              name="member_signup_about_mobile"
              multiline
            />

            <CustomField
              component={TextField}
              label="Expert signup about text"
              name="expert_signup_about"
              multiline
            />

            <CustomField
              component={TextField}
              label="Expert signup about text (short)"
              name="expert_signup_about_mobile"
              multiline
            />

            <CustomField
              component={TextField}
              classes={{ root: s.textField }}
              label="Expert signup page title"
              name="network_join_title"
            />

            <CustomField
              type="checkbox"
              component={Checkbox}
              label="Prompt hourly rate"
              name="signup_prompt_hourly_rate"
            />

            <CustomField
              type="checkbox"
              component={Checkbox}
              label="Prompt marketplace preference"
              name="signup_prompt_marketplace"
            />

            <CustomField
              type="checkbox"
              component={Checkbox}
              label="Prompt profile publicity"
              name="signup_prompt_profile_publicity"
            />

            <CustomField
              type="checkbox"
              component={Checkbox}
              label="Automatically set experts entering the network to be unpaid by default"
              name="expert_unpaid"
            />

            <h4>
              Expert signup agreements
              <Fab
                type="button"
                size="small"
                color="secondary"
                onClick={() => push('agreements', { active: true })}
                style={{ marginLeft: 10 }}
              >
                <AddIcon />
              </Fab>
            </h4>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Active</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Label</TableCell>
                    <TableCell>URL</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FieldArray
                    name="agreements"
                    // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
                    validate={(values) => validateAgreements(values)}
                  >
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <TableRow key={name}>
                          <TableCell>
                            <CustomField
                              type="checkbox"
                              component={Checkbox}
                              name={`${name}.active`}
                              FormControlProps={{ fullWidth: false }}
                            />
                          </TableCell>
                          <TableCell>
                            <CustomField
                              component={TextField}
                              fullWidth={false}
                              name={`${name}.policy_code`}
                              inputProps={{ maxLength: 64 }}
                            />
                          </TableCell>
                          <TableCell>
                            <CustomField
                              component={TextField}
                              fullWidth={false}
                              name={`${name}.policy_label`}
                            />
                          </TableCell>
                          <TableCell>
                            <CustomField
                              component={TextField}
                              fullWidth={false}
                              inputProps={{ maxLength: 200 }}
                              name={`${name}.policy_url`}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="inherit"
                              size="small"
                              onClick={() => fields.remove(index)}
                            >
                              <FAIcon color={red500} icon="trash" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </FieldArray>
                </TableBody>
              </Table>
            </TableContainer>

            <Button type="submit" style={{ marginTop: 40 }} size="medium">
              Save
            </Button>
          </form>
        );
      }}
    </Form>
  );
};

export default connector(Subdomain);
