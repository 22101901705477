import { ApolloError } from '@apollo/client';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { ConnectedProps, connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { notify } from '@/actions/ui';
import Currency from '@/components/Currency/Currency';
import Form from '@/components/Form';
import { Checkbox } from '@/components/FormAdapters';
import SettingsSection from '@/components/SettingsSection';
import { updateProfile } from '@/profile/store';
import { RootState } from '@/store';
import { darkGray } from '@/theme/colors';

import RateAdvice from './RateAdvice';

const getStyles = makeStyles(() => ({
  dollar: {
    color: darkGray,
    marginTop: 15,
  },
  hourlyRateBox: {
    padding: '0 0 10px !important',
    border: '0 !important',
    maxWidth: 120,
    display: 'flex',
  },
  hourlyRate: {
    marginTop: 35,
  },
  advice: {
    marginTop: 35,
  },
}));

interface FormData {
  bill_rate: number;
  available_long_term: boolean;
  available_marketplace: boolean;
}

function validate(values: FormData) {
  const errors = {};

  const { bill_rate: billRate } = values;

  if (billRate === undefined) {
    // @ts-expect-error TS(2339): Property 'bill_rate' does not exist on type '{}'.
    errors.bill_rate = 'Required';
  }

  return errors;
}

interface BillRateFormProps {
  userId?: string;
  profileId: string;
  onSubmit?: (values: FormData) => void;
  onReset?: () => void;
  component?: any;
  showMarketplace?: boolean;
  [key: string]: any;
}

const connector = connect(
  (state: RootState, ownProps: BillRateFormProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId];
    return {
      profile,
      initialValues: {
        bill_rate: profile.bill_rate || undefined,
        available_long_term: profile.available_long_term || undefined,
        available_marketplace: profile.available_marketplace || undefined,
      },
    };
  },
  {
    updateProfile,
    notify,
  }
);

const BillRateForm = ({
  userId,
  profileId,
  updateProfile,
  notify,
  onSubmit,
  reset,
  onReset,
  handleSubmit: handleSubmitProp,
  component: Container = Form,
  showMarketplace = false,
  ...other
}: BillRateFormProps &
  ConnectedProps<typeof connector> &
  InjectedFormProps<FormData, BillRateFormProps>) => {
  const s = getStyles();

  const handleSubmit = async (values: any) => {
    try {
      await updateProfile({ id: profileId, ...values });
    } catch (err: unknown) {
      if (!(err instanceof ApolloError)) throw err;
      notify(err.message, 'error');
      return {};
    }

    if (onSubmit) {
      onSubmit(values);
    }
  };

  const handleReset = () => {
    reset();

    if (onReset) {
      onReset();
    }
  };

  return (
    <Container {...other} onSubmit={handleSubmitProp(handleSubmit)} onReset={handleReset}>
      <Grid container>
        <Grid item md={3} sm={12}>
          <SettingsSection
            box
            className={s.hourlyRate}
            boxClassName={s.hourlyRateBox}
            title="Hourly Rate"
          >
            <Field
              id="expertAvailabilityBillRate"
              component={Currency}
              name="bill_rate"
              variant="outlined"
            />
          </SettingsSection>
        </Grid>
        <Grid item md={9} sm={12} container alignItems="center">
          <RateAdvice className={s.advice} profileId={profileId} />
        </Grid>

        <Grid item md={12} sm={12}>
          <SettingsSection box title="Consultation Preferences">
            <Field
              type="checkbox"
              id="expertAvailabilityAvailableLongTerm"
              component={Checkbox}
              name="available_long_term"
              label="Available for long-term projects"
            />
          </SettingsSection>

          {showMarketplace && (
            <SettingsSection
              box
              title="OnFrontiers Marketplace"
              text="By opting in to join the OnFrontiers marketplace, our research managers will match you to participate in projects for our diverse set of global customers."
            >
              <Field
                type="checkbox"
                id="expertAvailabilityAvailableMarketplace"
                component={Checkbox}
                name="available_marketplace"
                label="Yes, add my profile to the OnFrontiers marketplace"
              />
            </SettingsSection>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default connector(
  reduxForm<FormData, BillRateFormProps>({
    form: 'expertAvailability',
    enableReinitialize: true,
    validate,
    // @ts-expect-error related to incompatible types
  })(BillRateForm)
);
