import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Skeleton, useMediaQuery } from '@mui/material';
import pluralize from 'pluralize';
import { useState } from 'react';
import { Filter } from 'react-feather';

import Button from '@/componentsv2/Button';
import { Drawer } from '@/componentsv2/Drawer';
import { SearchEnriched } from '@/openapi';
import { mq } from '@/theme/screens';

import CheckboxFilter, { CHECKBOX_FILTERS } from './CheckboxFilter';
import RangeFilter, { RANGE_FILTERS } from './RangeFilter';
import SearchInput, { SearchInputProps } from './SearchInput';

type FiltersProps = {
  data?: SearchEnriched;
  isFetching?: boolean;
  isLoading?: boolean;
  filterIds: string[];
  handleClear: () => void;
  handleSubmit: SearchInputProps['handleSubmit'];
  initialQuery: string;
  popstateKey: number;
  query: string;
  applyFilter: (filterId: string) => void;
  removeFilter: (filterId: string) => void;
};

const Filters = ({
  data,
  isFetching,
  isLoading,
  filterIds,
  handleClear,
  handleSubmit,
  initialQuery,
  popstateKey,
  query,
  applyFilter,
  removeFilter,
}: FiltersProps): JSX.Element => {
  const enableYearsOfExpFilter = useFeatureIsOn('years-of-experience-filter');
  const mobileSearchLayout = useMediaQuery(mq.mdd);
  const [mobileOpen, setMobileOpen] = useState(false);
  const areFiltersApplied = query || filterIds.length > 0;

  const commonJSX = (
    <>
      <div className="flex justify-between px-12 py-8">
        <div>
          <h2 className="hidden hd-6 md:block">Search Filters</h2>
          <p className="text-grey-500 subtitle-2">
            {isLoading ? (
              <Skeleton variant="text" width={50} sx={{ display: 'inline-block' }} />
            ) : (
              <span>{(data?.count ?? 0).toLocaleString()}</span>
            )}{' '}
            <span className="body-2">{pluralize('result', data?.count ?? 0)}</span>
          </p>
        </div>
        {areFiltersApplied ? (
          <button
            className="text-grey-600 underline decoration-grey-400 underline-offset-4 hover:decoration-grey-600"
            onClick={handleClear}
          >
            Clear Filters
          </button>
        ) : null}
      </div>
      <div className="-space-y-1">
        {CHECKBOX_FILTERS.map((filter) => (
          <CheckboxFilter
            isLoading={isLoading}
            isFetching={isFetching}
            key={`${filter.name}-${popstateKey}`}
            name={filter.name}
            filterKey={filter.filterKey}
            title={filter.title}
            options={filter.filterKey ? data?.filter_values?.[filter.filterKey] : undefined}
            searchInputQuery={query}
            applyFilter={applyFilter}
            removeFilter={removeFilter}
            allFilterIds={filterIds}
            skeletonCount={filter.skeletonCount}
          />
        ))}
        {enableYearsOfExpFilter
          ? RANGE_FILTERS.map((filter) => (
              <RangeFilter
                key={`${filter.minFilterKey}-${filter.maxFilterKey}-${popstateKey}`}
                title={filter.title}
                minFilterKey={filter.minFilterKey}
                maxFilterKey={filter.maxFilterKey}
                applyFilter={applyFilter}
                removeFilter={removeFilter}
                allFilterIds={filterIds}
                isFetching={isFetching}
              />
            ))
          : null}
      </div>
      <div className="px-5 pb-10 md:border-b md:border-grey-400">
        <SearchInput
          key={popstateKey}
          handleSubmit={handleSubmit}
          initialInputValue={initialQuery}
        />
      </div>
    </>
  );

  return mobileSearchLayout ? (
    <>
      <div className="p-16">
        <Button
          size="small"
          variant="tertiary"
          startIcon={Filter}
          onClick={() => setMobileOpen(true)}
        >
          Filters
        </Button>
      </div>
      <Drawer open={mobileOpen} size="xs" anchor="left">
        <Drawer.Header breadcrumbs={[{ title: 'Filters' }]} onClose={() => setMobileOpen(false)} />
        <Drawer.Main>
          <Drawer.Section>{commonJSX}</Drawer.Section>
        </Drawer.Main>
      </Drawer>
    </>
  ) : (
    <aside className="top-0 sticky h-screen w-[280px] shrink-0 overflow-auto bg-white">
      {commonJSX}
    </aside>
  );
};

export default Filters;
