import React from 'react';

import Footer from '../Footer';
import SnackbarNotification from '../Snackbar/SnackbarNotification';

function Layout({
  children,
  dispatch,
  footerProps,
  hideMessage,
  showFooter = true,
  ...other
}: any) {
  return (
    <div {...other}>
      {children}

      <SnackbarNotification />

      {showFooter && <Footer {...footerProps} />}
    </div>
  );
}

export default Layout;
