import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { ConnectedProps, connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router';

import { AccountType } from '@/__generated__/graphql';
import { setUserContext } from '@/actions/ui';
import { QUEUE_IDS } from '@/core/apiWebSocket';
import { UserContext } from '@/core/user';
import { useApp } from '@/hooks/useAppContext';
import { updateUnreadCount } from '@/messaging/store';
import { fetchConflictsCount } from '@/profile/store';
import { RootState } from '@/store';

import Button from '../Button';
import Header, { NavBarItem } from '../Header';
import SelectGroup from '../SelectGroup';
import { BadgeCount } from './UserMenu';
import { navItemsBuilder } from './navItemsBuilder';

interface UserContextOptionProps {
  option: {
    name: string;
    description?: string;
  };
}

const UserContextOption = ({ option }: UserContextOptionProps) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {option.name}
      {option.description && (
        <span
          style={{
            marginLeft: 5,
          }}
        >
          ({option.description})
        </span>
      )}
    </div>
  );
};

const connector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
    userContext: state.ui.userContext,
    userContextOptions: state.ui.userContextOptions,
    unreadCount: state.messaging.unreadCount,
    conflictsCount: state.profiles.counts.conflicts,
  }),
  {
    updateUnreadCount,
    setUserContext,
    fetchConflictsCount,
  }
);

interface AccessHeaderProps {
  SearchComponent?: React.ComponentType;
  shouldShowNewRequest: boolean;
}

const AccessHeader = ({
  viewer,
  unreadCount,
  userContext,
  userContextOptions,
  conflictsCount,
  SearchComponent,
  setUserContext,
  fetchConflictsCount,
  shouldShowNewRequest,
}: AccessHeaderProps & ConnectedProps<typeof connector>): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const unsubscribeRef = useRef<(() => void) | null>(null);
  const { apiWebSocket } = useApp();

  const [navItems, setNavItems] = useState<NavBarItem[]>([]);

  const hasGroups = viewer.groups && viewer.groups?.length > 0;
  const isAccountEnterprise = viewer.groups?.find((g) => g.account_type === AccountType.Enterprise);

  const handleChangeContext = useCallback(
    (userContext: string) => {
      setUserContext(userContext as UserContext);
      if (userContext === 'expert' && location.pathname === '/expert_requests') {
        navigate('/dashboard');
      } else {
        navigate(location);
      }
    },
    [location, navigate, setUserContext]
  );

  const handleGroupContext = useCallback(
    (value: string) => {
      if (!value) return;
      handleChangeContext(value);
    },
    [handleChangeContext]
  );

  const userMenuItems: NavBarItem[] = [];

  // build context section for user menu
  userMenuItems.push(
    ...userContextOptions.map((option, index, items) => ({
      key: `${option.value}-context`,
      text: <UserContextOption option={option} />,
      divider: !viewer.admin && index === items.length - 1,

      onClick: () => {
        handleChangeContext(option.value);
      },
    }))
  );

  if (viewer.admin) {
    userMenuItems.push({
      key: 'context-selector',
      text: (
        <SelectGroup
          autoComplete
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onChange={handleGroupContext}
          className="m-0 p-0 w-full"
          margin="none"
        />
      ),
      handleKeyDown: (e) => {
        e.stopPropagation();
      },
      divider: true,
    });
  }

  // build normal menu items
  userMenuItems.push(
    ...[
      {
        key: 'dashboard',
        text: 'Dashboard',
        onClick: () => {
          navigate('/dashboard');
        },
      },
      {
        key: 'profile',
        text: 'My Profile',
        onClick: () => {
          navigate(`/profile/${viewer.username}`);
        },
      },
      {
        key: 'teams',
        text: 'Teams',
        to: '/teams',
      },
      {
        key: 'settings',
        text: 'Settings',
        onClick: () => {
          navigate(`/settings`);
        },
        divider: true,
      },
    ]
  );

  // build admin menu items
  if (viewer.admin) {
    userMenuItems.push(
      ...[
        {
          key: 'applications',
          text: 'View Applications',
          to: '/search?expert_states[]=applied&expert_states[]=applying&sort=created.at&asc=false',
        },
        {
          key: 'profile-uploader',
          text: 'Profile Uploader',
          onClick: () => {
            navigate(`/profile-uploader`);
          },
        },
        {
          key: 'profile-conflicts',
          text: (
            <div className="flex items-center">
              Profile Conflicts <BadgeCount count={conflictsCount} />
            </div>
          ),
          onClick: () => {
            navigate(`/profile-conflicts`);
          },
          divider: true,
        },
      ]
    );
  }

  if (shouldShowNewRequest) {
    userMenuItems.push({
      key: 'expert-requests',
      text: <span className="text-brand-tertiary">Find Experts</span>,
      onClick: () => {
        navigate('/request_expert');
      },
    });
  }

  const handleNewUnreadCount = useCallback((payload: Record<string, any>) => {
    updateUnreadCount(payload.count);
  }, []);

  useEffect(() => {
    if (!viewer.id) {
      setNavItems([
        {
          key: 'login',
          text: 'Login',
          to: '/login',
        },
        {
          key: 'signup',
          text: 'Sign Up',
          to: '/signup?next=%2Fdashboard',
        },
      ]);
      return;
    }

    const navItems = navItemsBuilder({
      profileUrl: viewer.html_url ? new URL(viewer.html_url).pathname : '/',
      unreadMessages: unreadCount > 0 ? unreadCount : null,
      userContext: userContext,
      isAdmin: viewer.admin,
      hasGroups,
      isAccountEnterprise,
      conflictsCount,
      shouldShowNewRequest,
      userContextOptions,
    });
    const switchAccountItem = navItems.find((item) => item.key === 'switch-context');
    if (switchAccountItem) {
      switchAccountItem.children = [
        ...userContextOptions.map((option) => ({
          key: `${option.value}-context`,
          text: (
            <span className="flex items-center text-brand-secondary">
              {option.value === userContext && (
                <ChevronRight size={16} className="-ml-[20px] mr-[4px]" />
              )}
              <UserContextOption option={option} />
            </span>
          ),

          onClick: () => {
            handleChangeContext(option.value);
          },
        })),
        {
          key: 'context-selector',
          text: (
            <SelectGroup
              autoComplete
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              onChange={handleGroupContext}
              className="m-0 p-0 w-full"
              margin="none"
            />
          ),
          handleKeyDown: (e) => {
            e.stopPropagation();
          },
        },
      ];
    }
    setNavItems(
      navItems.map((item) => ({
        ...item,
        onClick: () => item.to && navigate(item.to),
      }))
    );
  }, [
    viewer,
    userContext,
    unreadCount,
    conflictsCount,
    hasGroups,
    isAccountEnterprise,
    shouldShowNewRequest,
    navigate,
    userContextOptions,
    handleChangeContext,
    handleGroupContext,
  ]);

  useEffect(() => {
    if (!viewer.id) return;

    unsubscribeRef.current = apiWebSocket.on(
      QUEUE_IDS.CHAT_UNREAD_MESSAGES,
      viewer.id,
      handleNewUnreadCount
    );

    return () => {
      if (unsubscribeRef.current) unsubscribeRef.current();
    };
  }, [apiWebSocket, handleNewUnreadCount, viewer.id]);

  useEffect(() => {
    if (!viewer.id) return;
    if (viewer.admin) fetchConflictsCount();
  }, [fetchConflictsCount, viewer]);

  const displayName = useMemo(() => {
    const context = userContextOptions.find((o) => o.value === userContext);

    if (!context) return `${viewer.first_name} ${viewer.last_name}`;

    return context.name + (context.description ? ` (${context.description})` : '');
  }, [viewer, userContext, userContextOptions]);

  return (
    <Header
      userData={{
        displayName,
        picture_url: viewer.picture_url as string,
        username: viewer.username || undefined,
      }}
      isAuthenticated={!!viewer.id}
      SearchComponent={SearchComponent}
      findExperts={
        shouldShowNewRequest ? (
          <Link to="/request_expert" className="hidden xl:block">
            <Button size="small" label="Find Experts" style={{ minWidth: 115 }} />
          </Link>
        ) : null
      }
      navbarItems={navItems}
      userMenuItems={userMenuItems}
      logout={() => navigate('/logout')}
    />
  );
};

export default connector(AccessHeader);
