import { redirect } from 'react-router';

import { fetchGroup } from '@/actions/group';
import { fetchSiteSettings } from '@/actions/siteSettings';

import { LegacyRoute } from '../routesMiddleware';
import TeamAbout from './TeamAbout';

const route: LegacyRoute = {
  path: '/team/:slug/about',
  async action(context) {
    const { store, params } = context;
    const group = await store.dispatch(
      fetchGroup(params.slug, {
        aboutPage: true,
        publicData: true,
      })
    );

    if (!group || !group.about_page) {
      return redirect('/404');
    }

    await store.dispatch(fetchSiteSettings());

    document.title = `Join ${group.name}`;
    return <TeamAbout group={group} />;
  },
};

export default route;
