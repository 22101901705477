import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { ConnectedProps, connect } from 'react-redux';

import { notify } from '@/actions/ui';
import { Checkbox, TextField } from '@/components/FormAdapters';
import FAIcon from '@/components/Icon/FAIcon';
import LabelledOutline from '@/components/LabelledOutline';
import Link from '@/components/Link';
import config from '@/config';
import { openFileDialog, presignAttachmentURL } from '@/core/attachment';
import { useApp } from '@/hooks/useAppContext';
import AddButton from '@/profile/components/forms/buttons/AddButton';
import { RootState } from '@/store';
import { black, darkGreen } from '@/theme/colors';
import { required } from '@/utils';

const useStyles = makeStyles(() => ({
  attachment: {
    width: '100%',
    flexDirection: 'column',
    margin: '0 10px',
  },
  author: {
    marginTop: 15,
    textAlign: 'right',
  },
  attachmentInput: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  removeAction: {
    flex: '0 0 auto',
  },
  button: {
    fontSize: '20px',
  },
}));

const attachmentConnector = connect((state: RootState) => ({
  viewer: state.viewer,
}));

interface AttachmentProps {
  name: string;
  index: number;
  placeholder: string;
  disabled: boolean;
  value: {
    id: string;
    name: string;
    file_url: string;
    description: string;
    author: any;
    created_at: string;
  };
  isViewerExpert: boolean;
}

function AttachmentPure({
  viewer,
  name,
  index,
  placeholder,
  disabled,
  value,
  isViewerExpert,
}: AttachmentProps & ConnectedProps<typeof attachmentConnector>) {
  const s = useStyles();
  const { graphql } = useApp();

  const editDisabled = disabled || (value && value.id);
  const [externalURL, setExternalURL] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function getpresignAttachmentURL() {
      const url = await presignAttachmentURL(graphql.client, value.file_url);
      setExternalURL(url);
    }
    if (value.file_url) getpresignAttachmentURL();
  }, [graphql.client, value.file_url]);

  return (
    <div className={s.attachment}>
      <a href={externalURL} target="_blank" rel="noreferrer">{`${index + 1}. ${value.name}`}</a>
      <Field
        name={`${name}.description`}
        component={TextField}
        validate={required}
        required
        disabled={editDisabled}
        label="Description"
        placeholder={placeholder}
        multiline
        minRows={1}
        maxRows={3}
        fullWidth
        style={{ marginTop: 15 }}
        // Disable native required validation
        inputProps={{
          required: true,
          maxLength: 2048,
        }}
      />
      {!isViewerExpert && (
        <Field
          name={`${name}.hide_from_experts`}
          component={Checkbox}
          type="checkbox"
          disabled={editDisabled}
          label="Hide from experts (only show to Team Members and OnFrontiers staff)"
        />
      )}
      {value.author && (
        <div className={s.author}>
          Uploaded by{' '}
          <Link to={`${value.author.html_url}`} target="_blank">{`${value.author.name}`}</Link>
          &nbsp;on{' '}
          {`${moment(value.created_at)
            .tz(viewer?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format('YYYY-MM-DD • h:mma')}`}
          .
        </div>
      )}
    </div>
  );
}

const Attachment = attachmentConnector(AttachmentPure);

interface AttachmentInputProps {
  showRemove: boolean;
  name: string;
  index: number;
  onRemove: () => void;
  inputProps: any;
  inputComponent: any;
  className: string;
  removeClassName: string;
  value: any;
}

function AttachmentInput({
  showRemove,
  name,
  index,
  onRemove,
  inputProps,
  inputComponent,
  className,
  removeClassName,
  value,
}: AttachmentInputProps) {
  const s = useStyles();

  const InputComponent = inputComponent;

  return (
    <div className={cx(s.attachmentInput, className)}>
      <div className={cx(s.removeAction, removeClassName)}>
        {showRemove ? (
          <FAIcon
            icon="trash"
            className={s.button}
            onClick={onRemove}
            style={{
              color: black,
              cursor: 'pointer',
            }}
          />
        ) : (
          <FAIcon
            icon="check-circle"
            className={s.button}
            style={{
              color: darkGreen,
            }}
          />
        )}
      </div>
      <InputComponent name={name} index={index} value={value} {...inputProps} />
    </div>
  );
}

const attachmentsListConnector = connect(undefined, {
  notify,
});

interface AttachmentListProps extends AttachmentInputProps {
  fields: any;
  minLength: number;
  addButtonLabel: string;
  style: React.CSSProperties;
  itemClassName: string;
  disabled: boolean;
  notify: any;
  maxAttachmentFiles: number;
  maxAttachmentSize: number;
  onAddedFiles: () => void;
  addActionStyle: React.CSSProperties;
  showRemoveForField: (v: any) => boolean;
}

const AttachmentListPure = ({
  fields,
  inputProps,
  inputComponent,
  minLength = 0,
  addButtonLabel,
  style,
  className,
  itemClassName,
  removeClassName,
  disabled,
  notify,
  maxAttachmentFiles,
  maxAttachmentSize,
  onAddedFiles,
  addActionStyle,
  showRemoveForField = () => true,
}: AttachmentListProps & ConnectedProps<typeof attachmentsListConnector>) => {
  const { graphql } = useApp();
  const maxFiles = maxAttachmentFiles > fields.length ? maxAttachmentFiles - fields.length : 0;

  const handleAdd = async () => {
    openFileDialog(graphql.client, {
      accept: config.filestackEngagementAttachmentMimeTypes,
      fromSources: [
        'local_file_system',
        'url',
        'googledrive',
        'dropbox',
        'box',
        'onedrive',
        'onedriveforbusiness',
      ],
      // need to be explicitly false on transformation to remove transformations UI
      transformations: {
        crop: false,
        circle: false,
        rotate: false,
      },
      maxFiles,
      maxSize: maxAttachmentSize * 1024 * 1024,
      onFileSelected: (file) => {
        // If you throw any error in this function it will reject the file selection.
        // The error message will be displayed to the user as an alert.
        const hasDuplicate = fields
          .map(function (_: any, index: any) {
            const attachment = fields.value[index];
            return !attachment.id && attachment.name === file.filename;
          })
          .some((f: any) => f);
        if (hasDuplicate) {
          const msg = `File is already selected for submission: ${file.filename}`;
          notify(msg, 'error');
          throw new Error(msg);
        }
      },
    })
      .then(async (files) => {
        files.map(function (file) {
          fields.push({
            name: file.filename,
            file_url: file.url,
            description: undefined,
          });
        });
      })
      .then(onAddedFiles);
  };

  const showRemove = fields.length > minLength;

  return (
    <>
      <div style={style} className={className}>
        {fields.map((name: any, index: any) => (
          <LabelledOutline key={name}>
            <AttachmentInput
              inputComponent={inputComponent}
              name={name}
              index={index}
              inputProps={inputProps}
              onRemove={() => fields.remove(index)}
              showRemove={!disabled && showRemove && showRemoveForField(fields.value[index])}
              className={itemClassName}
              removeClassName={removeClassName}
              value={fields.value[index]}
            />
          </LabelledOutline>
        ))}
      </div>
      {!disabled && maxFiles > 0 && (
        <div style={addActionStyle}>
          <AddButton label={addButtonLabel} onClick={handleAdd} />
        </div>
      )}
    </>
  );
};
AttachmentListPure.displayName = 'AttachmentList';

const AttachmentList: React.ComponentType<AttachmentListProps> =
  attachmentsListConnector(AttachmentListPure);

interface AttachmentsProps {
  name: string;
  disabled: boolean;
  label: string;
  isMobileVersion?: boolean;
  isViewerExpert?: boolean;
  maxAttachmentFiles: number;
  maxAttachmentSize: number;
  onAddedFiles?: () => void;
  addActionStyle: React.CSSProperties;
  addButtonLabel: string;
  showRemoveForField?: (v: any) => boolean;
}

function Attachments(props: AttachmentsProps): JSX.Element {
  const {
    name,
    disabled,
    label,
    isMobileVersion,
    isViewerExpert,
    showRemoveForField = (v: any) => !v?.id,
    ...other
  } = props;
  return (
    <FieldArray
      component={(props) => <AttachmentList {...(props as unknown as AttachmentListProps)} />}
      disabled={disabled}
      name={name}
      defaultValue={[]}
      inputComponent={Attachment}
      inputProps={{
        label,
        placeholder: isMobileVersion ? '' : 'Add a file description',
        disabled,
        isViewerExpert,
      }}
      showRemoveForField={showRemoveForField}
      {...other}
    />
  );
}

export default Attachments;
