import MuiTextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { Field, FieldArray, useField } from 'formik';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { notify } from '@/actions/ui';
import FAIcon from '@/components/Icon/FAIcon';
import Link from '@/components/Link';
import config from '@/config';
import { openFileDialog, presignAttachmentURL } from '@/core/attachment';
import { Viewer } from '@/core/viewer';
import { useApp } from '@/hooks/useAppContext';
import AddButton from '@/profile/components/forms/buttons/AddButton';
import { RootState } from '@/store';
import { black, darkGreen } from '@/theme/colors';
import { required } from '@/utils';

import LabelledOutline from '../LabelledOutline/LabelledOutline';

const useStyles = makeStyles(() => ({
  attachment: {
    width: '100%',
    flexDirection: 'column',
    margin: '0 10px',
  },
  attachmentInput: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  removeAction: {
    flex: '0 0 auto',
  },
  button: {
    fontSize: '20px',
  },
}));

type Value = {
  id: string;
  name: string;
  file_url: string;
  description: string;
  author: {
    name: string;
    html_url: string;
  };
  created_at: string;
};

interface AttachmentsFormikProps {
  viewer: Viewer;
  name: string;
  index: number;
  placeholder: string;
  disabled: boolean;
  value: Value;
  isViewerExpert: boolean;
}

interface AttachmentInputProps extends AttachmentsFormikProps {
  showRemove: boolean;
  onRemove: () => void;
}

interface AttachmentsListProps {
  name: string;
  label: string;
  addButtonLabel: string;
  maxAttachmentFiles: number;
  maxAttachmentSize: number;
  minLength?: number;
  disabled: boolean;
  isMobileVersion?: boolean;
  isViewerExpert: boolean;
  onAddedFiles: () => void;
}

const AttachmentsFormik = ({
  viewer,
  name,
  index,
  placeholder,
  disabled,
  value,
  isViewerExpert,
}: AttachmentsFormikProps): JSX.Element => {
  const s = useStyles();
  const { graphql } = useApp();

  const editDisabled = disabled || (value && value.id);
  const [externalURL, setExternalURL] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function getpresignAttachmentURL() {
      const url = await presignAttachmentURL(graphql.client, value.file_url);
      setExternalURL(url);
    }
    if (value.file_url) getpresignAttachmentURL();
  }, [graphql.client, value.file_url]);

  return (
    <div className={s.attachment}>
      <div className="flex flex-col">
        <a href={externalURL} target="_blank" rel="noreferrer">{`${index + 1}. ${value.name}`}</a>
        <Field name={`${name}.${index}.description`}>
          {({ field }: { field: any }) => (
            <MuiTextField
              className="mt-10"
              required
              validate={required}
              label="Description"
              placeholder={placeholder}
              disabled={editDisabled}
              multiline
              minRows={1}
              maxRows={3}
              maxLength={2048}
              fullWidth
              {...field}
            />
          )}
        </Field>
        {!isViewerExpert && (
          <label htmlFor={`${name}.${index}.hide_from_experts`} className="ml-auto cursor-pointer">
            <Field
              type="checkbox"
              id={`${name}.${index}.hide_from_experts`}
              name={`${name}.${index}.hide_from_experts`}
              label="Hide from experts (only show to Team Members and OnFrontiers staff)"
            />
            <span className="ml-4">
              Hide from experts (only show to Team Members and OnFrontiers staff)
            </span>
          </label>
        )}
      </div>
      {value.author && (
        <div className="mt-10 text-right">
          Uploaded by{' '}
          <Link to={`${value.author.html_url}`} target="_blank">{`${value.author.name}`}</Link>
          &nbsp;on{' '}
          {`${moment(value.created_at)
            .tz(viewer?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format('YYYY-MM-DD • h:mma')}`}
          .
        </div>
      )}
    </div>
  );
};

const AttachmentInput = ({
  showRemove,
  onRemove,
  ...inputProps
}: AttachmentInputProps): JSX.Element => {
  const s = useStyles();

  return (
    <div className={cx(s.attachmentInput)}>
      <div className={cx(s.removeAction)}>
        {showRemove ? (
          <FAIcon
            icon="trash"
            className={s.button}
            onClick={onRemove}
            style={{
              color: black,
              cursor: 'pointer',
            }}
          />
        ) : (
          <FAIcon
            icon="check-circle"
            className={s.button}
            style={{
              color: darkGreen,
            }}
          />
        )}
      </div>
      <AttachmentsFormik {...inputProps} />
    </div>
  );
};

const attachmentsListConnector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
  }),
  {
    notify,
  }
);

const AttachmentsList = ({
  viewer,
  name,
  addButtonLabel,
  maxAttachmentFiles,
  maxAttachmentSize,
  minLength = 0,
  disabled,
  isViewerExpert,
  isMobileVersion,
  onAddedFiles,
}: AttachmentsListProps & ConnectedProps<typeof attachmentsListConnector>): JSX.Element => {
  const [, { value }] = useField(name);
  const { graphql } = useApp();
  const maxFiles = maxAttachmentFiles > value.length ? maxAttachmentFiles - value.length : 0;

  const handleAdd = async (push: <X>(obj: X) => void) => {
    const files = await openFileDialog(graphql.client, {
      accept: config.filestackEngagementAttachmentMimeTypes,
      fromSources: [
        'local_file_system',
        'url',
        'googledrive',
        'dropbox',
        'box',
        'onedrive',
        'onedriveforbusiness',
      ],
      // need to be explicitly false on transformation to remove transformations UI
      transformations: {
        crop: false,
        circle: false,
        rotate: false,
      },
      maxFiles,
      maxSize: maxAttachmentSize * 1024 * 1024,
      onFileSelected: (file) => {
        // If you throw any error in this function it will reject the file selection.
        // The error message will be displayed to the user as an alert.
        const hasDuplicate = value
          .map(function (attachment: any) {
            return !attachment.id && attachment.name === file.filename;
          })
          .some((f: any) => f);
        if (hasDuplicate) {
          const msg = `File is already selected for submission: ${file.filename}`;
          notify(msg, 'error');
          throw new Error(msg);
        }
      },
    });
    files.map(function (file) {
      push({
        name: file.filename,
        file_url: file.url,
        description: undefined,
      });
    });
    onAddedFiles();
  };

  const showRemove = value.length > minLength;
  const showRemoveForField = (v: any) => !v?.id;

  return (
    <FieldArray
      name={name}
      render={({ push, remove }) => (
        <>
          {value.map((file: { file_url: string }, index: number) => (
            <LabelledOutline key={file.file_url}>
              <AttachmentInput
                name={name}
                index={index}
                onRemove={() => remove(index)}
                showRemove={showRemove && showRemoveForField(value[index])}
                value={value[index]}
                viewer={viewer}
                placeholder={isMobileVersion ? '' : 'Add a file description'}
                disabled={disabled}
                isViewerExpert={isViewerExpert}
              />
            </LabelledOutline>
          ))}
          {!disabled && maxFiles > 0 && (
            <div className="mr-24 text-right">
              <AddButton type="button" label={addButtonLabel} onClick={() => handleAdd(push)} />
            </div>
          )}
        </>
      )}
    />
  );
};

export default attachmentsListConnector(AttachmentsList);
