import { AutocompleteOption, NullableAutocompleteOption } from './types';

export function convertToAutocompleteOptions<T extends NullableAutocompleteOption>(
  data?: T[]
): AutocompleteOption[] {
  return (
    data?.map((result: T) => ({
      id: 'id' in result ? (result.id ?? '') : '',
      name: 'name' in result ? (result.name ?? '') : '',
    })) ?? []
  );
}
