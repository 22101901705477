import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { UAParser } from 'ua-parser-js';
import { CLIs, Crawlers, Emails, Fetchers } from 'ua-parser-js/extensions';

import { gengql } from '@/__generated__';
import { DocumentNodeResult } from '@/utils/gql';

// @ts-ignore - This is a hack to make the code compile
const uaParser = new UAParser([CLIs, Crawlers, Emails, Fetchers]);

interface BillingAccount {
  id: string;
}

export interface Group {
  id: string;
  name: string;
  billing_account: BillingAccount;
  account_type: 'basic' | 'advanced' | 'enterprise';
  default_anonymous_messaging: boolean;
}

const GET_VIEWER = gengql(/* GraphQL */ `
  query getViewer($userId: String) {
    user(id: $userId) {
      id
      billing_account_id
      admin
      expert_state
      compliance_completed_at
      signup_type
      has_password
      locked
      signup_subdomain
      can_autofill_profile
      password_expiry {
        expiry
        expired
        expiring
      }
      agreements {
        policy
        updated_at
        accepted
      }
      groups {
        id
        name
        slug
        html_url
        branding_logo_url
        branding_show_poweredbyof
        billing_account {
          id
          type
          state
          credit_balance {
            cents
            currency
          }
        }
        about
        account_type
        internal
        internal_network {
          id
          name
        }
        default_anonymous_messaging
      }
      profile {
        id
        first_name
        last_name
        name
        html_url
        url_endpoint
        linkedin_username
        linkedin_url
        title
        summary
        skype
        timezone
        city
        country
        picture_url
        questions
        cv_url
        available_long_term
        available_marketplace
        hide_profile
        bill_rate
        credit_rate
        expert_state
        languages {
          code
          fluency
        }
        keywords
        tier
      }
      # deprecated fields
      first_name
      last_name
      name
      picture_url
      country_code
      timezone
      username
      phone
      html_url
      email {
        address
        accepted
        confirmed
      }
    }
  }
`);

export type Viewer = NonNullable<DocumentNodeResult<typeof GET_VIEWER>['user']> & {
  full_name?: string;
  intercomHash?: string;
  userAgentParsed: ReturnType<typeof uaParser.getResult>;
};

export async function fetchViewer(client: ApolloClient<NormalizedCacheObject>): Promise<Viewer> {
  const { data } = await client.query({ query: GET_VIEWER });
  const viewer = { ...data.user } as Viewer;
  viewer.full_name = viewer.profile?.name || undefined;
  viewer.userAgentParsed = uaParser.getResult();
  return viewer;
}

export const loggedOutViewer: Viewer = {
  id: '',
  html_url: '',
  admin: false,
  groups: [],
  agreements: [],
  userAgentParsed: uaParser.getResult(),
};

// Harness viewer
export type CurrentUserType = {
  OF_ID: string | null;
  EMAIL: string | null;
  NAME: string | null;
  BROWSER: string | null;
  LOCATION: string | null;
};

export const CurrentUser: CurrentUserType = {
  OF_ID: null,
  EMAIL: null,
  NAME: null,
  BROWSER: null,
  LOCATION: null,
};
