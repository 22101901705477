import { Helmet } from 'react-helmet';

import {
  CurrentRestriction,
  ExpertHistory,
  ExpertState,
  MarketplacePreference,
  ProfileType,
} from '@/__generated__/graphql';
import { fetchCountries } from '@/actions/country';
import { fetchAllGroups } from '@/actions/group';
import { track } from '@/actions/tracking';
import ConsultationsStarting from '@/components/ConsultationsStarting';
import LayoutPage from '@/components/Layout/LayoutPage';
import { isEnterpriseUser } from '@/core/group';
import { LegacyRoute } from '@/routes/routesMiddleware';
import { parseExperienceFilter } from '@/search';
import { Query } from '@/search';
import Experts from '@/search/components/Experts';
import { search, setSearchResults } from '@/search/store';
import { fetchUser } from '@/store/user';
import { parseEnum } from '@/utils/types';

import UpsellPromo from '../components/UpsellPromo';

function toBool(value: any) {
  if (value === undefined || value === null) return value;
  return value === '1' || value === 1 || value.toString().toLowerCase() === 'true';
}

const route: LegacyRoute = {
  path: '/search',

  async action({ store, query: queryRaw }) {
    const { viewer } = store.getState();

    if (!isEnterpriseUser(viewer)) {
      store.dispatch(track('promo.show.search'));
      document.title = 'Upgrade to Enterprise';
      return (
        <LayoutPage showNav verticalCenter>
          <UpsellPromo />
        </LayoutPage>
      );
    }

    const totalFiltersApplied = Object.keys(queryRaw).filter(
      (k) => !['q', 'query', 'ascending'].includes(k)
    ).length;

    const query = new Query({
      text: queryRaw.get('query') || queryRaw.get('q') || undefined,
      sort: queryRaw.get('sort') || undefined,
      ascending: toBool(queryRaw.get('asc')),
      mustHaveEmail: toBool(queryRaw.get('email')),
      profileType: parseEnum(ProfileType, queryRaw.get('profile_type')),
      expertStates: queryRaw
        .getAll('expert_states')
        .map((s) => parseEnum(ExpertState, s))
        .filter((s) => !!s),
      expertHistory: queryRaw
        .getAll('expert_history')
        .map((s) => parseEnum(ExpertHistory, s))
        .filter((s) => !!s),
      languages: queryRaw.getAll('languages'),
      countries: queryRaw.getAll('countries'),
      industries: queryRaw.getAll('industries'),
      agentIds: queryRaw.getAll('agents'),
      networkIds: queryRaw.getAll('networks'),
      organizationsCurrentRestriction: parseEnum(
        CurrentRestriction,
        queryRaw.get('organizations_restrict')
      ),
      locationsCurrentRestriction: parseEnum(
        CurrentRestriction,
        queryRaw.get('locations_restrict')
      ),
      jobTitlesCurrentRestriction: parseEnum(
        CurrentRestriction,
        queryRaw.get('job_titles_restrict')
      ),
      organizations: queryRaw
        .getAll('organizations')
        .map(parseExperienceFilter)
        .filter((x) => !!x),
      locations: queryRaw
        .getAll('locations')
        .map(parseExperienceFilter)
        .filter((x) => !!x),
      jobTitles: queryRaw
        .getAll('job_titles')
        .map(parseExperienceFilter)
        .filter((x) => !!x),
      consultationsWith: queryRaw.getAll('consultations_with'),
      educationDegrees: queryRaw.getAll('education_degrees'),
      educationFos: queryRaw.getAll('education_fos'),
      groupKeywordIds: queryRaw.getAll('group_keyword_ids'),
      keywords: queryRaw.getAll('keywords'),
      marketplacePreference: parseEnum(
        MarketplacePreference,
        queryRaw.get('marketplace_preference')
      ),
    });

    try {
      store.dispatch(
        setSearchResults({
          query,
          fetching: true,
          reset: true,
        })
      );
    } catch (err: any) {
      if (!err.query) throw err;
    }

    [
      search(query),
      fetchCountries(),
      fetchAllGroups({ collection: 'networks', internalNetwork: true }),
      fetchUser(viewer.username, {
        groups: true,
        profileKeywordsDefinition: true,
      }),
    ].forEach(store.dispatch);

    return (
      <LayoutPage showNav selected="my-network">
        <Helmet>
          <title>My Network</title>
        </Helmet>
        <ConsultationsStarting />
        <Experts totalFiltersApplied={totalFiltersApplied} />
      </LayoutPage>
    );
  },
};

export default route;
