import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { ConnectedProps, connect } from 'react-redux';

import { RootState } from '@/store';

const getStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    marginLeft: 10,
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
}));

interface RateAdviceProps {
  profileId: string;
  className?: string;
}

const connector = connect((state: RootState, ownProps: RateAdviceProps) => {
  const profile = state.profiles.fullProfiles[ownProps.profileId];
  return {
    profile,
  };
});

const RateAdvice = ({
  className,
}: RateAdviceProps & ConnectedProps<typeof connector>): JSX.Element => {
  const s = getStyles();

  return (
    <div className={cx(s.root, className)}>
      <p>Rate changes will apply only to future opportunities.</p>
    </div>
  );
};

export default connector(RateAdvice);
