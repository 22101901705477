import { useQuery } from '@apollo/client';
import Divider from '@mui/material/Divider';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { Field as FinalField } from 'react-final-form';
import { ConnectedProps, connect } from 'react-redux';

import { gengql } from '@/__generated__';
import { ExpertRequestType } from '@/__generated__/graphql';
import { fetchSectors } from '@/actions/sector';
import Attachments from '@/components/Attachments/Attachments';
import DateTimePicker from '@/components/DateTimePicker/DateTimePicker';
import DurationField from '@/components/DurationField';
import { Select, TextField } from '@/components/FormAdapters/FormAdapters';
import KeywordInput from '@/components/KeywordInput/KeywordInput';
import LabelledOutline from '@/components/LabelledOutline';
import Link from '@/components/Link';
import SelectLocation from '@/components/SelectLocation';
import SelectSector from '@/components/SelectSector';
import config from '@/config';
import { getTimezoneDescription } from '@/core/time';
import { ER_TYPE_LABELS, isSetExpectedDurationType, isWorkType } from '@/expertrequest/store';
import { RootState } from '@/store';
import { required } from '@/utils';

import Companies from './Companies';
import s from './DetailsNew.module.scss';
import Disclosure from './Disclosure';
import ExpertQueries from './ExpertQueries';
import FocusAreas from './FocusAreas';
import Project from './Project';

const erTypeOptions = Object.values(ExpertRequestType).map((v) => ({
  value: v,
  label: ER_TYPE_LABELS[v],
}));

const GET_COUNTRIES = gengql(/* GraphQL */ `
  query getERDetailsNewCountries {
    countries {
      id
      name
    }
  }
`);

type FormSectionProps = {
  title: string;
  subtitle: string;
  showDivider?: boolean;
  children?: React.ReactNode;
};

const FormSection: FC<FormSectionProps> = memo(({ title, subtitle, showDivider, children }) => {
  return (
    <div className={s.section}>
      {showDivider && <Divider />}
      <div>
        <p className={s.sectionTitle}>{title}</p>
        <p className={s.sectionSubtitle}>{subtitle}</p>
        <div>{children}</div>
      </div>
    </div>
  );
});
FormSection.displayName = 'FormSection';

type FormSubSectionProps = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
};

const FormSubSection: FC<FormSubSectionProps> = memo(({ title, subtitle, children }) => (
  <div className={s.subsection}>
    <div>
      <p className={s.subsectionTitle}>{title}</p>
      <p className={s.subsectionSubtitle}>{subtitle}</p>
    </div>
    <div>{children}</div>
  </div>
));
FormSubSection.displayName = 'FormSubSection';

type FieldIfProps = {
  name: string;
  showFields: string[];
  hideFields: string[];
  [key: string]: any;
};

const FieldIf: React.FC<FieldIfProps> = memo(({ name, showFields, hideFields, ...other }) => {
  return showFields.includes(name) && !hideFields.includes(name) ? (
    <FinalField name={name} {...other} />
  ) : null;
});
FieldIf.displayName = 'FieldIf';

const workTypeFields = ['job_scope', 'opportunity_location'];

const maxAttachmentFiles = 5;
const maxAttachmentSize = 10;
const attachmentsAddStyle = { marginLeft: 40 };

const connector = connect((state: RootState) => ({
  allSectors: state.sectors.all,
  // @ts-ignore missing description
  projects: state.projects.names,
  groups: state.groups.all,
  viewer: state.viewer,
}));

interface DetailsNewProps {
  values: any;
  change: (field: string, value: any) => void;
  canEdit?: boolean;
  canEditQueries?: boolean;
  canAddAttachment?: boolean;
  canRemoveAttachment?: boolean;
  isMobileVersion?: boolean;
  isViewerExpert: boolean;
  showFields?: string[];
  hideFields?: string[];
}

const DEFAULT_SHOW_FIELDS = [
  'er_type',
  'group_about',
  'project',
  'name',
  'description',
  'qualifications',
  'questions',
  'companies',
  'disclosure',
  'sectors',
  'regions',
  'focusAreas',
  'instructions_research',
  'attachments',
  'tags',
  'expected_duration',
  'time_done_scoping_call',
  ...workTypeFields,
];

const DEFAULT_HIDE_FIELDS: string[] = [];

const DetailsNew: FC<DetailsNewProps & ConnectedProps<typeof connector>> = memo(
  ({
    values,
    groups,
    projects,
    change,
    canEdit = true,
    canEditQueries = true,
    canAddAttachment = true,
    canRemoveAttachment = true,
    allSectors,
    isMobileVersion,
    isViewerExpert,
    viewer,
    showFields = DEFAULT_SHOW_FIELDS,
    hideFields = DEFAULT_HIDE_FIELDS,
  }) => {
    const { data } = useQuery(GET_COUNTRIES);
    const allCountries = data?.countries || [];

    useEffect(() => {
      const project = projects?.edges?.find((p: any) => p.node.id === values.project_id)?.node;
      const groupId = values.group_id || project?.group?.id;

      if (groupId) {
        const group = groups?.edges?.find((g: any) => g.node.id === groupId)?.node;
        change('group_about', group?.about);
      }
    }, [values.group_id, projects, groups, change, values.project_id]);

    const isViewerAdmin = viewer.admin;
    const { profile } = viewer;

    const showFieldsFiltered = useMemo(
      () =>
        showFields.filter((f) => {
          if (workTypeFields.includes(f)) return isWorkType(values.er_type);
          if (f === 'expected_duration') return isSetExpectedDurationType(values.er_type);
          return true;
        }),
      [showFields, values.er_type]
    );

    const doShowField = (field: any) => {
      return showFieldsFiltered.includes(field) && !hideFields.includes(field);
    };

    const Field = useCallback(
      (props: any) => (
        // see https://github.com/final-form/react-final-form/issues/130 for
        // context on the parse attribute, by providing it empty string text
        // fields are explicitly included in the submit values allowing full
        // deletion of already set fields
        <FieldIf
          parse={(v: any) => v}
          showFields={showFieldsFiltered}
          hideFields={hideFields}
          disabled={!canEdit}
          {...props}
        />
      ),
      [canEdit, hideFields, showFieldsFiltered]
    );

    return (
      <div className={s.formQuestions}>
        <FormSection
          title={isViewerExpert ? 'Basic Information' : 'Public Opportunity Fields'}
          subtitle={
            isViewerExpert
              ? ''
              : 'These fields will appear on the public opportunity page that our Research Managers share with expert candidates.'
          }
        >
          {isViewerAdmin && !config.disableChangeExpertRequestType ? (
            <Field
              TextFieldProps={{
                variant: 'outlined',
                inputProps: {
                  readOnly: true,
                },
              }}
              autocomplete
              component={Select}
              disableClearable
              format={(v: any) => {
                if (typeof v === 'string') {
                  return {
                    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    label: ER_TYPE_LABELS[v],
                    value: v,
                  };
                }
                return v;
              }}
              fullWidth
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option}
              isOptionEqualToValue={(option: any, value: any) => isEqual(option, value)}
              getOptionDisabled={({ value }: any) =>
                value === ExpertRequestType.ProBonoConsultation
              }
              id="expertRequestType"
              label="Request Type"
              limit={null}
              name="er_type"
              openOnFocus
              options={erTypeOptions}
              parse={(v: any) => v.value}
            />
          ) : (
            <Field
              id="expertRequestType"
              component={TextField}
              disabled
              name="er_type"
              label="Request Type"
              variant="outlined"
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              format={(v: any) => ER_TYPE_LABELS[v]}
              fullWidth
              inputProps={{
                style: {
                  textTransform: 'capitalize',
                },
              }}
            />
          )}
          {doShowField('project') && (
            <Project
              // @ts-expect-error TS(2769): No overload matches this call.
              formValues={values}
              change={change}
              field={FinalField}
              disabled={!canEdit}
              TextFieldProps={{
                variant: 'outlined',
              }}
            />
          )}
          <Field
            id="expertRequestGroupAbout"
            component={TextField}
            name="group_about"
            label="Describe Your Company"
            variant="outlined"
            fullWidth
            multiline
            minRows={2}
            maxRows={50}
            inputProps={{ maxLength: 2048 }}
          />
          <Field
            id="expertRequestName"
            component={TextField}
            validate={required}
            name="name"
            label="Expert Request Title"
            variant="outlined"
            required
            fullWidth
          />
          <Field
            id="expertRequestOpportunityLocation"
            component={TextField}
            name="opportunity_location"
            label="Opportunity Location (optional)"
            placeholder="New York, NY"
            variant="outlined"
            fullWidth
          />
          <Field
            id="expertRequestJobScope"
            component={TextField}
            name="job_scope"
            label="Job Scope"
            variant="outlined"
            placeholder="Please describe the scope of this job"
            fullWidth
            multiline
            minRows={2}
            maxRows={50}
          />
          <Field
            id="expertRequestDescription"
            component={TextField}
            name="description"
            label="Ideal Expert Profile"
            placeholder="example: “Decision maker for setting up a manufacturing facility in West Africa”"
            variant="outlined"
            fullWidth
            multiline
            minRows={2}
            maxRows={50}
            inputProps={{ maxLength: 2048 }}
          />
          {doShowField('expected_duration') && (
            <FormSubSection title="Expected Duration (Optional)">
              <DurationField
                id="expertRequestExpectedDuration"
                name="expected_duration"
                label="How many hours do you expect the written review to take?"
                minHours={0}
                maxHours={isViewerAdmin ? undefined : 10}
                variant="outlined"
              />
            </FormSubSection>
          )}
          {doShowField('qualifications') && (
            <FormSubSection
              title={isViewerExpert ? 'Specific Questions' : 'Specific Questions for Expert(s)'}
              subtitle={
                isViewerExpert
                  ? ''
                  : "We'll use these questions to screen experts for your project."
              }
            >
              <ExpertQueries
                // @ts-expect-error TS(2769): No overload matches this call.
                name="questions"
                label={canEdit && canEditQueries ? 'Add Question' : 'Question'}
                placeholder='example: "Does education software in India require government approval?"'
                addButtonLabel="Question"
                disabled={!canEdit || !canEditQueries}
                isMobileVersion={isMobileVersion}
                isViewerExpert={isViewerExpert}
                isViewerAdmin={isViewerAdmin}
                minLength={1}
              />
            </FormSubSection>
          )}
          {doShowField('questions') && (
            <FormSubSection
              title={isViewerExpert ? 'Desired Qualifications' : 'Desired Expert Qualifications'}
            >
              <ExpertQueries
                // @ts-expect-error TS(2769): No overload matches this call.
                name="qualifications"
                label={canEdit && canEditQueries ? 'Add Qualification' : 'Qualification'}
                placeholder='example: "Have you previously been involved in a merger/acquisition?"'
                addButtonLabel="Qualification"
                disabled={!canEdit || !canEditQueries}
                isMobileVersion={isMobileVersion}
                isViewerExpert={isViewerExpert}
                isViewerAdmin={isViewerAdmin}
                minLength={1}
              />
            </FormSubSection>
          )}
          {doShowField('attachments') && (
            <FormSubSection
              title="Attachments for Review"
              subtitle={
                isViewerExpert
                  ? ''
                  : `Upload files below (File limit: ${maxAttachmentFiles}, File size limit: ${maxAttachmentSize}MB)`
              }
            >
              <Attachments
                name="attachments"
                label="Upload Files"
                addButtonLabel="Files"
                disabled={!canEdit || !canAddAttachment}
                isMobileVersion={isMobileVersion}
                maxAttachmentFiles={maxAttachmentFiles}
                maxAttachmentSize={maxAttachmentSize}
                addActionStyle={attachmentsAddStyle}
                isViewerExpert={isViewerExpert}
                showRemoveForField={() => canRemoveAttachment && canEdit}
              />
            </FormSubSection>
          )}
        </FormSection>
        <FormSection
          title={isViewerExpert ? 'Additional Information' : 'Search Notes'}
          subtitle={
            isViewerExpert
              ? ''
              : 'Use the following fields to add information you would like to share with our Research Managers. It will not be shared with expert candidates on the Public Opportunity Page.'
          }
        >
          {doShowField('companies') && (
            // @ts-expect-error TS(2769): No overload matches this call.
            <Companies isMobileVersion={isMobileVersion} disabled={!canEdit} />
          )}
          {doShowField('disclosure') && (
            // @ts-expect-error TS(2769): No overload matches this call.
            <Disclosure formValues={values} change={change} disabled={!canEdit} />
          )}
          {doShowField('sectors') && (
            <Field
              id="expertRequestSectors"
              component={SelectSector}
              name="sectors"
              label="Sector(s) of Expertise"
              sectors={canEdit ? allSectors : values?.sectors}
              TextFieldProps={{
                variant: 'outlined',
              }}
            />
          )}
          <Field
            id="expertRequestRegions"
            multiple
            component={SelectLocation}
            name="regions"
            label="Region(s) of Expertise"
            countries={canEdit ? allCountries : values?.regions}
            TextFieldProps={{
              variant: 'outlined',
            }}
            format={(values: any) => {
              if (!values) {
                return;
              }
              if (canEdit) {
                return values.map((v: any) =>
                  allCountries.find((c: any) => c.id.toString() === v.id)
                );
              }
              return values;
            }}
          />
          {isViewerAdmin && (
            <Field
              component={KeywordInput}
              freeSolo
              getOptionLabel={(o: any) => o}
              autoSelect
              variant="outlined"
              TextFieldProps={{
                variant: 'outlined',
                inputProps: { maxLength: 25 },
              }}
              options={[]}
              id="expertRequestTags"
              name="tags"
              label="Tags"
            />
          )}
          {doShowField('focusAreas') && (
            <LabelledOutline id="expertRequestFocusAreas" label="Focus Area(s)">
              <FocusAreas disabled={!canEdit} />
            </LabelledOutline>
          )}
          <Field
            id="expertRequestInstructionsResearch"
            component={TextField}
            name="instructions_research"
            label="Instructions for Researchers"
            placeholder="Add an optional note with specific recruiting instructions for our research team"
            variant="outlined"
            fullWidth
            multiline
            minRows={2}
            maxRows={50}
            inputProps={{ maxLength: 2048 }}
          />
          {isViewerAdmin && (
            <LabelledOutline id="timeDoneScopingCallLabel" label="Time Done Scoping Call">
              <Field
                id="timeDoneScopingCall"
                component={DateTimePicker}
                name="time_done_scoping_call"
                placeholder="Time Done Scoping Call"
                fullWidth
                inline
                timezone={profile?.timezone}
                displayWarning={false}
                maxDate={new Date()}
              />

              <Link newTab className={s.changeTimezoneLink} to="/settings/communication">
                Change My Timezone {getTimezoneDescription(profile?.timezone)}
              </Link>
            </LabelledOutline>
          )}
        </FormSection>
      </div>
    );
  }
);
DetailsNew.displayName = 'DetailsNew';
DetailsNew.propTypes = {
  values: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  canEditQueries: PropTypes.bool,
  canAddAttachment: PropTypes.bool,
  canRemoveAttachment: PropTypes.bool,
  isMobileVersion: PropTypes.bool,
  isViewerExpert: PropTypes.bool.isRequired,
  showFields: PropTypes.arrayOf(PropTypes.string.isRequired),
  hideFields: PropTypes.arrayOf(PropTypes.string.isRequired),
};

const ConnectedComp = connector(DetailsNew);

// @ts-expect-error TS(2339): Property 'fetch' does not exist on type 'typeof Pr... Remove this comment to see the full error message
ConnectedComp.fetch = Project.fetch.concat([fetchSectors]);

export default ConnectedComp;
