import cx from 'classnames';
import React from 'react';
import { Link as RouterLink } from 'react-router';

import { darkBrown, darkGray } from '@/theme/colors';
import { rewriteUrl } from '@/utils';

const urlToPathname = (url: string) => {
  try {
    const urlObj = new URL(url, window.location.origin);
    return urlObj.pathname + urlObj.search;
  } catch (e: unknown) {
    console.warn(e, url);
  }
  return null;
};

type LinkProps = {
  // internal navigation
  to?: string | null;
  // external navigation
  href?: string | null;
  force?: boolean;
  newTab?: boolean;
  target?: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  menu?: boolean;
  disabled?: boolean;
  className?: string;
  id?: string;
  style?: React.CSSProperties;
  dangerouslySetInnerHTML?: {
    __html: string | TrustedHTML;
  };
  onKeyPress?: (e: React.KeyboardEvent) => void;
};

const Link = ({
  to: initialTo,
  href,
  children,
  onClick,
  menu,
  disabled,
  newTab,
  className,
  id,
  style,
  dangerouslySetInnerHTML,
  onKeyPress,
}: LinkProps): React.ReactNode => {
  if (newTab && !href && !initialTo) {
    throw new Error('Link component with newTab requires "to" or "href" prop');
  }

  let to = initialTo || href || '';
  if (to) {
    to = rewriteUrl(to);
  }

  const linkStyle = menu ? { color: darkBrown } : { cursor: 'pointer' };
  const disabledStyle = { color: darkGray, cursor: 'not-allowed' };
  if (disabled) {
    return (
      <span
        id={id}
        style={{
          ...style,
          ...disabledStyle,
        }}
        className={className}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      >
        {children}
      </span>
    );
  }

  if (href || newTab) {
    return (
      <a
        href={to}
        target="_blank"
        rel="noreferrer"
        className={cx('hover:underline', className)}
        style={linkStyle}
        onClick={onClick}
      >
        {children}
      </a>
    );
  } else if (to) {
    return (
      <RouterLink
        to={urlToPathname(to) || to}
        onClick={onClick}
        style={{
          ...linkStyle,
          ...style,
        }}
        className={className}
      >
        {children}
      </RouterLink>
    );
  }

  return (
    <button
      id={id}
      onClick={onClick}
      style={{
        ...style,
        ...linkStyle,
      }}
      className={className}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      onKeyDown={onKeyPress}
      role="link"
    >
      {children}
    </button>
  );
};

export default Link;
