import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from 'rc-tooltip';
import { useNavigate } from 'react-router';

import RequestConsultationButton from '@/components/Button/RequestConsultationButton';
import Divider from '@/components/Divider';
import SendMessageButton from '@/components/SendMessageButton';
import { Viewer } from '@/core/viewer';
import { darkGray } from '@/theme/colors';
import { formatCredits } from '@/utils';

import AddToExpertRequestButton from './AddToExpertRequestButton';

const getStyles = makeStyles(() => ({
  sendMessageButton: {
    marginTop: 15,
    textAlign: 'center',
  },
}));

const getSharingStyles = makeStyles(() => ({
  sharing: {
    marginTop: 10,
    textAlign: 'center',
    fontSize: 12,
  },
  sharingGroup: {
    fontWeight: 'bold',
  },
}));

const getBookingStyles = makeStyles(() => ({
  credits: {
    textAlign: 'center',
  },
  creditsLabel: {
    textTransform: 'uppercase',
    fontSize: 10,
  },
  creditsValue: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  expertStateButton: {
    marginTop: 10,
  },
  buttonInfo: {
    color: darkGray,
    fontSize: 12,
    textAlign: 'center',
    marginTop: 8,
  },
}));

interface BookingProps {
  profile: NonNullable<Viewer['profile']>;
  requestUrl: string;
  canRequestConsultation?: boolean;
}

function Booking({ profile, requestUrl, canRequestConsultation }: BookingProps): JSX.Element {
  const navigate = useNavigate();
  const s = getBookingStyles();
  const credits = formatCredits(profile.credit_rate || 0);

  return (
    <div>
      <Divider spacing={10} />

      <div className={s.credits}>
        <span className={s.creditsLabel}>From</span>
        <span className={s.creditsValue}>
          <span>
            &nbsp;{credits} <span>{Number(credits) === 1 ? 'credit' : 'credits'}</span>/hr
          </span>
        </span>
      </div>

      {canRequestConsultation && (
        <>
          <div className={s.expertStateButton}>
            <RequestConsultationButton fullWidth onClick={() => navigate(requestUrl)} />
          </div>

          <div className={s.buttonInfo}>You won&apos;t be charged until confirming</div>
        </>
      )}
    </div>
  );
}

function Sharing({ profile }: any) {
  const s = getSharingStyles();

  const { expert_internal_networks: inetexps } = profile;
  const groupNames = (inetexps || [])
    .map((ine: any) => ine.from_group && ine.from_group.name)
    .filter(Boolean);

  if (!groupNames.length) return null;
  if (groupNames.length === 1)
    return (
      <div className={s.sharing}>
        Shared by <span className={s.sharingGroup}>{groupNames[0]}</span>
      </div>
    );

  const tooltip = (
    <div>
      {groupNames.slice(1).map((gn: string) => (
        <div key={gn}>• {gn}</div>
      ))}
    </div>
  );

  const otherPluralized = groupNames.length === 2 ? 'other' : 'others';

  return (
    <div className={s.sharing}>
      Shared by <span className={s.sharingGroup}>{groupNames[0]}</span>
      &nbsp;and{' '}
      <Tooltip
        overlay={tooltip}
        trigger={['hover']}
        overlayStyle={{ zIndex: 2000 }}
        placement="bottom"
      >
        <span>
          {groupNames.length - 1} {otherPluralized}
        </span>
      </Tooltip>
    </div>
  );
}

export interface ActionsProps extends BookingProps {
  user: Viewer;
  showMessageButton?: boolean;
}

function Actions({
  canRequestConsultation,
  user,
  profile,
  requestUrl,
  showMessageButton,
}: ActionsProps): JSX.Element | null {
  const s = getStyles();

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  if (mobile) return null;

  return (
    <>
      <Booking
        canRequestConsultation={canRequestConsultation}
        profile={profile}
        requestUrl={requestUrl}
      />

      <AddToExpertRequestButton
        fullWidth
        profile={profile}
        style={{ marginTop: 15 }}
        form="addToExpertRequestFromProfile"
      />

      {showMessageButton && (
        <div className={s.sendMessageButton}>
          <SendMessageButton userId={user.id} />
        </div>
      )}

      <Sharing profile={profile} />
    </>
  );
}

export default Actions;
