import { List, Menu } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import React, { ReactNode, useState } from 'react';
import { ChevronDown, ChevronUp, Icon } from 'react-feather';
import { useNavigate } from 'react-router';

import { resetUser } from '@/auth/store/userSlice';
import { NavBar } from '@/componentsv2/NavBar';
import { segmentTracking } from '@/core/analytics';
import { ProfileOnboardingTask } from '@/openapi';
import APP_ROUTES from '@/routes/APP_ROUTES';
import { useAppDispatch } from '@/store';

export type NavBarItem = {
  key: string;
  text: string | ReactNode;
  to?: string;
  divider?: boolean;
  icon?: string | Icon;
  className?: string;
  mobileOnly?: boolean;
  children?: NavBarItem[];
  onClick?: () => void;
  handleKeyDown?: (event: React.KeyboardEvent) => void;
};

type UserData = {
  displayName: string | null;
  picture_url: string;
  username?: string;
};

interface HeaderProps {
  userData: UserData | null;
  incompleteTasks?: ProfileOnboardingTask[];
  SearchComponent?: React.ElementType;
  isAuthenticated: boolean;
  navbarItems: NavBarItem[];
  userMenuItems?: NavBarItem[];
  findExperts?: React.ReactNode;
  logout: (args: { returnTo: string }) => void;
}

function MoreOptionsNavbar({ navbarItems }: { navbarItems: NavBarItem[] }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (item: NavBarItem) => {
    if (item.onClick) {
      item.onClick();
    } else if (item.to) {
      navigate(item.to);
    }
  };

  if (!navbarItems.length) return null;
  if (navbarItems.length === 1) {
    const item = navbarItems[0];
    if (item.mobileOnly) return null;

    const NavIcon = item.icon && typeof item.icon !== 'string' ? item.icon : undefined;

    return (
      <NavBar.Item icon={NavIcon} path={item.to} onClick={() => handleNavigate(item)}>
        {item.text}
      </NavBar.Item>
    );
  }

  return (
    <div>
      <NavBar.Item icon={open ? ChevronUp : ChevronDown} onClick={handleClick} path={''}>
        More
      </NavBar.Item>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        classes={{ paper: 'px-5' }}
      >
        {navbarItems.map((item) => {
          if (item.mobileOnly) return;

          const NavIcon = item.icon && typeof item.icon !== 'string' ? item.icon : undefined;

          return (
            <NavBar.Item
              key={item.key}
              icon={NavIcon}
              path={item.to}
              onClick={() => handleNavigate(item)}
            >
              {item.text}
            </NavBar.Item>
          );
        })}
      </Menu>
    </div>
  );
}

export const Header: React.FC<HeaderProps> = ({
  userData,
  incompleteTasks,
  SearchComponent,
  isAuthenticated,
  navbarItems,
  userMenuItems,
  findExperts,
  logout,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const [userMenuOptionsOpen, setUserMenuOptionsOpen] = useState('');

  const handleLogout = () => {
    const segmentPayload = {
      event_type: 'Access',
      event_name: 'Log Out',
    };
    segmentTracking('clicked-logout-button', segmentPayload);
    dispatch(resetUser());
    logout({ returnTo: window.location.origin });
  };

  const toggleUserMenuOptions = (key: string) => {
    if (userMenuOptionsOpen === key) {
      setUserMenuOptionsOpen('');
    } else {
      setUserMenuOptionsOpen(key);
    }
  };

  const handleClickUserMenu = (item: NavBarItem) => {
    if (item.children) {
      toggleUserMenuOptions(item.key);
      return;
    }

    setMenuOpen(false);
    if (item.onClick) {
      item.onClick();
    } else if (item.to) {
      navigate(item.to);
    }
  };

  const ChevronIcon = ({ item }: { item: NavBarItem }) => {
    if (item.children) {
      return userMenuOptionsOpen === item.key ? (
        <ChevronUp size={36} className="text-brand-secondary" />
      ) : (
        <ChevronDown size={36} />
      );
    }
    return null;
  };

  const { displayName: fullName, picture_url: pictureUrl } = userData || {};

  return (
    <NavBar
      mobileNavSlot={
        <>
          {navbarItems.map((item) => (
            <div key={item.key}>
              <NavBar.MobileItem
                path={item.to}
                className={item.className}
                boxClassName={userMenuOptionsOpen === item.key ? '!text-brand-secondary' : ''}
                onClick={() => handleClickUserMenu(item)}
              >
                {item.text}
                <ChevronIcon item={item} />
              </NavBar.MobileItem>
              <Collapse in={userMenuOptionsOpen === item.key} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.children?.map((child) => (
                    <NavBar.MobileItem
                      key={child.key}
                      path={child.to}
                      className="pl-20"
                      onClick={() => handleClickUserMenu(child)}
                    >
                      {child.text}
                    </NavBar.MobileItem>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
          {isAuthenticated && <NavBar.MobileItem onClick={handleLogout}>Log Out</NavBar.MobileItem>}
        </>
      }
    >
      {isAuthenticated ? (
        <>
          <NavBar.ItemContainer>
            {navbarItems.slice(0, 5).map((item) => {
              if (item.mobileOnly) return;

              const NavIcon = item.icon && typeof item.icon !== 'string' ? item.icon : undefined;

              return (
                <NavBar.Item
                  key={item.key}
                  icon={NavIcon}
                  path={item.to}
                  onClick={() => handleClickUserMenu(item)}
                  hasNotification={item.key === 'dashboard' && !!incompleteTasks?.length}
                >
                  {item.text}
                </NavBar.Item>
              );
            })}
            {navbarItems.length > 5 && (
              <MoreOptionsNavbar
                navbarItems={navbarItems.filter((item) => !item.mobileOnly).slice(5)}
              />
            )}
          </NavBar.ItemContainer>
          {!!SearchComponent && <SearchComponent />}
          {findExperts}
          {fullName ? (
            <NavBar.UserMenu
              fullName={fullName}
              avatarPictureUrl={pictureUrl}
              open={menuOpen}
              onOpenChange={setMenuOpen}
            >
              {userMenuItems?.map((item) => (
                <NavBar.UserMenuItem
                  onKeyDown={item.handleKeyDown}
                  key={item.key}
                  divider={item.divider}
                  className="font-semibold"
                  onClick={() => handleClickUserMenu(item)}
                >
                  {item.text}
                </NavBar.UserMenuItem>
              ))}
              <NavBar.UserMenuItem className="font-bold" onClick={handleLogout}>
                Log Out
              </NavBar.UserMenuItem>
            </NavBar.UserMenu>
          ) : null}
        </>
      ) : (
        <NavBar.UserMenu fullName="Visitor" open={menuOpen} onOpenChange={setMenuOpen}>
          <NavBar.UserMenuItem onClick={() => navigate(APP_ROUTES.login)}>
            Log In
          </NavBar.UserMenuItem>
          <NavBar.UserMenuItem onClick={() => navigate(APP_ROUTES.signup)}>
            Sign Up
          </NavBar.UserMenuItem>
        </NavBar.UserMenu>
      )}
    </NavBar>
  );
};

export default Header;
