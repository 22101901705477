import { useApolloClient } from '@apollo/client';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { PickerFileMetadata } from 'filestack-js';
import langs from 'langs';
import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { ConnectedProps, connect } from 'react-redux';

import Button from '@/components/Button/Button';
import CircularProgress from '@/components/CircularProgress/CircularProgress';
import CvButton from '@/components/CvButton/CvButton';
import { RadioGroup, Select, TextField } from '@/components/FormAdapters';
import Image from '@/components/Image';
import SelectLocation from '@/components/SelectLocation';
import { presignAttachmentURL } from '@/core/attachment';
import { RootState } from '@/store';
import { borderColor } from '@/theme/colors';
import { normalizeSpace } from '@/utils';

const useStyles = makeStyles((theme) => ({
  divider: {
    height: 1,
    backgroundColor: borderColor,
    margin: '20px 0',
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      margin: '10px 0',
    },
  },
  image: {
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      height: '100%',
    },
  },
  imageUpload: {
    marginTop: 16,
    marginBottom: 8,
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  upload: {
    background: 'red',
  },
  footer: {
    marginTop: 30,
  },
  paragraph: {
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
  },
}));

export interface FormData {
  first_name: string;
  last_name: string;
  picture: string;
  picture_url: string;
  country: string;
  city: string;
  languages: string[];
  linkedin_url: string;
  resume: string;
  title: string;
  summary: string;
  cv_url: string;
  autofill: string;
}

interface BasicInformationFormProps {
  sending?: boolean;
}

const connector = connect((state: RootState, ownProps: BasicInformationFormProps) => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const profile = state.profiles.fullProfiles[ownProps.profileId];
  const languages = profile.languages || [];

  return {
    allCountries: state.countries.filter((c: any) => Boolean(c.country_iso2_code)),
    languages,
  };
});

const BasicInformationForm = ({
  sending,
  handleSubmit,
  form,
  values,
  allCountries,
}: BasicInformationFormProps &
  ConnectedProps<typeof connector> &
  FormRenderProps<FormData>): JSX.Element => {
  const s = useStyles();

  //const form = useForm();

  const theme = useTheme();
  const graphql = useApolloClient();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const languagesOptions = langs.all().map((l) => ({
    label: l.name,
    value: l['3'],
  }));

  if (sending) {
    return (
      <div>
        <CircularProgress size={52} style={{ marginLeft: 10, marginBottom: 20 }} />
        <div>Processing...</div>
      </div>
    );
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Grid container spacing={4}>
        <Grid item md={6} sm={12} xs={12}>
          <div className={s.imageUpload}>
            <Image
              src={values.picture}
              onChange={async (imgs) => {
                const url = await presignAttachmentURL(graphql, imgs[0].url);
                form.batch(() => {
                  form.change('picture_url', imgs[0].url);
                  form.change('picture', url);
                });
              }}
              emptyText="Upload a photo here"
              editable
              label="Profile Photo"
              keepOutline
              className={s.image}
              dimensions={{ width: 220, height: 220 }}
            />
          </div>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item md={6} sm={12} xs={12}>
              <Field
                component={TextField}
                name="first_name"
                label="First Name"
                variant="outlined"
                autoComplete="nope"
                changeOnBlur={false}
                format={normalizeSpace}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <Field
                component={TextField}
                name="last_name"
                label="Last Name"
                variant="outlined"
                autoComplete="nope"
                changeOnBlur={false}
                format={normalizeSpace}
                inputProps={{ maxLength: 255 }}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Field
                component={TextField}
                name="city"
                label="City"
                variant="outlined"
                autoComplete="nope"
                inputProps={{ maxLength: 255 }}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Field
                component={SelectLocation}
                multiple={false}
                name="country"
                countries={allCountries}
                format={(country) => allCountries.find((c: any) => c.name === country)}
                parse={(country) => (country && country.name) || ''}
                label="Country"
                TextFieldProps={{
                  variant: 'outlined',
                  inputProps: { autoComplete: 'nope' },
                }}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Field
                component={TextField}
                name="linkedin_url"
                label="LinkedIn URL"
                variant="outlined"
                inputProps={{ maxLength: 255 }}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Field
                component={TextField}
                type="upload"
                name="resume"
                label="Upload Resume (PDF, DOC)"
                variant="outlined"
                changeOnBlur={false}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <CvButton
                        label="Upload"
                        onUpload={(file: PickerFileMetadata) => {
                          form.batch(() => {
                            form.change('cv_url', file.url);
                            form.change('resume', file.filename);
                          });
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <Field
                component={Select}
                name="languages"
                label="Proficient Languages"
                options={languagesOptions}
                autocomplete
                limit={null}
                TextFieldProps={{ variant: 'outlined' }}
                multiple
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!mobile && <Divider className={s.divider} />}

      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Field
            component={TextField}
            name="title"
            placeholder="Write a short headline about your experience"
            label="Headline"
            variant="outlined"
            multiline={mobile}
            InputLabelProps={{
              shrink: true,
            }}
            minRows={2}
            maxRows={6}
            inputProps={{ maxLength: 120 }}
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <Field
            component={TextField}
            name="summary"
            placeholder="Add a brief bio"
            label="Short Bio"
            variant="outlined"
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            minRows={mobile ? 4 : 2}
            maxRows={4}
            inputProps={{ maxLength: 2000 }}
          />
        </Grid>
      </Grid>

      {values.cv_url && (
        <React.Fragment>
          <Divider className={s.divider} />

          <Grid container>
            <p className={s.paragraph}>Profile Optimization</p>

            <Field
              component={RadioGroup}
              defaultValue="true"
              format={(value: any) => String(value)}
              name="autofill"
              options={[
                {
                  value: 'true',
                  label: 'Yes, use my CV and other info that may be on file to improve my profile',
                },
                {
                  value: 'false',
                  label: 'No thanks, I will manually complete all fields',
                },
              ]}
            />
          </Grid>
        </React.Fragment>
      )}

      <div className={s.footer}>
        <Button size="large" type="submit" fullWidth={mobile}>
          Continue
        </Button>
      </div>
    </form>
  );
};

export default connector(BasicInformationForm);
