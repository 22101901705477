import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { LanguageFluency } from '@/__generated__/graphql';
import { Viewer } from '@/core/viewer';
import BasicInformationForm, { FormData } from '@/profile/components/forms/BasicInformationForm';
import { autofillProfile, updateProfile } from '@/profile/store';
import { RootState } from '@/store';
import { hasOnlyLetters, isValidLinkedin } from '@/utils/reducer';

const getStyles = makeStyles((theme: any) => ({
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
      marginTop: 15,
    },
  },
  subtitle: {
    fontSize: 16,
    marginBottom: 30,
  },
  loading: {
    marginTop: 100,
    textAlign: 'center',
  },
}));

function validate(values: FormData) {
  const errors: { [key: string]: string } = {};

  const fields: (keyof FormData)[] = ['first_name', 'last_name', 'country', 'title', 'summary'];

  const { languages, linkedin_url: linkedinUrl } = values;

  if (values.cv_url) {
    fields.push('autofill');
  }

  if (!languages || !languages.length) {
    errors.languages = 'Required';
  }

  if (linkedinUrl && !isValidLinkedin(linkedinUrl)) {
    errors.linkedin_url = 'Must be a valid https://linkedin.com URL';
  }

  if (!(values.first_name || '').trim()) {
    errors.first_name = 'Required';
  }

  if (values.first_name && !hasOnlyLetters(values.first_name)) {
    errors.first_name = 'Only letters allowed';
  }

  if (!(values.last_name || '').trim()) {
    errors.last_name = 'Required';
  }

  if (values.last_name && !hasOnlyLetters(values.last_name)) {
    errors.last_name = 'Only letters allowed';
  }

  fields.forEach((f) => {
    if (!values[f]) errors[f] = 'Required';
  });

  return errors;
}

interface BasicInformationProps {
  nextUrl: string;
  profileId: string;
  viewer: Viewer;
}

const connector = connect(
  (state: RootState, ownProps: BasicInformationProps) => {
    const profile = state.profiles.fullProfiles[ownProps.profileId] || {};
    return {
      profile,
    };
  },
  {
    updateProfile,
    autofillProfile,
  }
);

const BasicInformation = ({
  nextUrl,
  profileId,
  viewer,
  profile,
  updateProfile,
  autofillProfile,
  ...other
}: BasicInformationProps & ConnectedProps<typeof connector>) => {
  const s = getStyles();
  const navigate = useNavigate();
  const {
    first_name,
    last_name,
    picture_url,
    country,
    city,
    languages,
    linkedin_url,
    title,
    summary,
  } = profile;
  const [sending, setSending] = useState(false);

  const initialValues: Partial<FormData> = useMemo(
    () => ({
      first_name: first_name || undefined,
      last_name: last_name || undefined,
      picture_url: picture_url || undefined,
      country: country || undefined,
      city: city || undefined,
      languages: (languages || []).map((l) => l.code),
      linkedin_url: linkedin_url || undefined,
      cv_url: undefined,
      autofill: 'true',
      title: title || undefined,
      summary: summary || undefined,
    }),
    [city, country, first_name, languages, last_name, linkedin_url, picture_url, summary, title]
  );

  const handleSubmit = useCallback(
    async (values: FormData) => {
      setSending(true);
      const { autofill, cv_url, first_name, last_name, languages } = values;

      if (autofill === 'true' && cv_url) {
        await autofillProfile({
          cvUrl: cv_url,
        });
      }

      try {
        await updateProfile({
          id: profileId,
          ...values,
          languages: languages.map((l) => ({
            code: l,
            fluency: LanguageFluency.ProfessionalWorking,
          })),
          first_name: first_name.trim(),
          last_name: last_name.trim(),
        });
        navigate(nextUrl);
      } catch (err: any) {
        const errors: { [key: string]: string } = {};

        if (err.message) {
          if (err.message.includes('first name')) {
            errors.first_name = 'Invalid name';
          } else if (err.message.includes('last name')) {
            errors.last_name = 'Invalid name';
          }
        }

        setSending(false);
        return { errors };
      }
    },
    [autofillProfile, navigate, nextUrl, profileId, updateProfile]
  );

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} sm={12}>
        <p className={s.title}>Enter your basic information</p>
        <p className={s.subtitle}>
          Start off by adding your basic profile information and hourly rate.
        </p>

        <Form
          component={BasicInformationForm}
          profileId={profileId}
          nextUrl={nextUrl}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          sending={sending}
          {...other}
        />
      </Grid>
    </Grid>
  );
};

export default connector(BasicInformation);
