import { ConnectedProps, connect } from 'react-redux';
import { Field, InjectedFormProps, formValueSelector, reduxForm } from 'redux-form';

import { notify } from '@/actions/ui';
import EditDialog, { EditDialogProps } from '@/components/EditDialog';
import { Checkbox, TextField } from '@/components/FormAdapters/FormAdapters';
import { Candidate } from '@/expertrequest';
import { RootState } from '@/store';

type Profile = NonNullable<Candidate['profile']>;

export interface FormData {
  id: string;
  match_note: {
    note?: string;
    recommended?: boolean;
  };
}

interface EditMatchNoteProps extends EditDialogProps {
  initialValues: FormData;
  onSubmit: (values: FormData) => void | Promise<void>;
  noteValue?: string;
  profile: Profile;
}

const connector = connect(
  (state: RootState, ownProps: EditMatchNoteProps) => {
    const formName = `candidateMatchNote-${ownProps.initialValues.id}`;
    return {
      form: formName,
      noteValue: formValueSelector(formName)(state, 'match_note.note'),
    };
  },
  {
    notify,
  }
);

const EditMatchNote = ({
  handleSubmit,
  reset,
  noteValue,
  profile,
  onSubmit,
  notify,
  ...other
}: EditMatchNoteProps &
  ConnectedProps<typeof connector> &
  InjectedFormProps<FormData, EditMatchNoteProps>) => {
  const finalSubmit = async (values: FormData) => {
    try {
      await onSubmit(values);
      notify('Research Manager note updated.', 'success');
    } catch (err: unknown) {
      notify('Error while updating Research Manager note.', 'error');
      console.error(err);
    }
  };

  return (
    <EditDialog
      {...other}
      onSubmit={handleSubmit(finalSubmit)}
      onReset={() => reset()}
      // bogus attribute (force dialog position update on text change)
      //fieldValue={noteValue}
    >
      <Field
        id="match_note.note"
        component={TextField}
        fullWidth
        multiline
        name="match_note.note"
        label="Research Manager note"
        placeholder={
          'Example: Based on my vetting calls, I think ' +
          `${profile.first_name} is a good fit for your needs based on ` +
          'the following...'
        }
        maxRows={15}
      />
      <Field
        type="checkbox"
        component={Checkbox}
        name="match_note.recommended"
        label="Add my recommendation"
        style={{ margin: '15px 0' }}
      />
    </EditDialog>
  );
};

export default reduxForm<FormData, EditMatchNoteProps>({
  form: 'editMatchNote',
  enableReinitialize: true,
})(connector(EditMatchNote));
