import { AppThunk } from '@/store';

export function track(
  code: string,
  objectId?: string,
  context?: Record<string, any>,
  recordIpAddress = false
): AppThunk<Promise<void>> {
  return async () => {
    // TODO: add segment tracking
    console.warn('not tracking', code, objectId);
  };
}
