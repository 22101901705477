import { useQuery } from '@apollo/client';

import { gengql } from '@/__generated__';
import { useApp } from '@/hooks/useAppContext';

import CustomSelect from '../Select';

const FETCH_ALL_GROUPS = gengql(/* GraphQL */ `
  query selectGroupGetGroups(
    $internal: Boolean
    $memberOnly: Boolean
    $cursor: String
    $pageSize: Int!
  ) {
    groups(internal: $internal, member_only: $memberOnly, first: $pageSize, after: $cursor) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          billing_account {
            id
          }
        }
      }
    }
  }
`);

interface FetchGroupsOptions {
  internal: boolean;
  memberOnly?: boolean;
  memberLimit: number;
  cursor: string | null;
  pageSize: number;
  sharingExperts: boolean;
  name: string;
  includeStats: boolean;
}

interface SelectGroupProps {
  autoComplete?: boolean;
  autoFocus?: boolean;
  input?: object;
  limit?: number;
  [key: string]: any;
}

const SelectGroup = ({
  autoComplete,
  autoFocus,
  input = {},
  limit = 5,
  memberOnly: memberOnlyProp,
  pageSize = 1000,
  internal = false,
  ...other
}: SelectGroupProps & Partial<FetchGroupsOptions>): JSX.Element => {
  const { viewer } = useApp();
  const memberOnly = memberOnlyProp !== undefined ? memberOnlyProp : !viewer.admin;

  const { data, refetch, loading } = useQuery(FETCH_ALL_GROUPS, {
    variables: {
      cursor: '',
      memberOnly,
      internal,
      pageSize,
    },
  });

  const groups = (data?.groups?.edges || []).map((e) => e?.node).filter((g) => !!g);

  return (
    <CustomSelect<any, false, true, false>
      loading={loading}
      placeholder="Search team..."
      {...input}
      {...other}
      limit={limit}
      TextFieldProps={{ autoFocus }}
      rawValueOnChange
      id="autoCompleteTeam"
      clearOnBlur
      disableClearable
      autocomplete
      options={groups.map((g) => ({
        value: g.id,
        label: g.name,
      }))}
      onFocus={() => refetch()}
    />
  );
};

export default SelectGroup;
