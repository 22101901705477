import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { PickerFileMetadata } from 'filestack-js';
import { memo } from 'react';

import { Viewer } from '@/core/viewer';
import { borderColor, white } from '@/theme/colors';

import EditIcon from '../EditIcon';
import Image from '../Image';
import Link from '../Link';
import SocialButton from '../SocialButton/SocialButton';

const getStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: white,
    maxWidth: 277,
    padding: 20,
    border: `1px solid ${borderColor}`,
    zIndex: 1,
    position: 'relative',
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      border: 0,
      padding: 0,
      maxWidth: 'none',
    },
  },
  container: {
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 10,
    },
  },
  imageContainer: {
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      backgroundColor: white,
      maxWidth: 140,
      padding: 5,
      margin: '0 auto',
    },
  },
  nameContainer: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  name: {
    textAlign: 'center',
    flex: 1,
    margin: 0,
    fontWeight: 'bold',
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  image: {
    width: '100%',
    minHeight: 220,
    boxSizing: 'border-box',
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      minHeight: 140,
    },
  },
  credits: {
    fontSize: 12,
  },
  socialButton: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export interface CardProps {
  profile: NonNullable<Viewer['profile']>;
  pictureEditable?: boolean;
  onPictureChange?: (file: PickerFileMetadata[]) => void;
  onEditName?: () => void;
  onEditLinkedIn?: () => void;
  credits?: number;
  preview?: boolean;
  children?: React.ReactNode;
  className?: string;
  imageContainerClassname?: string;
  style?: React.CSSProperties;
}

function Card({
  profile,
  pictureEditable,
  onPictureChange,
  onEditName,
  onEditLinkedIn,
  credits,
  preview,
  children,
  className,
  imageContainerClassname,
  style,
}: CardProps): JSX.Element {
  const s = getStyles();

  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className={clsx(s.root, className)} style={style}>
      <div className={s.container}>
        {preview || !mobile ? (
          <span />
        ) : (
          <div className={s.credits}>
            {credits && (
              <div>{`${credits} ${Number(credits) === 1 ? 'credit' : 'credits'}/hr`}</div>
            )}
          </div>
        )}

        {mobile && (profile.linkedin_url || onEditLinkedIn) && (
          <div className={s.socialButton}>
            <SocialButton startIcon="linkedin" href={profile.linkedin_url} />
            {onEditLinkedIn && <EditIcon style={{ marginLeft: 10 }} onClick={onEditLinkedIn} />}
          </div>
        )}
      </div>

      <div className={clsx(s.imageContainer, imageContainerClassname)}>
        <Image
          className={s.image}
          editable={pictureEditable}
          href={profile.html_url}
          src={profile.picture_url || ''}
          onChange={onPictureChange}
          dimensions={{ height: 220, width: 220 }}
        />
      </div>

      <div className={s.nameContainer}>
        <Link to={profile.html_url}>
          <h3 className={s.name}>{profile.name}</h3>
        </Link>
        {onEditName && <EditIcon onClick={onEditName} style={{ marginLeft: 10 }} />}
      </div>
      {children}
    </div>
  );
}

export default memo(Card);
