import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '@/components/Button';
import FAIcon from '@/components/Icon/FAIcon';
import { fetchExpertRequests } from '@/expertrequest/store';
import { RootState } from '@/store';

import AddToExpertRequestDialog from './AddToExpertRequestDialog';

interface AddToExpertRequestButtonProps {
  profile?: any;
  profiles?: any[];
  size?: 'tiny' | 'normal' | 'large' | 'huge' | 'small' | 'medium' | 'large';
  label?: string;
  form?: string;
  className?: string;
  classes?: object;
  style?: React.CSSProperties;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: boolean;
  onClick?: () => void;
  onExpertAdd?: () => void;
}

const connector = connect(
  (rootState: RootState) => ({
    viewer: rootState.viewer,
  }),
  {
    fetchExpertRequests,
  }
);

const AddToExpertRequestButton = (
  props: AddToExpertRequestButtonProps & ConnectedProps<typeof connector>
) => {
  const navigate = useNavigate();
  const [dialogOpened, setDialogOpened] = React.useState(false);

  const handleOpen = () => {
    const { fetchExpertRequests, viewer } = props;
    if (!viewer.id) {
      navigate(`/login?next=${window.location.pathname}`);
    }

    fetchExpertRequests({ state: 'open' }).then(() => setDialogOpened(true));
  };

  const handleClose = () => setDialogOpened(false);

  const { profile, label = 'Add to Request', disabled, icon = true, onExpertAdd, ...other } = props;

  const profiles = profile ? [profile] : props.profiles || [];
  if (profiles.length === 0) return null;

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="lightTan"
        size="small"
        {...other}
        startIcon={icon && <FAIcon size={14} icon="plus" />}
        onClick={handleOpen}
      >
        {label}
      </Button>
      {!disabled && (
        <AddToExpertRequestDialog
          open={dialogOpened}
          profiles={profiles}
          onClose={handleClose}
          onExpertAdd={onExpertAdd}
        />
      )}
    </React.Fragment>
  );
};

export default connector(AddToExpertRequestButton);
