import { CSSProperties } from 'react';
import { UnknownAction } from 'redux';

import { gengql } from '@/__generated__';
import { UserContext, getUserContextOptions, isGroupContext } from '@/core/user';
import { AppThunk } from '@/store';
import { setCache } from '@/utils';

import ActionTypes from './ActionTypes';

export function notify(message = '', messageType = 'success', duration = 8000): UnknownAction {
  return {
    type: ActionTypes.UI__SHOW_MESSAGE,
    message,
    messageType,
    duration,
  };
}

export function hideMessage(): UnknownAction {
  return {
    type: ActionTypes.UI__HIDE_MESSAGE,
  };
}

interface PopupOptions {
  title: string;
  layout?: string;
  contents?: any;
  buttons?: any;
  buttonAlignment?: any;
  links?: any;
  contentStyle?: CSSProperties;
}

export function popup({
  layout,
  title,
  contents,
  buttons,
  links,
  contentStyle,
  buttonAlignment,
}: PopupOptions): UnknownAction {
  return {
    type: ActionTypes.UI__SHOW_POPUP,
    layout,
    title,
    contents,
    buttons,
    links,
    contentStyle,
    buttonAlignment,
  };
}

export function hidePopup(): UnknownAction {
  return {
    type: ActionTypes.UI__HIDE_POPUP,
  };
}

const GET_GROUP = gengql(/* GraphQL */ `
  query actionSetUserContextGetGroup($id: String!) {
    group(id: $id) {
      id
      name
      billing_account {
        id
      }
    }
  }
`);

export function setUserContext(userContext: UserContext): AppThunk<Promise<void>> {
  return async (dispatch, getState, { graphql }) => {
    if (!userContext) throw new Error('user context cannot be empty');

    const { viewer } = getState();

    let group;
    if (isGroupContext(userContext)) {
      const { data } = await graphql.client.query({
        query: GET_GROUP,
        variables: { id: userContext },
      });
      group = data.group;
    }

    const userContextOptions = getUserContextOptions(viewer, userContext, group && [group]);

    setCache('user_context', userContext);

    dispatch({
      type: ActionTypes.UI__SET_USER_CONTEXT,
      userContext,
      userContextOptions,
    });
  };
}

export function saveUserView(userId: string): UnknownAction {
  return {
    type: ActionTypes.UI__SAVE_USER_VIEW,
    userId,
  };
}
