// polyfill for Uint8Array.toBase64
import { ApolloError, ApolloProvider, ServerError } from '@apollo/client';
import { CacheProvider } from '@emotion/react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import 'core-js/modules/esnext.uint8-array.to-base64.js';
import moment from 'moment-timezone';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';

import App from '@/App';
import ActionTypes from '@/actions/ActionTypes';
import { createToken, updateCall } from '@/actions/call';
import { track } from '@/actions/tracking';
import { notify, setUserContext } from '@/actions/ui';
import { TokenMeta, logout } from '@/auth';
import { setViewer } from '@/auth/store/viewerSlice';
// import PlivoCarrier from '@/conference/plivoCarrier';
// import TwilioCarrier from '@/conference/twilioCarrier';
import voipCarrier from '@/conference/voipCarrier';
import ZoomCarrier from '@/conference/zoomCarrier';
import config from '@/config';
import { initSegmentIdentifier } from '@/core/analytics';
import { GraphQLClient, bearerAuth } from '@/core/api';
import ApiWebSocket from '@/core/apiWebSocket';
import '@/core/compat';
import { initHubSpot, initIntercom } from '@/core/crm';
import { saveInvitationToken } from '@/core/invite';
import { PermissionService } from '@/core/permissions';
import { saveTracking } from '@/core/tracking';
import { getDefaultUserContext } from '@/core/user';
import { Viewer, fetchViewer } from '@/core/viewer';
import { AppProvider } from '@/hooks/useAppContext';
import SendbirdHelper from '@/messaging/sendbird';
import { updateProfile } from '@/profile/store';
import store, { AppStore, setThunkExtraArg } from '@/store';
import { setRuntimeVariable } from '@/store/runtime';
import { updateUserInfo } from '@/store/user';
import theme, { createEmotionCache } from '@/theme';
import '@/theme/index.css';
import { getCache } from '@/utils';

import pkg from '../package.json';
import './overrides';
import reportWebVitals from './reportWebVitals';
import createRoutes from './routes';

declare global {
  // Polyfills
  interface Uint8Array {
    toBase64(): string;
  }

  interface Window {
    __VERSION__: string;
    __GIT_SHA__?: string;
    OF_SEARCH_V2_FEATURE?: string;
    OF_KNOWLEDGE_TABLE_FEATURE?: string;
    dataLayer: (string | object)[];
    _hsq?: (string | object)[];
    _analyticsTraits?: object;
    analytics: SegmentAnalytics.AnalyticsJS;
    growthbookFeaturesOnUsage: Record<string, boolean | number | string>;
    HubSpotConversations?: {
      widget?: {
        status: () => { loaded: boolean };
        open: () => void;
      };
    };
  }
}

interface VoipError {
  message: string;
}

const gitSha = import.meta.env.VITE_GIT_SHA;
const buildLabel = import.meta.env.VITE_BUILD_LABEL || 'dev';
const buildNumber = import.meta.env.VITE_BUILD_NUMBER || gitSha;

let buildVersion = '';
if (buildLabel && buildLabel !== 'prod') {
  buildVersion += `-${buildLabel}`;
  if (buildNumber) {
    buildVersion += `+${buildNumber}`;
  }
}

window.__VERSION__ = pkg.version + buildVersion;
window.__GIT_SHA__ = gitSha;

function trackConsultationJoin(store: AppStore, action: string) {
  const { call } = store.getState();
  store.dispatch(
    track(
      `consultation.join.web.${action}`,
      call.consultationId,
      {
        ua: navigator && navigator.userAgent,
      },
      false
    )
  );
}

function saveTimezone(store: AppStore, viewer: Viewer) {
  if (!viewer.id || !viewer.profile || viewer.timezone) return;
  store.dispatch(
    updateProfile({
      id: viewer.profile.id,
      timezone: moment.tz.guess(),
    })
  );
}

function isUnauthorizedError(err: unknown): boolean {
  if (!(err instanceof ApolloError)) return false;
  return (err?.networkError as ServerError).statusCode === 401;
}

(async function main() {
  Sentry.init({
    release: window.__VERSION__,
    environment: config.appEnv,
    dsn: config.sentryDsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        unblock: ['.sentry-unblock, [data-sentry-unblock]'],
        unmask: ['.sentry-unmask, [data-sentry-unmask]'],
      }),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
    ],

    ignoreErrors: ['AbortError', 'CancelError', 'Non-Error promise rejection captured'],

    beforeSend(event: Sentry.ErrorEvent, _hint: Sentry.EventHint) {
      if (event.exception) {
        // KT: disable this for now.
        // Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },

    beforeBreadcrumb: excludeGraphQLFetch,
    // beforeBreadcrumb(breadcrumb) {
    //   // too noisy
    //   if (breadcrumb.category === 'redux.action') {
    //     return null;
    //   }

    //   return breadcrumb;
    // },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
  Sentry.setTag('git_sha', gitSha);

  const accessToken = getCache('access_token') as TokenMeta | undefined;
  const token = accessToken?.token;
  let graphql = new GraphQLClient(config.apiUrl, token ? bearerAuth(token) : undefined);

  let viewer: Viewer = { ...store.getState().viewer };

  if (token) {
    try {
      viewer = await fetchViewer(graphql.client);
    } catch (err) {
      logout();
      if (!isUnauthorizedError(err)) {
        throw err;
      }
      graphql = new GraphQLClient(config.apiUrl);
    }
  }

  // KT: setup sentry user as soon as possible to give more context
  if (viewer.id) {
    const segment = viewer.admin ? 'admin' : viewer.signup_type;
    const scope = Sentry.getCurrentScope();
    scope.setUser({
      id: viewer.id,
      username: viewer.username || undefined,
      email: viewer.email?.address || '',
      segment,
    });
  }

  viewer.intercomHash = getCache('intercom_hash');

  const apiWebSocket = new ApiWebSocket(config.webSocketUrl, token);
  const sendbird = new SendbirdHelper(viewer, graphql);

  setThunkExtraArg({
    graphql,
    history,
    sendbird,
    voipCarrier,
    apiWebSocket,
  });
  store.dispatch(setViewer(viewer));
  store.dispatch(updateUserInfo(viewer));
  store.dispatch(setRuntimeVariable('referrer', document.referrer));
  store.dispatch(setRuntimeVariable('token', token));
  store.dispatch(setRuntimeVariable('login', accessToken?.login_meta || undefined));

  const cache = createEmotionCache();

  const {
    ui: { userContext },
  } = store.getState();
  await store.dispatch(setUserContext(getDefaultUserContext(viewer, userContext)));

  store.dispatch({ type: ActionTypes.LOAD_FROM_LOCAL_STORAGE });

  const permission = new PermissionService(graphql.client, viewer.id);

  // Global (context) variables that can be easily accessed from any React
  // component https://facebook.github.io/react/docs/context.html
  const context = {
    viewer,

    // Integrate Redux
    // http://redux.js.org/docs/basics/UsageWithReact.html
    store,

    // GraphQL client (Lokka)
    // https://github.com/kadirahq/lokka
    graphql,

    location: window.location,
    sendbird,
    voipCarrier,
    permission,
    apiWebSocket,
    capabilities: {
      screen: {
        width: window.screen.width,
      },
    },
  };

  // Telephony
  // voipCarrier.registerCarrier('twilio', TwilioCarrier);
  // voipCarrier.registerCarrier('plivo', PlivoCarrier);
  voipCarrier.registerCarrier('zoom', ZoomCarrier);
  voipCarrier.setTokenFactory((identifier: string) => store.dispatch(createToken(identifier)));
  voipCarrier.on('callStateChange', (state: object) => store.dispatch(updateCall(state)));
  voipCarrier.on('error', (err: VoipError) => {
    store.dispatch(notify(err.message, 'error'));
    trackConsultationJoin(store, 'error');
  });
  voipCarrier.on('connect', () => {
    trackConsultationJoin(store, 'success');
  });

  // Init

  initSegmentIdentifier(viewer);

  saveTracking();

  saveInvitationToken();

  saveTimezone(store, viewer);

  if (config.intercomAppId) {
    initIntercom(config.intercomAppId, viewer);
  }

  initHubSpot(viewer);

  // KT: some possibly relevant server code
  // const icons = {
  //   shortcut: `${cdnUrl}/images/icons/favicon.png`,
  //   appleTouch: `${cdnUrl}/images/icons/apple-touch-icon.png`,
  //   manifest: `${cdnUrl}/images/icons/site.webmanifest`,
  //   msAppConfig: `${cdnUrl}/images/icons/browserconfig.xml`,
  // };

  // const data = {
  //   title: 'OnFrontiers - Learn faster',
  //   meta: {
  //     description:
  //       'Automation for the knowledge economy | Access specialists on any topic within hours. Share your experience to help others on their path forward. Invest in your expert network.',
  //     url: location.toString(),
  //     imageUrl: '/images/meta.png',
  //     type: 'website',
  //   },

  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  const router = sentryCreateBrowserRouter(
    createRoutes({ store, graphqlClient: graphql, sendbird, permission })
  );

  // Note that React strict mode doesn't work with @mui/styles
  // See https://mui.com/system/styles/basics/
  createRoot(document.getElementById('root') as Element).render(
    <ReduxProvider store={store}>
      <CacheProvider value={cache}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            {/* <SnackbarProvider
              hideIconVariant
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              autoHideDuration={6000}
              maxSnack={3}
              Components={{
                undo: UndoSnackbar,
                knowledgeSummary: KnowledgeSummarySnackbar,
              }}
            > */}
            <ApolloProvider client={graphql.client}>
              <AppProvider context={context}>
                <App>
                  <RouterProvider router={router} />
                </App>
              </AppProvider>
            </ApolloProvider>
            {/* </SnackbarProvider> */}
          </ThemeProvider>
        </StyledEngineProvider>
      </CacheProvider>
    </ReduxProvider>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
