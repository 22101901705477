import { Briefcase, Layout, MessageSquare, Star } from 'react-feather';

import { NavBarItem } from '@/components/Header';

import { BadgeCount } from './UserMenu';

// --- ITEMS TEMPLATE

const itemDashboard = {
  key: 'dashboard',
  text: 'Dashboard',
  to: '/dashboard',
  icon: Layout,
};

const itemMessaging = {
  key: 'messaging',
  text: 'Messages',
  to: '/messaging',
  icon: MessageSquare,
};

const itemCalls = {
  key: 'consultations',
  text: 'Consultations',
  to: '/consultations',
  icon: Briefcase,
};

const itemExpertRequest = {
  key: 'expert_requests',
  text: 'Expert Requests',
  to: '/expert_requests',
  icon: Star,
  id: 'expertRequestsAdmin',
};

const itemMyClients = {
  key: 'my-network',
  text: 'My Clients',
  to: '/network',
  icon: Star,
};

const itemMyNetwork = {
  key: 'my-network',
  text: 'My Network',
  to: '/search',
  icon: Star,
};

const itemBecomeAnExpert = {
  key: 'signup/expert',
  text: 'Become an Expert',
  to: '/signup/expert',
  promo: true,
};

const buildAccountSubItems = (
  profileUrl: string,
  isAdmin: boolean,
  hasGroups: boolean,
  conflictsCount: number
) => {
  const subItems: NavBarItem[] = [
    {
      key: 'profile',
      text: 'My Profile',
      to: profileUrl,
    },
  ];

  if (hasGroups) {
    subItems.push({
      key: 'teams',
      text: 'Manage My Teams',
      to: '/teams',
    });
  }

  subItems.push({
    key: 'settings',
    text: 'Settings',
    to: '/settings',
  });

  if (isAdmin) {
    subItems.push(
      {
        key: 'applications',
        text: 'View Applications',
        to: '/search?expert_states[]=applied&expert_states[]=applying&sort=created.at&asc=false',
      },
      {
        key: 'profile-uploader',
        text: 'Profile Uploader',
        to: `/profile-uploader`,
      },
      {
        key: 'profile-conflicts',
        to: `/profile-conflicts`,
        text: (
          <>
            Profile Conflicts <BadgeCount count={conflictsCount} />
          </>
        ),
      }
    );
  }

  return subItems.map((item) => ({
    ...item,
    text: <div className="flex items-center text-brand-secondary">{item.text}</div>,
  }));
};

// --- BUILDER
export const navItemsBuilder = ({
  profileUrl,
  unreadMessages,
  userContext,
  isAdmin,
  hasGroups,
  isAccountEnterprise,
  hasExpertState,
  conflictsCount,
  shouldShowNewRequest = false,
}: any): NavBarItem[] => {
  const newItemMessaging = {
    ...itemMessaging,
    text: <span>Messages {unreadMessages}</span>,
  };

  const accountSubItems = buildAccountSubItems(profileUrl, isAdmin, hasGroups, conflictsCount);
  const items: NavBarItem[] = [itemDashboard, newItemMessaging, itemCalls];

  if (userContext !== 'expert') {
    items.push(itemExpertRequest);
  }

  if (userContext === 'expert') {
    items.push(itemMyClients);
  } else if (!isAdmin && isAccountEnterprise) {
    items.push(itemMyNetwork);
  }

  if (!hasExpertState) {
    items.push(itemBecomeAnExpert);
  }

  items.push({
    key: 'switch-context',
    text: 'Switch Context',
    mobileOnly: true,
  });

  items.push({
    key: 'my-account',
    text: 'My Account',
    mobileOnly: true,
    children: accountSubItems,
  });

  if (shouldShowNewRequest) {
    items.push({
      key: 'expert-requests',
      text: <span className="text-brand-tertiary">Find Experts</span>,
      to: '/request_expert',
      mobileOnly: true,
    });
  }

  return items;
};
