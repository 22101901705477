import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { notify } from '@/actions/ui';
import AttachmentsListFormik from '@/components/Attachments/AttachmentsListFormik';
import Button from '@/components/Button';
import FormikForm from '@/componentsv2/FormikForm';
import { addAttachments } from '@/consultation/store';

import s from './SubmitAttachments.module.scss';

const maxAttachmentFiles = 5;
const maxAttachmentSize = 10;

interface SubmitAttachmentsProps {
  addAttachments: (data: any) => Promise<void>;
  consultation: any;
  notify: (message: string, type: string) => void;
  isViewerExpert: boolean;
  isMobileVersion: boolean;
}

const SubmitAttachments: React.FC<SubmitAttachmentsProps> = ({
  addAttachments,
  consultation,
  notify,
  isViewerExpert,
  isMobileVersion,
}) => {
  const [allowSubmit, setAllowSubmit] = useState(false);

  const handleAddFiles = useCallback(() => {
    setAllowSubmit(true);
  }, []);

  const handleSubmitFiles = useCallback(
    async (values: any) => {
      const { attachments } = values;

      try {
        await addAttachments({
          consultation,
          attachments: attachments.filter((a: any) => !a.id),
        });
        notify('Successfully submitted written response files.', 'success');
      } catch (ex) {
        notify('Error submitting written response files.', 'error');
        throw ex;
      }

      setAllowSubmit(false);
    },
    [addAttachments, consultation, notify]
  );

  const canUpload = isViewerExpert;

  const title = isViewerExpert
    ? 'File(s) uploaded for written response to client'
    : consultation.attachments.length
      ? 'File(s) uploaded by expert for written response'
      : 'No files have been uploaded by expert';

  const formik = useFormik({
    initialValues: consultation,
    onSubmit: handleSubmitFiles,
  });

  return (
    <FormikForm value={formik}>
      <div className={s.attachmentSection}>
        <div>
          <p className={s.attachmentTitle}>{title}</p>
          {canUpload && (
            <p className={s.attachmentSubtitle}>
              {`Upload files below (File limit: ${maxAttachmentFiles}, File size limit: ${maxAttachmentSize}MB)`}
            </p>
          )}
        </div>
        <AttachmentsListFormik
          name="attachments"
          disabled={!canUpload}
          label="Upload Files"
          addButtonLabel="Files"
          isMobileVersion={isMobileVersion}
          maxAttachmentFiles={maxAttachmentFiles}
          maxAttachmentSize={maxAttachmentSize}
          onAddedFiles={handleAddFiles}
          isViewerExpert={isViewerExpert}
        />
        {allowSubmit && (
          <div className={s.submitButton}>
            <Button type="submit" color="teal" size="large">
              Submit Files
            </Button>
          </div>
        )}
      </div>
    </FormikForm>
  );
};

export default connect(undefined, {
  notify,
  addAttachments,
})(SubmitAttachments);
