import { gengql } from '@/__generated__';

export const GET_CREDIT_RATE = gengql(/* GraphQL */ `
  query getUserActionCreditRate($id: String!, $billingAccountId: String) {
    expert(id: $id) {
      creditRate(billing_account_id: $billingAccountId) {
        cents
        currency
      }
    }
  }
`);

export const UPDATE_USER = gengql(/* GraphQL */ `
  mutation actionUpdateUser(
    $id: String!
    $agreements: [AgreementInput!]
    $signup_type: SignupType
    $background_check: Boolean
  ) {
    updateUser(
      id: $id
      agreements: $agreements
      signup_type: $signup_type
      background_check: $background_check
    ) {
      id
      agreements {
        policy
        updated_at
        accepted
      }
      signup_type
      background_check
    }
  }
`);

export const DEPRECATED_FIELDS = gengql(/* GraphQL */ `
  fragment DeprecatedFields on User {
    id
    first_name
    last_name
    name
    picture_url
    country_code
    country
    city
    timezone
    username
    phone
    html_url
    email {
      address
      accepted
      confirmed
    }
    headline
    summary
    languages {
      code
      fluency
    }
    bill_rate
  }
`);

export const GET_USER = gengql(/* GraphQL */ `
  query actionFetchUser(
    $id: String
    $otpAuthEnabled: Boolean!
    $expertise: Boolean!
    $groupKeywords: Boolean!
    $addresses: Boolean!
    $availableConsultation: Boolean!
    $experiences: Boolean!
    $education: Boolean!
    $internalNetworks: Boolean!
    $expertRequestCandidates: Boolean!
    $sources: Boolean!
    $audit: Boolean!
    $signupSubdomain: Boolean!
    $recruiter: Boolean!
    $stats: Boolean!
    $groups: Boolean!
    $sharedInternalNetworks: Boolean!
    $profileKeywordsDefinition: Boolean!
    $connectionStates: [NetworkExpertConnectionState!]
  ) {
    user(id: $id) {
      ...DeprecatedFields
      id
      admin
      expert_state
      locked
      compliance_completed_at
      signup_type
      agreements {
        policy
        updated_at
        accepted
      }
      background_check
      otp_auth_enabled @include(if: $otpAuthEnabled)
      profile {
        id
        first_name
        last_name
        name
        html_url
        url_endpoint
        linkedin_username
        linkedin_url
        title
        summary
        skype
        timezone
        city
        country
        picture_url
        cover_url
        questions
        cv_url
        available_long_term
        available_marketplace
        hide_profile
        bill_rate
        credit_rate
        languages {
          code
          fluency
        }
        keywords
        tier
        additional_information
        sectors @include(if: $expertise) {
          id
          name
        }
        regions @include(if: $expertise) {
          id
          name
          country_iso2_code
        }
        group_keywords @include(if: $groupKeywords) {
          group {
            id
            name
          }
          keywords {
            id
            name
          }
        }
        emails @include(if: $addresses) {
          address
          display
          primary
          verified
          accepted
          confirmed
        }
        phones @include(if: $addresses) {
          address
          display
          primary
          verified
          accepted
        }
        can_request_consultation @include(if: $availableConsultation)
        experiences @include(if: $experiences) {
          id
          title
          start_date
          end_date
          description
          organization
          linkedin_url
          location
          current
          role
        }
        education @include(if: $education) {
          id
          degree
          field_of_study
          school
          start_date
          end_date
          description
        }
        expert_internal_networks(connection_states: $connectionStates)
          @include(if: $internalNetworks) {
          id
          network {
            id
            name
            group {
              id
              city
              country
            }
          }
          add_method
          participation_level
          connection_state
          from_group {
            id
            name
          }
          unpaid
        }
        expert_request_id @include(if: $expertRequestCandidates)
        expert_request_candidates @include(if: $expertRequestCandidates) {
          id
          request_id
          state
        }
        sources @include(if: $sources) {
          agent_id
          agent_profile_id
          source_id
          source_profile_id
          created_by {
            id
            name
            html_url
          }
          created_at
        }

        created_at @include(if: $audit)
        created_by @include(if: $audit) {
          id
          name
          html_url
        }
        updated_at @include(if: $audit)
        updated_by @include(if: $audit) {
          id
          name
          html_url
        }
      }
      signup_subdomain @include(if: $signupSubdomain)
      recruiter @include(if: $recruiter) {
        id
        name
        html_url
      }
      rating @include(if: $stats)
      expert_stats @include(if: $stats) {
        consultation_count
        candidate_summary {
          vetting_count
          verified_count
          matched_count
        }
      }
      client_stats @include(if: $stats) {
        expert_request_count
        consultation_count
        avg_expert_rating
      }
      groups @include(if: $groups) {
        id
        name
        html_url
        branding_logo_url
        branding_show_poweredbyof
        account_type
        default_anonymous_messaging
        shared_internal_networks @include(if: $sharedInternalNetworks) {
          id
          name
        }
        profile_keywords_definition @include(if: $profileKeywordsDefinition) {
          id
          name
        }
      }
    }
  }
`);
